import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
  tgl_realisasi_produksi_aset: Yup.string().required("Masukan tanggal"),
  progress: Yup.string().test(
    "maxPercentase",
    "Progress tidak boleh melebihi 100%",
    (item, { from }) => {
      const max = 100 - parseFloat(from[0]?.value?.progress_saat_ini ?? 0);
      console.log(max);

      return parseFloat(item) <= parseFloat(max);
    }
  ),
});

export const formRegisterAsetValidationSchema = Yup.object().shape({
  bulan_perolehan: Yup.string().required("Masukan bulan perolehan"),
  tahun_perolehan: Yup.string().required("Masukan tahun perolehan"),
  id_satuan_pakai: Yup.string().required("Pilih satuan pakai"),
  id_kondisi_aset: Yup.string().required("Pilih kondisi aset"),
  id_karyawan: Yup.string().required("Pilih karyawan penanggung jawab"),
  id_provinsi: Yup.string().required("Pilih provinsi"),
  id_kabupaten: Yup.string().required("Pilih kabupaten"),
  id_kecamatan: Yup.string().required("Pilih kecamatan"),
  id_desa: Yup.string().required("Pilih desa"),
});
