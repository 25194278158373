// Components
import { Table, Td, TdFixed, Th, ThFixed } from 'components';
import { Card } from 'react-bootstrap';
import { RupiahConvert } from 'utilities';

const SummarySection = ({ summary, total }) => {
  return (
    <>
      <b>Summary Biaya</b>
      <Card>
        <Card.Body>
          <Table noHover>
            <thead>
              <tr>
                <ThFixed>No.</ThFixed>
                <Th>Grup BUASO</Th>
                <Th>Jenis Komponen</Th>
                <ThFixed>Total</ThFixed>
              </tr>
            </thead>
            <tbody>
              {summary.map((val, index) => (
                <>
                  <tr>
                    <TdFixed rowSpan={2}>{index + 1}</TdFixed>
                    <Td rowSpan={2}>{val.buaso}</Td>
                    <Td>Komponen Utama</Td>
                    <TdFixed textRight>
                      {RupiahConvert(String(val.summary_ut ?? 0)).getWithComa}
                    </TdFixed>
                  </tr>
                  <tr>
                    <Td>Komponen Tambahan</Td>
                    <TdFixed textRight>
                      {RupiahConvert(String(val.summary_tb ?? 0)).getWithComa}
                    </TdFixed>
                  </tr>
                </>
              ))}
              <tr className="font-weight-bold">
                <TdFixed colSpan={3} textRight>
                  Total
                </TdFixed>
                <TdFixed textRight>
                  {RupiahConvert(String(total)).getWithComa}
                </TdFixed>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};
export default SummarySection;
