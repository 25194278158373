import React, { useState } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import { TableKomponenProduksi } from "./TableKomponenProduksi";
import { DataStatus, SelectSearch } from "components";
import { useQuery } from "react-query";
import ProduksiAsetApi from "../__CloseProduksiAsetApi__";
import { useFormikContext } from "formik";

export const TabKomponenProduksiAset = ({ action }) => {
  const [salinKomponenConfig, setSalinKomponenConfig] = useState({ id: "" });
  const { values, setValues } = useFormikContext();
  const { data: salinKomponen, isFetching: loadingSalinKomponen } = useQuery(
    ["dropdownSalinKomponen", "dropdown"],
    () => ProduksiAsetApi.getSalinKomponen()
  );

  const getDetailSalinKomponen = useQuery(
    ["salinKomponen", "single", salinKomponenConfig?.id],
    () =>
      ProduksiAsetApi.getSingleProduksiAset({
        id_produksi_aset: salinKomponenConfig?.id,
      }).then((data) =>
        setValues({
          ...values,
          bahan: data?.data?.bahan?.map((val) => ({
            ...val,
            id_item: val?.id_item_bahan,
          })),
          upah: data?.data?.upah?.map((val) => ({
            ...val,
            id_item: val?.id_item_upah,
          })),
          alatMesin: data?.data?.alat_mesin?.map((val) => ({
            ...val,
            id_item: val?.id_item_alat_mesin,
          })),
          subkon: data?.data?.subkon?.map((val) => ({
            ...val,
            id_item: val?.id_item_subkon,
          })),
          overhead: data?.data?.overhead?.map((val) => ({
            ...val,
            id_item: val?.id_item_overhead,
          })),
        })
      ),
    { enabled: !!salinKomponenConfig?.id }
  );

  return (
    <div>
      <Card className="mt-3">
        <Tab.Container defaultActiveKey="bahan">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="bahan">Bahan</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="upah">Upah</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="alatMesin">Alat Mesin</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="subkon">Subkon</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="overhead">Overhead</Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Tab.Content>
            {getDetailSalinKomponen.isFetching ? (
              <DataStatus loading text="Memuat Data..." />
            ) : (
              <>
                <Tab.Pane eventKey="bahan">
                  <div className="m-2">
                    <TableKomponenProduksi tab="bahan" action={action} />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="upah">
                  <div className="m-2">
                    <TableKomponenProduksi tab="upah" action={action} />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="alatMesin">
                  <div className="m-2">
                    <TableKomponenProduksi tab="alatMesin" action={action} />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="subkon">
                  <div className="m-2">
                    <TableKomponenProduksi tab="subkon" action={action} />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="overhead">
                  <div className="m-2">
                    <TableKomponenProduksi tab="overhead" action={action} />
                  </div>
                </Tab.Pane>
              </>
            )}
          </Tab.Content>
        </Tab.Container>
      </Card>
    </div>
  );
};
