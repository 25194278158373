import {
  ButtonCancel,
  ButtonCreate,
  ButtonDelete,
  ButtonUpdate,
  ButtonVerify,
} from "@bhawanadevteam/react-core";
import {
  DataStatus,
  NumberFormat,
  SelectSearch,
  TBody,
  Table,
  Td,
  Th,
  ThFixed,
  Tr,
} from "components";
import Thead from "components/Table/THead";
import { Formik, useFormikContext } from "formik";
import React, { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useQuery } from "react-query";
import * as Yup from "yup";
import { DecimalConvert, RupiahConvert } from "utilities";
import RealisasiProduksiAsetApi from "../__RealisasiProduksiAsetApi__";

const FormInputTable = ({ tab, data, action, ind, setisUpdate }) => {
  const { values: formValues, setValues: setFormValues } = useFormikContext();

  const { data: dropdownItem, isFetching: loadingDropdownItem } = useQuery(
    ["dropdownItem", "dropdown", tab],
    () => RealisasiProduksiAsetApi.getDropdownItem({ id_buaso: 1 })
  );

  const { data: dropdownGudang, isFetching: loadingDropdownGudang } = useQuery(
    ["dropdownGudang", "dropdown"],
    () => RealisasiProduksiAsetApi.getDropdownGudang()
  );

  const tableInitialValues = {
    id_item: data?.id_item ?? undefined,
    nama_item: data?.nama_item ?? "",
    id_gudang: data?.id_gudang ?? undefined,
    id_satuan: data?.id_satuan ?? "",
    nama_gudang: data?.nama_gudang ?? "",
    qty: data?.qty ?? 0,
    nama_satuan: data?.nama_satuan ?? "",
  };

  const tableValidationSchema = Yup.object().shape({
    id_item: Yup.string().required("Pilih item"),
    qty: Yup.string().required("Masukan qty. realisasi"),
  });

  const checkActionSubmit = (value) => {
    if (action === "update") {
      setFormValues({
        ...formValues,
        transfer: {
          ...formValues.transfer,
          [tab]: formValues?.transfer?.[tab]?.map((val, i) =>
            i === ind ? value : val
          ),
        },
      });
      setisUpdate(false);
    } else {
      setFormValues({
        ...formValues,
        transfer: {
          ...formValues.transfer,
          [tab]: [...formValues.transfer[tab], value],
        },
      });
    }
  };

  const tableSubmitHandler = (value, { setSubmitting, resetForm }) => {
    checkActionSubmit(value);
    setSubmitting(false);
    resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={tableInitialValues}
      validationSchema={tableValidationSchema}
      onSubmit={tableSubmitHandler}
    >
      {({
        values,
        setFieldValue,
        setValues,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
      }) => (
        <Tr>
          <Td colSpan="2" width="400">
            <SelectSearch
              option={dropdownItem}
              loading={loadingDropdownItem}
              placeholder="Pilih Item"
              defaultValue={
                values?.id_item
                  ? {
                      value: values?.id_item,
                      label: values?.nama_item,
                    }
                  : undefined
              }
              onChange={(val) =>
                setValues({
                  ...values,
                  id_item: val.value,
                  nama_item: val.label,
                  harga_satuan: val?.harga_satuan,
                  nama_satuan: val?.nama_satuan,
                  id_satuan: val?.id_satuan,
                })
              }
              error={errors.id_item && touched.id_item && true}
              errorText={errors.id_item && touched.id_item && errors.id_item}
            />
          </Td>
          <Td width="200">
            <SelectSearch
              option={dropdownGudang}
              loading={loadingDropdownItem}
              placeholder="Pilih Gudang"
              defaultValue={
                values?.id_gudang
                  ? {
                      value: values?.id_gudang,
                      label: values?.nama_gudang,
                    }
                  : undefined
              }
              onChange={(val) =>
                setValues({
                  ...values,
                  id_gudang: val.value,
                  nama_gudang: val.label,
                })
              }
              error={errors.id_item && touched.id_item && true}
              errorText={errors.id_item && touched.id_item && errors.id_item}
            />
          </Td>
          <Td>{values?.nama_satuan ?? "-"}</Td>
          <Td>
            <NumberFormat
              placeholder="Masukan Qty. Komponen"
              value={values?.qty}
              onChange={(val) => setFieldValue("qty", val)}
              error={errors.qty && touched.qty && true}
              errorText={errors.qty && touched.qty && errors.qty}
            />
          </Td>

          <Td>
            {action === "update" ? (
              <ButtonGroup>
                <ButtonVerify
                  icon
                  noText
                  variant="outline-success"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                  loadingText=""
                />
                <ButtonCancel
                  icon
                  noText
                  variant="outline-danger"
                  onClick={() => setisUpdate(false)}
                  loadingText=""
                />
              </ButtonGroup>
            ) : (
              <ButtonCreate
                icon
                noText
                onClick={handleSubmit}
                loading={isSubmitting}
                loadingText=""
                style={{ width: 60 }}
              />
            )}
          </Td>
        </Tr>
      )}
    </Formik>
  );
};

export const TableSisaProduksi = ({ action, tab, section }) => {
  const [isUpdate, setisUpdate] = useState("");
  const { values, setValues } = useFormikContext();

  const onDeleteDataTable = (ind) => {
    const filterData = values?.transfer?.[tab]?.filter(
      (val, index) => index !== ind
    );
    setValues({
      ...values,
      transfer: { ...values?.transfer, [tab]: filterData },
    });
  };

  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th className="text-capitalize">Item Sisa Produksi</Th>
            <Th>Gudang</Th>
            <Th>Satuan</Th>
            <Th>Qty Realisasi</Th>
            {action === "detail" || section === "realisasi" ? (
              ""
            ) : (
              <ThFixed>Aksi</ThFixed>
            )}
          </Tr>
        </Thead>
        <TBody>
          {action === "detail" || section === "realisasi" ? (
            ""
          ) : (
            <FormInputTable tab={tab} action="create" />
          )}
          {values?.[section]?.[tab]?.length > 0 ? (
            values?.[section]?.[tab]?.map((val, ind) =>
              isUpdate === ind ? (
                <FormInputTable
                  tab={tab}
                  action="update"
                  data={val}
                  ind={ind}
                  setisUpdate={setisUpdate}
                />
              ) : (
                <Tr key={ind}>
                  <Td>{ind + 1}</Td>
                  <Td>{val.nama_item ?? "-"}</Td>
                  <Td>{val?.nama_gudang ?? "-"}</Td>
                  <Td>{val.nama_satuan ?? "-"}</Td>
                  <Td className="text-right">
                    {DecimalConvert(parseFloat(val.qty ?? 0)).getWithComa}
                  </Td>
                  {action === "detail" || section === "realisasi" ? (
                    ""
                  ) : (
                    <Td>
                      <ButtonGroup>
                        <>
                          <ButtonUpdate
                            noText
                            icon
                            variant="success"
                            size="sm"
                            onClick={() => setisUpdate(ind)}
                          />
                          <ButtonDelete
                            noText
                            icon
                            size="sm"
                            onClick={() => onDeleteDataTable(ind)}
                          />
                        </>
                      </ButtonGroup>
                    </Td>
                  )}
                </Tr>
              )
            )
          ) : (
            <Tr>
              <Td colSpan="8">
                <DataStatus text="Tidak Ada Data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </div>
  );
};
