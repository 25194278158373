// React
import React, { useState, useEffect } from "react"

// Router
import { useHistory, useLocation } from "react-router-dom"

// Components
import { ButtonGroup } from "react-bootstrap"
import {
  CRUDLayout, Alert, DataStatus, ReadButton, UpdateButton, 
  Table, InputSearch, Pagination, THead, TBody, Tr, ThFixed, 
  Td, ApprovalStatusButton, FilterButton, CreateButton, Th, 
  TdFixed
} from "components"
import { PageNumber as TableNumber, DateConvert } from "utilities"

// API
import { JobMixDesignApi } from "api"

// View Components
import { ModalFilter } from "./components"

// View Functions
import { getStatusApprovalLabel } from "./functions"

const RegistrasiJobMixDesign = ({ setNavbarTitle }) => {
  // Hooks
  const history = useHistory()
  const location = useLocation()

  // Variables
  const filter = location?.state?.pagination
  const title = "Job Mix Design"

  // States
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [data, setData] = useState([])
  const [modalFilter, setModalFilter] = useState(false)
  const [dataFilter, setDataFilter] = useState({
    active: filter?.jobmix?.active,
    page: filter?.jobmix?.page ?? 1,
    per_page: filter?.jobmix?.per_page ?? 10,
    q: filter?.jobmix?.q ?? "",
    jenis_barang: filter?.jobmix?.jenis_barang,
    status_approval: filter?.jobmix?.status_approval,
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })
  const [searchTime, setSearchTime] = useState(null);
  const [totalPage, setTotalPage] = useState(0)
  const [totalData, setTotalData] = useState(0)

  const getInitialData = () => {
    const filterData = {...dataFilter}
    delete filterData.active

    setIsPageLoading(true)

    JobMixDesignApi.page(filterData).then(res => {
      setData(res?.data?.data ?? [])
      setTotalPage(res.data.total_page)
      setTotalData(res.data.data_count)
    }).catch(() => {
      setAlertConfig({
        show: true,
        variant: "danger",
        text: "Data gagal dimuat!",
      })
    }).finally(() => setIsPageLoading(false))
  }

  const onInputSearchChange = (e) => {
    const key = e.target.value
    if(searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setDataFilter({
          ...dataFilter,
          q: e.target.value
        })
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
      }, 750)
    )
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })
      }
    }
  }

  const dismissAlert = () => {
    let state = { ...history.location.state }
    delete state.alert
    history.replace({ ...history.location, state })

    setAlertConfig({
      ...alertConfig,
      show: false,
    })
  }

  useEffect(() => {
    setNavbarTitle(title)
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }

    // eslint-disable-next-line
  }, [setNavbarTitle])

  useEffect(() => {
    getInitialData()

    return () => {
      setIsPageLoading(false)
    }

    // eslint-disable-next-line
  }, [dataFilter])

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Kode Barang</ThFixed>
            <Th style={{ minWidth: '250px' }}>Nama Barang</Th>
            <Th style={{ width: '150px' }}>Jenis Barang</Th>
            <ThFixed>Status Approval</ThFixed>
            <ThFixed>Update Terakhir</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
              {TableNumber(dataFilter.page, dataFilter.per_page, index)}
              </TdFixed>
              <Td className="align-middle">
                <ButtonGroup>
                  {getStatusApprovalLabel(val.status_approval).level >= 1 && (
                    <ReadButton
                      size="sm"
                      onClick={() => history.push(`/master/job-mix-design/detail/${val.id_jobmix_design}`, {
                        pagination: {
                          ...filter,
                          jobmix: dataFilter
                        },
                      })}
                    />
                  )}
                  {getStatusApprovalLabel(val.status_approval).level >= 2 && (
                    <UpdateButton
                      size="sm"
                      onClick={() => history.push(`/master/job-mix-design/ubah/${val.id_jobmix_design}`, {
                        pagination: {
                          ...filter,
                          jobmix: dataFilter
                        },
                      })}
                    />
                  )}
                </ButtonGroup>
              </Td>
              <TdFixed>{val.kode_item ?? "-"}</TdFixed>
              <Td>{val.nama_item ?? "-"} [{val.nama_varian ?? "-"}]</Td>
              <Td>{val.nama_jenis ?? "-"}</Td>
              <Td>
                <ApprovalStatusButton variant={getStatusApprovalLabel(val.status_approval).variant}>
                  {getStatusApprovalLabel(val.status_approval).label}
                </ApprovalStatusButton>
              </Td>
              <TdFixed>
                {val.tgl_approval ? DateConvert(new Date(val.tgl_approval)).defaultDMY : "-"}
              </TdFixed>
            </Tr>
          ))}
        </TBody>
      </Table>
    )

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <>
        <b>{`List Data ${title}`}</b>
        <DataTable />
        <Pagination
          dataLength={dataFilter.per_page}
          dataPage={
            totalData <= 10 ? data.length : data.map((_res, index) => {
              if (index === data.length - 1) {
                return TableNumber(dataFilter.page, dataFilter.per_page, index)
              } else {
                return null
              }
            })
          }
          dataNumber={data.map((_res, index) => {
            if (index === 0) {
              return TableNumber(dataFilter.page, dataFilter.per_page, index)
            } else {
              return null
            }
          })}
          dataCount={totalData}
          onDataLengthChange={(e) => {
            setDataFilter({
              ...dataFilter,
              per_page: e.target.value,
              page: 1
            })
          }}
          currentPage={dataFilter.page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setDataFilter({
            ...dataFilter,
            page: selected + 1
          })}
        />
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch 
              // value={dataFilter.q} 
              onChange={onInputSearchChange} />

            <FilterButton
              active={dataFilter?.active}
              onClick={() => setModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => history.push("/master/job-mix-design/list-item-barang", {
            pagination: {
              ...filter,
              jobmix: dataFilter
            },
          })}/>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <div style={{ minHeight: '500px' }}>
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() => dismissAlert()}
        />

        {isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." /> : <PageContent />}

        {modalFilter && (
          <ModalFilter
            show={modalFilter}
            setShow={setModalFilter}
            data={dataFilter}
            setData={setDataFilter}
            isApproval
          />
        )}
      </div>
    </CRUDLayout>
  )
}

export default RegistrasiJobMixDesign
