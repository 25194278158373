// React
import React, { useState, useEffect } from 'react';

// Router
import { useHistory, useLocation } from 'react-router-dom';

// Components
import { ButtonGroup } from 'react-bootstrap';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  UpdateButton,
  ReadButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  TdFixed,
  CreateButton,
  ApprovalStatusButton,
  FilterButton,
  BackButton,
  ActionButton,
} from 'components';
import { DateConvert, DecimalConvert, TableNumber } from 'utilities';

// API
import { CloseJobMixApi } from 'api';

// View Components
import { ModalFilter } from './Section';

// View Functions
// import { generateStatusApproval } from "../functions";

import { IoClose } from 'react-icons/io5';

const ProduksiList = ({ setNavbarTitle }) => {
  // Hooks
  const history = useHistory();
  const location = useLocation();

  let createAlert = location?.state?.alert?.variant;

  // States
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataJobMix, setDataJobMix] = useState([]);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.produksi?.filter?.active,
      tgl_mulai_produksi: location?.state?.produksi?.filter?.tgl_mulai_produksi,
      tgl_selesai_produksi:
        location?.state?.produksi?.filter?.tgl_selesai_produksi,
      tgl_mulai_sales_order:
        location?.state?.produksi?.filter?.tgl_mulai_sales_order,
      tgl_selesai_sales_order:
        location?.state?.produksi?.filter?.tgl_selesai_sales_order,
      barang_jadi: location?.state?.produksi?.filter?.barang_jadi,
    },
    pagination: {
      page: location?.state?.produksi?.pagination?.page ?? '1',
      dataLength: location?.state?.produksi?.pagination?.dataLength ?? '10',
      totalPage: location?.state?.produksi?.pagination?.totalPage ?? '1',
      dataCount: location?.state?.produksi?.pagination?.dataCount ?? '0',
    },
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    CloseJobMixApi.getProduksi({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_mulai_produksi: dataFilter?.filter?.tgl_mulai_produksi,
      tgl_selesai_produksi: dataFilter?.filter?.tgl_selesai_produksi,
      tgl_mulai_sales_order: dataFilter?.filter?.tgl_mulai_sales_order,
      tgl_selesai_sales_order: dataFilter?.filter?.tgl_selesai_sales_order,
      barang_jadi: dataFilter?.filter?.barang_jadi,
    })
      .then((data) => {
        setDataJobMix(data?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          },
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };
  // const checkAlert = () => {
  //   const locationState = location.state

  //   if (locationState) {
  //     if (locationState.alert) {
  //       setAlertConfig({
  //         show: locationState.alert.show,
  //         text: locationState.alert.text,
  //         variant: locationState.alert.variant,
  //       })
  //     }
  //   }
  // }
  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: '1',
        dataLength: '10',
        totalPage: '1',
        dataCount: '0',
      },
    });
    setAlertConfig({
      show: key ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + key,
    });
  };

  useEffect(() => {
    setNavbarTitle('Close Produksi');
    if (createAlert !== undefined) {
      // setShowAlert(true);
      setAlertConfig({
        variant: location?.state?.alert?.variant,
        text: location?.state?.alert?.text,
        show: true,
      });

      getInitialData();
      history.replace((createAlert = undefined));
    } else {
      return getInitialData();
    }

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_mulai_produksi,
    dataFilter?.filter?.tgl_selesai_produksi,
    dataFilter?.filter?.tgl_mulai_sales_order,
    dataFilter?.filter?.tgl_selesai_sales_order,
    dataFilter?.filter?.barang_jadi,
  ]);

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Informasi Produksi</ThFixed>
            <ThFixed>Informasi Sales Order</ThFixed>
            <Th>Item Produksi</Th>
            <Th noPadding width={100}>
              Qty. Produksi
            </Th>
            <Th noPadding width={100}>
              Total Qty. Telah Direalisasi
            </Th>
            <ThFixed>Aksi</ThFixed>
          </tr>
        </thead>
        <tbody>
          {dataJobMix.map((val, index) => (
            <tr key={index}>
              <Td textCenter>
                {TableNumber(
                  dataFilter?.pagination?.page,
                  dataFilter?.pagination?.dataLength,
                  index,
                )}
              </Td>
              <Td className="text-nowrap">
                <div>
                  {val.tgl_jobmix
                    ? DateConvert(new Date(val.tgl_jobmix)).defaultDMY
                    : '-'}
                </div>
                <div>{val.no_jobmix ?? '-'}</div>
              </Td>
              <Td>
                <div>
                  {val.tgl_sales_order
                    ? DateConvert(new Date(val.tgl_sales_order)).defaultDMY
                    : '-'}
                </div>
                <div>{val.no_sales_order ?? '-'}</div>
              </Td>
              <Td>{val.nama_item ?? '-'}</Td>
              <Td>
                {val.qty_jobmix
                  ? `${DecimalConvert(val.qty_jobmix).getWithComa} ${
                      val.kode_satuan ?? ''
                    }`
                  : '-'}
              </Td>
              <Td>
                {val.qty_realisasi
                  ? `${DecimalConvert(val.qty_realisasi).getWithComa} ${
                      val.kode_satuan ?? ''
                    }`
                  : '-'}
              </Td>
              <Td>
                <ButtonGroup size="sm">
                  <ReadButton
                    onClick={() =>
                      history.push(
                        '/transaksi/job-mix/detail/' + val.id_jobmix,
                        {
                          ...location?.state,
                          produksi: dataFilter,
                        },
                      )
                    }
                  />
                  <ActionButton
                    variant="danger"
                    size="sm"
                    text={<IoClose />}
                    onClick={() =>
                      history.push(
                        '/transaksi/close-job-mix/produksi/tambah/' +
                          val.id_jobmix,
                        {
                          ...location?.state,
                          produksi: dataFilter,
                        },
                      )
                    }
                  />
                </ButtonGroup>
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    );

    if (!dataJobMix || dataJobMix.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <b>List Data Produksi Yang Siap Di Close</b>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              value={searchConfig.key}
              onChange={onInputSearchChange}
            />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.goBack()} size="sm" />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}

      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default ProduksiList;
