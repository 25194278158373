import Services from '../../../services'

class PabrikApi {
    page(value) {
        return Services.get(`/pabrik/page`, {params: {...value}});
    }
    single(value) {
        return Services.get(`/pabrik/single`, {params: {...value}});
    }
    create(value) {
        return Services.post(`/pabrik`, value);
    }
    delete(value) {
        return Services.post(`/pabrik/delete`, value);
    }
    update(value) {
        return Services.put(`/pabrik`, value);
    }
    show(value) {
        return Services.put(`/pabrik/show`, value);
    }
    hide(value) {
        return Services.put(`/pabrik/hide`, value);
    }
    no_baru() {
        return Services.get(`/pabrik/no_baru`);
    }
    no_gudang_baru() {
        return Services.get(`/pabrik/no_gudang_baru`);
    }
    dropdown(value) {
        return Services.get(`/pabrik/dropdown`, {params: {...value}});
    }

    // mapping_pabrik_gudang
    mapping_pabrik_gudang(params) {
        return Services.get("/mapping_pabrik_gudang", { params });
    }
    mapping_pabrik_gudang_dropdown(params) {
        return Services.get("/mapping_pabrik_gudang/dropdown", { params });
    }
    mapping_pabrik_gudang_store(params) {
        return Services.post("/mapping_pabrik_gudang", params);
    }
    mapping_pabrik_gudang_delete(params) {
        return Services.post("/mapping_pabrik_gudang/delete", params);
    }

    // mapping_pabrik_alat_mesin
    mapping_pabrik_alat_mesin(params) {
        return Services.get("/mapping_pabrik_alat_mesin", { params });
    }
    mapping_pabrik_alat_mesin_dropdown(params) {
        return Services.get("/mapping_pabrik_alat_mesin/dropdown", { params });
    }
    mapping_pabrik_alat_mesin_store(params) {
        return Services.post("/mapping_pabrik_alat_mesin", params);
    }
    mapping_pabrik_alat_mesin_delete(params) {
        return Services.post("/mapping_pabrik_alat_mesin/delete", params);
    }
}

export default new PabrikApi();