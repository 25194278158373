import Services from "services";

class RealisasiProduksiAsetApi {
  async getList(params) {
    const fetch = await Services.get("/realisasi_produksi_aset/page", {
      params,
    });
    return fetch.data;
  }

  async getListProduksiAvailable(params) {
    const fetch = await Services.get("/realisasi_produksi_aset/available", {
      params,
    });
    return fetch.data;
  }

  async getSingleProduksiAsetAvailable(params) {
    const fetch = await Services.get(
      "/realisasi_produksi_aset/available_detail",
      {
        params,
      }
    );
    return fetch.data;
  }

  async getSingleProduksiAset(params) {
    const fetch = await Services.get("/realisasi_produksi_aset/single", {
      params,
    });
    return fetch.data;
  }

  async getDetailItemAset(params) {
    const fetch = await Services.get("/realisasi_produksi_aset/detail_aset", {
      params,
    });
    return fetch.data;
  }

  async getDropdownItem(params) {
    const fetch = await Services.get("/dropdown/item_produksi_aset", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_item,
      value: val.id_item,
      id_satuan: val.id_satuan,
      nama_satuan: val.nama_satuan,
      harga_satuan: val.harga_satuan,
    }));
  }

  async getSalinKomponen(params) {
    const fetch = await Services.get(
      "/realisasi_produksi_aset/salin_componen",
      {
        params,
      }
    );

    return fetch?.data?.data.map((val) => ({
      label: val.nama_aset,
      value: val.id_produksi_aset,
      no_produksi_aset: val.no_produksi_aset,
      tgl_produksi_aset: val.tgl_produksi_aset,
    }));
  }

  async getDropdownGudang(params) {
    const fetch = await Services.get("/dropdown/gudang", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_gudang,
      value: val.id_gudang,
    }));
  }

  async getDropdownwPetugasPenerimaan(params) {
    const fetch = await Services.get("/realisasi_produksi_aset/dropdown", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_karyawan,
      value: val.id_karyawan,
    }));
  }

  async getDropdownPabrikan(params) {
    const fetch = await Services.get("/realisasi_produksi_aset/dropdown", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_pabrikan,
      value: val.id_pabrikan,
    }));
  }

  async getDropdownSatuanPakai(params) {
    const fetch = await Services.get("/realisasi_produksi_aset/dropdown", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_satuan,
      value: val.id_satuan,
    }));
  }

  async getDropdownKondisiAset(params) {
    const fetch = await Services.get("/realisasi_produksi_aset/dropdown", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_kondisi_aset,
      value: val.id_kondisi_aset,
    }));
  }

  async getProvinsi(params) {
    const fetch = await Services.get("/realisasi_produksi_aset/dropdown", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_provinsi,
      value: val.id_provinsi,
    }));
  }

  async getKabupaten(params) {
    const fetch = await Services.get("/realisasi_produksi_aset/dropdown", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_kabupaten,
      value: val.id_kabupaten,
    }));
  }

  async getKecamatan(params) {
    const fetch = await Services.get("/realisasi_produksi_aset/dropdown", {
      params,
    });
    return fetch?.data?.data.map((val) => ({
      label: val.nama_kecamatan,
      value: val.id_kecamatan,
    }));
  }

  async getDesa(params) {
    const fetch = await Services.get("/realisasi_produksi_aset/dropdown", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_desa,
      value: val.id_desa,
    }));
  }

  generateNumberAset(params) {
    return Services.get("/realisasi_produksi_aset/no_baru_aset", { params });
  }

  generateNumber(params) {
    return Services.get("/realisasi_produksi_aset/no_baru", { params });
  }

  create(data) {
    return Services.post("/realisasi_produksi_aset", data);
  }

  createRegisterAset(data) {
    return Services.post("/realisasi_produksi_aset/registrasi_aset", data);
  }

  update(data) {
    return Services.put("/realisasi_produksi_aset", data);
  }

  upload(data) {
    return Services.post("/upload/permintaan_aset_multiple", data);
  }
}

export default new RealisasiProduksiAsetApi();
