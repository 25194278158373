import {
  DataStatus,
  SelectSearch,
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
} from "components";
import { InputQtyDecimal } from "components/Input";
import { DecimalConvert, RupiahConvert } from "utilities";

export const ListBarangJadiSection = ({ data, readOnly = false }) => {
  let total = 0;

  const ppn = data.ppn ? (data.ppn === "" ? 0 : parseFloat(data.ppn)) : 0;

  const diskon = data.diskon
    ? data.diskon === ""
      ? 0
      : parseFloat(data.diskon)
    : 0;

  return (
    <>
      <small>List Barang Jadi RAB</small>
      <br />

      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Kode Barang Jadi</ThFixed>
            <Th>Nama Barang Jadi</Th>
            <Th>Gudang</Th>
            <ThFixed>Qty. RAB</ThFixed>
            <ThFixed>Satuan</ThFixed>
            <ThFixed>Harga Satuan</ThFixed>
            <ThFixed>Sub Total</ThFixed>
          </Tr>
        </THead>

        <TBody>
          {data?.detail?.length > 0 ? (
            <>
              {data?.detail.map((val, index) => {
                const sub_total =
                  val.qty_sales_order && val.unit_cost
                    ? parseFloat(val.unit_cost) *
                      parseFloat(val.qty_sales_order)
                    : 0;
                total = total + sub_total;

                return (
                  <Tr key="index">
                    <TdFixed>{index + 1}</TdFixed>
                    <TdFixed>{val.kode_barang}</TdFixed>
                    <Td>{val.nama_barang}</Td>
                    {readOnly ? (
                      <Td>{val?.nama_gudang ?? "-"}</Td>
                    ) : (
                      <Td noPadding>
                        <SelectSearch
                          label=""
                          noMargin
                          placeholder="Pilih Gudang"
                          defaultValue={
                            val.id_gudang
                              ? {
                                  value: val.id_gudang,
                                  label: val.gudang.find(
                                    (e) => val.id_gudang === e.id_gudang
                                  )?.nama_gudang,
                                }
                              : ""
                          }
                          option={val.gudang.map((e) => {
                            return {
                              value: e.id_gudang,
                              label: e.nama_gudang,
                            };
                          })}
                        />
                      </Td>
                    )}
                    <TdFixed className="text-right">
                      {DecimalConvert(val.qty_sales_order).getWithComa}
                    </TdFixed>
                    <TdFixed>{val.kode_satuan_jual ?? val.kode_satuan}</TdFixed>
                    <TdFixed className="text-right">
                      {
                        RupiahConvert(parseFloat(val.unit_cost ?? 0).toString())
                          .getWithComa
                      }
                    </TdFixed>
                    <TdFixed className="text-right">
                      {
                        RupiahConvert(parseFloat(sub_total).toString())
                          .getWithComa
                      }
                    </TdFixed>
                  </Tr>
                );
              })}
              <Tr>
                <Td colSpan={7} className="text-right">
                  <b>Total</b>
                </Td>
                <TdFixed className="text-right">
                  {RupiahConvert(parseFloat(total).toString()).getWithComa}
                </TdFixed>
              </Tr>
              <Tr>
                <Td colSpan={6} className="text-right">
                  <b>Diskon (%)</b>
                </Td>
                <Td noPadding>
                  <InputQtyDecimal value={data.diskon} disabled={readOnly} />
                </Td>
                <TdFixed className="text-right">
                  {
                    RupiahConvert(parseFloat((total * diskon) / 100).toString())
                      .getWithComa
                  }
                </TdFixed>
              </Tr>
              <Tr>
                <Td colSpan={7} className="text-right">
                  <b>Total Setelah Dikurangi Diskon</b>
                </Td>
                <TdFixed className="text-right">
                  {
                    RupiahConvert(
                      (total - parseFloat((total * diskon) / 100)).toString()
                    ).getWithComa
                  }
                </TdFixed>
              </Tr>
              <Tr>
                <Td colSpan={6} className="text-right">
                  <b>PPn (%)</b>
                </Td>
                <Td noPadding>
                  <InputQtyDecimal value={data.ppn} disabled={readOnly} />
                </Td>
                <TdFixed className="text-right">
                  {
                    RupiahConvert(
                      parseFloat(
                        ((total - parseFloat((total * diskon) / 100)) * ppn) /
                          100
                      ).toString()
                    ).getWithComa
                  }
                </TdFixed>
              </Tr>
              <Tr>
                <Td colSpan={7} className="text-right">
                  <b>Total Setelah Ditambah PPn</b>
                </Td>
                <TdFixed className="text-right">
                  {
                    RupiahConvert(
                      parseFloat(
                        total -
                          parseFloat((total * diskon) / 100) +
                          parseFloat(
                            ((total - parseFloat((total * diskon) / 100)) *
                              ppn) /
                              100
                          )
                      ).toString()
                    ).getWithComa
                  }
                </TdFixed>
              </Tr>
            </>
          ) : (
            <Tr>
              <Td colSpan={8}>
                <DataStatus text="Tidak Ada Data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </>
  );
};
