import Services from "services";

class PerimntaanProduksiAsetApi {
  async getList(params) {
    const fetch = await Services.get("/permintaan_produksi_aset/page", {
      params,
    });
    return fetch.data;
  }

  async getListProduksiAvailable(params) {
    const fetch = await Services.get("/permintaan_produksi_aset/available", {
      params,
    });
    return fetch.data;
  }

  async getSingleProduksiAsetAvailable(params) {
    const fetch = await Services.get(
      "/permintaan_produksi_aset/available_detail",
      {
        params,
      }
    );
    return fetch.data;
  }

  async getSingleProduksiAset(params) {
    const fetch = await Services.get("/permintaan_produksi_aset/single", {
      params,
    });
    return fetch.data;
  }

  async getDropdownItem(params) {
    const fetch = await Services.get("/dropdown/item_produksi_aset", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_item,
      value: val.id_item,
      id_satuan: val.id_satuan,
      nama_satuan: val.nama_satuan,
      harga_satuan: val.harga_satuan,
    }));
  }

  async getSalinKomponen(params) {
    const fetch = await Services.get(
      "/permintaan_produksi_aset/salin_componen",
      {
        params,
      }
    );

    return fetch?.data?.data.map((val) => ({
      label: val.nama_aset,
      value: val.id_produksi_aset,
      no_produksi_aset: val.no_produksi_aset,
      tgl_produksi_aset: val.tgl_produksi_aset,
    }));
  }

  async getDropdownGudang(params) {
    const fetch = await Services.get("/dropdown/gudang", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_gudang,
      value: val.id_gudang,
    }));
  }

  generateNumber(params) {
    return Services.get("/permintaan_produksi_aset/no_baru", { params });
  }

  create(data) {
    return Services.post("/permintaan_produksi_aset", data);
  }

  update(data) {
    return Services.put("/permintaan_produksi_aset", data);
  }

  upload(data) {
    return Services.post("/upload/permintaan_aset_multiple", data);
  }
}

export default new PerimntaanProduksiAsetApi();
