import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
  CRUDLayout,
  Input,
  InputSearch,
  CreateButton,
  ActionButton,
  DataStatus,
  CreateModal,
  UpdateModal,
  DeleteModal,
  UpdateButton,
  Alert,
  Pagination,
  THead,
  TBody,
  ThFixed,
  Switch,
  TdFixed,
  Tr,
  Th,
  Td,
  DeleteButton,
  TextArea,
  SelectSearch,
} from "components";
import { Formik } from "formik";
import * as Yup from "yup";
import { JenisBarangJadiApi } from "api";
import { PageNumber } from "utilities";
import { Col } from "react-bootstrap";
import Axios from "axios";

const JenisBarangJadi = ({ setNavbarTitle }) => {
  const title = "Jenis Barang Jadi";

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(false);

  // STATE SEARCHING
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  // MODALS SHOW STATE
  const [isCreateForm, setIsCreateForm] = useState(false); // MODAL TAMBAH STATE
  const [isUpdateForm, setIsUpdateForm] = useState(false); // MODAL UPDATE STATE
  const [isDeleteData, setIsDeleteData] = useState(false); // MODAL HAPUS STATE

  // STATE DATA SATUAN
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [dropdownKelompok, setDropdownKelompok] = useState([]);
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState([]);
  // STATE MENAMPUNG DATA YANG AKAN DI HAPUS
  const [deleteData, setDeleteData] = useState([]);
  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [searchTime, setSearchTime] = useState(null);

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    Axios.all([
      JenisBarangJadiApi.getPage(page, dataLength, searchConfig.key),
      JenisBarangJadiApi.getKelompokItem(),
    ])
      .then(
        Axios.spread((res, kelompok) => {
          setData(res.data.data);
          setTotalPage(res.data.total_page);
          setDataCount(res.data.data_count);
          setDropdownKelompok(kelompok.data.data);
        })
      )
      .catch((err) => alert(err))
      .finally(() => {
        // if (searchKey !== "") {
        //   setAlertConfig({
        //     variant: "primary",
        //     text: `Hasil Pencarian : ${searchKey}`,
        //   });
        //   setShowAlert(true);
        // } else {
        //   setShowAlert(false);
        // }
        setIsLoading(false);
      });
  };

  // FUNCTION SWITCH HIDDEN DATA
  const changeDataStatus = (status, id) => {
    setIsLoading(true);
    setShowAlert(false);

    const value = {
      id_jenis: id,
    };

    const onLoadedSuccess = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      });
      setShowAlert(true);
    };

    status === true
      ? JenisBarangJadiApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : JenisBarangJadiApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };

  // FUNCTION CARI DATA DARI SERVER
  // const searchData = (e) => {
  //   e.preventDefault();
  //   setIsLoading(true);

  //   const key = searchKey; // SearchKey Value as key

  //   // SEARCH DATA WITH KEY FROM DATABASE
  //   JenisBarangJadiApi.search(key)
  //     .then((res) => {
  //       setData(res.data.data);
  //       setTotalPage(res.data.total_page);
  //     })
  //     .catch((err) => alert(err))
  //     .finally(() => {
  //       setIsSearching(true);
  //       setIsLoading(false);
  //       setAlertConfig({
  //         variant: "primary",
  //         text: `Hasil Pencarian : ${key}`,
  //       });
  //       setShowAlert(true);
  //     });
  // };
  const onInputSearchChange = (e) => {
    const key = e.target.value;
    if(searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: e.target.value
        });
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
      }, 750)
    )

  }

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [setNavbarTitle, page, dataLength, searchConfig.key]);

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    id_kelompok: Yup.string().required("Pilih Kelompok Item"),
    nama_jenis: Yup.string().required("Nama Jenis Barang Jadi wajib diisi"),
  });

  // MODAL TAMBAH COMPONENT
  const TambahModal = () => {
    // FORMIK INITIAL VALUES
    const formInitialValues = {
      // id_kelompok: 27,
      // nama_kelompok: "BARANG JADI",
      id_kelompok: "",
      nama_jenis: "",
      keterangan_jenis: "",
    };

    // KIRIM DATA BARU KE SERVER
    const formSubmitHandler = (values) => {
      JenisBarangJadiApi.create(values)
        .then(() => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Tambah data berhasil!",
          });
        })
        .catch((err) => {
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsCreateForm(false);
          // TAMPILKAN ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <CreateModal show={isCreateForm} onHide={() => setIsCreateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <SelectSearch
                  label="Kelompok Item"
                  name="id_kelompok"
                  onChange={(val) => setFieldValue("id_kelompok", val.value)}
                  option={dropdownKelompok.map((val) => {
                    return {
                      value: val.id_kelompok,
                      label: val.nama_kelompok,
                    };
                  })}
                  defaultValue={{
                    value: values.id_kelompok ? values.id_kelompok : "",
                    label: values.nama_kelompok ? values.nama_kelompok : "Pilih Kelompok",
                  }}
                  error={errors.id_kelompok && touched.id_kelompok && true}
                  errorText={errors.id_kelompok && touched.id_kelompok && errors.id_kelompok}
                />

                <Input
                  label="Nama Jenis Barang Jadi"
                  type="text"
                  name="nama_jenis"
                  value={values.nama_jenis}
                  onChange={handleChange}
                  error={errors.nama_jenis && touched.nama_jenis && true}
                  errorText={errors.nama_jenis}
                />

                <TextArea
                  label="Keterangan"
                  name="keterangan_jenis"
                  value={values.keterangan_jenis}
                  onChange={handleChange}
                />
              </Modal.Body>

              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Tambah"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </CreateModal>
    );
  };

  // MODAL UBAH COMPONENT
  const UbahModal = () => {
    const formInitialValues = {
      id_jenis: updateData.id_jenis,
      id_kelompok: updateData.id_kelompok,
      nama_kelompok: updateData.nama_kelompok,
      nama_jenis: updateData.nama_jenis,
      keterangan_jenis: updateData.keterangan_jenis,
    };

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandler = (values) => {
      // SEND UPDATED DATA TO SERVER
      JenisBarangJadiApi.update(values)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Ubah data berhasil!",
          });
        })
        .catch((err) => {
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Ubah data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE UPDATE MODAL FORM
          setIsUpdateForm(false);
          // SHOW ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <UpdateModal show={isUpdateForm} onHide={() => setIsUpdateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <SelectSearch
                  label="Kelompok Item"
                  name="id_kelompok"
                  onChange={(val) => setFieldValue("id_kelompok", val.value)}
                  option={dropdownKelompok.map((val) => {
                    return {
                      value: val.id_kelompok,
                      label: val.nama_kelompok,
                    };
                  })}
                  defaultValue={{
                    value: values.id_kelompok ? values.id_kelompok : "",
                    label: values.nama_kelompok ? values.nama_kelompok : "Pilih Kelompok",
                  }}
                  error={errors.id_kelompok && touched.id_kelompok && true}
                  errorText={errors.id_kelompok && touched.id_kelompok && errors.id_kelompok}
                />

                <Input
                  label="Nama Jenis Barang Jadi"
                  type="text"
                  name="nama_jenis"
                  value={values.nama_jenis}
                  onChange={handleChange}
                  error={errors.nama_jenis && touched.nama_jenis && true}
                  errorText={errors.nama_jenis}
                />

                <TextArea
                  label="Keterangan"
                  name="keterangan_jenis"
                  value={values.keterangan_jenis}
                  onChange={handleChange}
                />
              </Modal.Body>

              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Ubah"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </UpdateModal>
    );
  };

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_jenis: deleteData.id_jenis };

    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);

      JenisBarangJadiApi.delete(deleteValue)
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          });
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false);
          // TAMPIL ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
      >
        <div>Nama Jenis : {deleteData.nama_jenis}</div>
      </DeleteModal>
    );
  };

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <Th>Nama Jenis Barang Jadi</Th>
              <Th>Kelompok Barang Jadi</Th>
              <Th>Keterangan</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_jenis}>
                  <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                  <TdFixed>
                    <div className="d-flex justify-content-center">
                      <UpdateButton
                        size="sm"
                        className="m-1"
                        onClick={() => {
                          setUpdateData(val);
                          setIsUpdateForm(true);
                        }}
                      />
                      <DeleteButton
                        size="sm"
                        className="m-1"
                        onClick={() => {
                          setDeleteData(val);
                          setIsDeleteData(true);
                        }}
                      />
                      <Switch
                        id={val.id_jenis}
                        checked={val.is_hidden ? false : true}
                        onChange={() => changeDataStatus(val.is_hidden, val.id_jenis)}
                      />
                    </div>
                  </TdFixed>
                  <Td>{val.nama_jenis}</Td>
                  <Td>{val.nama_kelompok}</Td>
                  <Td>{val.keterangan_jenis}</Td>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataNumber={page * dataLength - dataLength + 1}
            dataPage={page * dataLength}
            dataCount={dataCount}
            onDataLengthChange={(e) => {
              setPage(1);
              setDataLength(e.target.value);
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="row">
            <Col md="8">
              <InputSearch
                onChange={onInputSearchChange}
                // onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => setIsCreateForm(true)} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      <TambahModal />
      <UbahModal />
      <HapusModal />
    </CRUDLayout>
  );
};

export default JenisBarangJadi;
