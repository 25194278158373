import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { SelectSearch, Input, TextEditor } from "components";
import { EditorState, ContentState, convertFromHTML, convertToRaw, convertFromRaw } from "draft-js";
import { RegistrasiBarangJadiApi } from "api";
import draftToHtml from "draftjs-to-html";

const TabInfo = ({ formik, dropdown, handleKelompokOnChange, type: TYPE }) => {
  const { values, errors, touched, setFieldValue, setValues } = formik;
  const [textEditorState, setTextEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(formik?.values?.uraian ?? "<p></p>"))
    )
  );

  const getNoBaru = () => {
    RegistrasiBarangJadiApi.no_baru().then(({ data }) =>
      setFieldValue("kode_item", data?.data ?? "")
    );
  };

  const onUraianChangeHandler = (value) => {
    const convertData = convertToRaw(value.getCurrentContent());

    setTextEditorState(value);
    setFieldValue("uraian", draftToHtml(convertData));
  };

  useEffect(() => {
    Boolean(!values.kode_item) && getNoBaru();
    return () => {};
  }, []);

  return (
    <Row>
      <Col lg>
        <Row>
          <Col md="12">
            <Input
              label="Kode Barang Jadi"
              placeholder="Kode Barang Jadi"
              type="text"
              name="kode_item"
              value={values.kode_item}
              readOnly={true}
            />
          </Col>

          <Col md="12">
            <Input
              label="Nama Barang Jadi"
              placeholder="Nama Barang Jadi"
              type="text"
              value={values.nama_item}
              onChange={(e) => setFieldValue("nama_item", e.target.value)}
              error={Boolean(errors.nama_item && touched.nama_item)}
              errorText={Boolean(errors.nama_item && touched.nama_item) && errors.nama_item}
              disabled={TYPE === "DETAIL"}
            />
          </Col>

          <Col md="12">
            <Input
              label="Nama Varian"
              placeholder="Nama Varian"
              type="text"
              name="nama_varian"
              value={values.nama_varian}
              onChange={(e) => setFieldValue("nama_varian", e.target.value)}
              error={
                Boolean(errors.nama_varian && touched.nama_varian) ||
                (Boolean(errors.nama_item && touched.nama_item) &&
                  errors.nama_item === "Nama Barang Jadi & Nama Varian telah digunakan")
              }
              errorText={
                Boolean(errors.nama_varian && touched.nama_varian)
                  ? errors.nama_varian
                  : Boolean(errors.nama_item && touched.nama_item)
                  ? "Nama Barang Jadi & Nama Varian telah digunakan"
                  : ""
              }
              disabled={TYPE === "DETAIL"}
            />
          </Col>

          <Col md="6">
            <SelectSearch
              label="Kelompok Barang Jadi"
              placeholder="Pilih Kelompok Barang Jadi"
              onChange={(val) => {
                setValues({ ...values, id_kelompok: val.value, id_jenis: "" });
                handleKelompokOnChange(val.value);
              }}
              option={dropdown?.kelompok ?? []}
              defaultValue={
                values.id_kelompok
                  ? dropdown?.kelompok?.find((item) => item.value === values.id_kelompok)
                  : ""
              }
              error={Boolean(errors.id_kelompok && touched.id_kelompok)}
              errorText={Boolean(errors.id_kelompok && touched.id_kelompok) && errors.id_kelompok}
              isDisabled={TYPE === "DETAIL"}
            />
          </Col>

          <Col md="6">
            <SelectSearch
              label="Jenis Barang Jadi"
              placeholder="Pilih Jenis Barang Jadi"
              onChange={(val) => setFieldValue("id_jenis", val.value)}
              option={dropdown?.jenis ?? []}
              defaultValue={
                values.id_jenis
                  ? dropdown?.jenis?.find((item) => item.value === values.id_jenis)
                  : ""
              }
              error={Boolean(errors.id_jenis && touched.id_jenis)}
              errorText={Boolean(errors.id_jenis && touched.id_jenis) && errors.id_jenis}
              isDisabled={values.id_kelompok === "" || TYPE === "DETAIL" ? true : false}
            />
          </Col>

          <Col md="12">
            <SelectSearch
              label="Pabrikan"
              placeholder="Pilih Pabrikan"
              onChange={(val) => setFieldValue("id_pabrikan", val.value)}
              option={dropdown?.pabrikan ?? []}
              defaultValue={
                values.id_pabrikan
                  ? dropdown?.pabrikan?.find((item) => item.value === values.id_pabrikan)
                  : ""
              }
              error={Boolean(errors.id_pabrikan && touched.id_pabrikan)}
              errorText={Boolean(errors.id_pabrikan && touched.id_pabrikan) && errors.id_pabrikan}
              isDisabled={TYPE === "DETAIL"}
            />
          </Col>

          <Col md="4">
            <SelectSearch
              label="Satuan Pakai"
              placeholder="Pilih Satuan Pakai"
              onChange={(val) => setFieldValue("id_satuan", val.value)}
              option={dropdown?.satuan ?? []}
              defaultValue={
                values.id_satuan
                  ? dropdown?.satuan?.find((item) => item.value === values.id_satuan)
                  : ""
              }
              error={Boolean(errors.id_satuan && touched.id_satuan)}
              errorText={Boolean(errors.id_satuan && touched.id_satuan) && errors.id_satuan}
              isDisabled={TYPE === "DETAIL"}
            />
          </Col>

          <Col md="4">
            <SelectSearch
              label="Satuan Beli"
              placeholder="Pilih Satuan Beli"
              onChange={(val) => setFieldValue("id_satuan_beli", val.value)}
              option={dropdown?.satuan ?? []}
              defaultValue={
                values.id_satuan_beli
                  ? dropdown?.satuan?.find((item) => item.value === values.id_satuan_beli)
                  : ""
              }
              error={Boolean(errors.id_satuan_beli && touched.id_satuan_beli)}
              errorText={
                Boolean(errors.id_satuan_beli && touched.id_satuan_beli) && errors.id_satuan_beli
              }
              isDisabled={TYPE === "DETAIL"}
            />
          </Col>

          <Col md="4">
            <SelectSearch
              label="Satuan Jual"
              placeholder="Pilih Satuan Jual"
              onChange={(val) => setFieldValue("id_satuan_jual", val.value)}
              option={dropdown?.satuan ?? []}
              defaultValue={
                values.id_satuan_jual
                  ? dropdown?.satuan?.find((item) => item.value === values.id_satuan_jual)
                  : ""
              }
              error={Boolean(errors.id_satuan_jual && touched.id_satuan_jual)}
              errorText={
                Boolean(errors.id_satuan_jual && touched.id_satuan_jual) && errors.id_satuan_jual
              }
              isDisabled={TYPE === "DETAIL"}
            />
          </Col>

          <Col md="12">
            <Input
              label="Link Referensi"
              placeholder="Link Referensi"
              type="text"
              value={values.link_referensi}
              onChange={(e) => setFieldValue("link_referensi", e.target.value)}
              error={Boolean(errors.link_referensi && touched.link_referensi)}
              errorText={
                Boolean(errors.link_referensi && touched.link_referensi) && errors.link_referensi
              }
              disabled={TYPE === "DETAIL"}
            />
          </Col>
        </Row>
      </Col>

      <Col lg>
        <TextEditor
          label="Uraian"
          placeholder="Uraian"
          editorState={textEditorState}
          onEditorStateChange={(val) => onUraianChangeHandler(val)}
          options={["inline", "list", "textAlign"]}
          editorStyle={{
            height: 320,
          }}
          readOnly={TYPE === "DETAIL"}
        />
      </Col>
    </Row>
  );
};

export default TabInfo;
