import React, { useState, useEffect } from "react";
import { ButtonGroup, Dropdown, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { debounce } from "lodash";
import {
  CRUDLayout,
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  Pagination,
  Alert,
} from "components";
import {
  InputSearch,
  DataStatus,
  ButtonFilter,
  ButtonDetail,
} from "components2";
import { tableNumber } from "utilities2";
import { ModalFilter } from "./__CatatanTransaksiHarianComps__";
import CatatanTransaksiHarianApi from "./__CatatanTransaksiHarianApi__";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { Formik } from "formik";
import { DecimalConvert } from "utilities";
import FileSaver from "file-saver";

export const CatatanTransaksiHarianList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: "",
    variant: "primary",
  });

  const [filter, setFilter] = useState({
    q: "",
    page: 1,
    per_page: 10,
    tanggal_awal: undefined,
    tanggal_akhir: undefined,
    id_jenis: undefined,
    id_kelompok: undefined,
    id_item_buaso: undefined,
    active: false,
  });

  const [modalFilter, setModalFilter] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);

  const getPermintaanAset = useQuery(
    ["analisa_checklist", "list", filter],
    () => CatatanTransaksiHarianApi.getList(filter),
    { enabled: filter?.active }
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setFilter({
      ...filter,
      q: searchKey,
    });
    setAlertConfig({
      show: searchKey ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + searchKey,
    });
  };

  const onFilterButtonClickkHandler = () => setModalFilter(!modalFilter);

  console.log(modalFilter);

  const onPaginationChange = ({ selected }) =>
    setFilter({ ...filter, page: selected + 1 });

  const onPaginationDataLengthChange = (e) =>
    setFilter({ ...filter, page: 1, per_page: e.target.value });

  const checkAlert = () => {
    if (state) {
      if (state.alert) {
        setAlertConfig({
          show: state?.alert?.show,
          text: state?.alert?.text,
          variant: state?.alert?.variant,
        });
      }
    }
  };

  // FORM VALUES FILTER
  const formInitialValuesFilter = {
    tanggal_awal: filter?.tanggal_awal,
    tanggal_akhir: filter?.tanggal_akhir,
    id_jenis: filter?.id_jenis,
    id_kelompok: filter?.id_kelompok,
    id_item_buaso: filter?.id_item_buaso,
  };

  const formSubmitHandlerFilter = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setFilter({
      ...filter,
      ...values,
      active: checkActive,
      page: 1,
    });
    onFilterButtonClickkHandler();
  };

  const exportData = (tipe) => {
    setLoadingExport(true);

    CatatanTransaksiHarianApi.export({
      tipe,
      tanggal_awal: filter?.tanggal_awal,
      tanggal_akhir: filter?.tanggal_akhir,
      id_jenis: filter?.id_jenis,
      id_kelompok: filter?.id_kelompok,
      id_item_buaso: filter?.id_item_buaso,
    })
      .then((res) => {
        const data = res.data.data;

        const urlFile = data;
        FileSaver.saveAs(urlFile, data);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Export Data gagal",
        });
      })
      .finally(() => setLoadingExport(false));
  };

  useEffect(() => {
    setNavbarTitle("Catatan Transaksi Harian Produksi");
    checkAlert();
  }, []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={debounce(searchHandler, 700)}
              className="mr-2"
            />
            <ButtonFilter
              size="sm"
              className="text-nowrap"
              onClick={onFilterButtonClickkHandler}
              active={filter?.active}
            />
            {/* Export Button */}
            <Dropdown>
              <Dropdown.Toggle
                disabled={loadingExport}
                className="ml-2 btn btn-warning text-white"
              >
                {loadingExport ? (
                  <Spinner animation="border" variant="white" size="sm" />
                ) : (
                  "Export Data"
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  // disabled={!dataFilter?.filter.active}
                  onClick={() =>
                    // dataFilter?.filter?.active &&
                    exportData("excel")
                  }
                >
                  Excel (.xlsx)
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection></CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {getPermintaanAset.isLoading || getPermintaanAset.isError ? (
        <DataStatus
          loading={getPermintaanAset.isLoading}
          text={
            getPermintaanAset.isLoading ? "Memuat . . ." : "Data gagal dimuat"
          }
        />
      ) : (
        <>
          <Alert
            show={alertConfig?.show}
            text={alertConfig?.text}
            variant={alertConfig?.variant}
            showCloseButton
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Catatan Transaksi Harian Produksi
            </small>
          </div>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Kode Item Bahan Produksi</ThFixed>
                <Th>Item Bahan Produksi</Th>
                <Th>Jenis Item</Th>
                <Th>Kelompok Item</Th>
                <Th>Total Qty. Realisasi Untuk Produksi</Th>
                <ThFixed>Aksi</ThFixed>
              </Tr>
            </THead>
            <TBody>
              {filter?.active ? (
                getPermintaanAset?.data?.data?.length > 0 ? (
                  getPermintaanAset?.data?.data?.map((e, index) => (
                    <Tr key={index}>
                      <TdFixed>
                        {tableNumber({
                          pageCurrent: filter.page,
                          dataLength: filter.per_page,
                          index,
                        })}
                      </TdFixed>
                      <TdFixed>{e?.kode_item ?? "-"}</TdFixed>
                      <Td>{e?.nama_item ?? "-"}</Td>
                      <Td>{e?.nama_jenis ?? "-"}</Td>
                      <Td>{e?.nama_kelompok ?? "-"}</Td>
                      <Td className="text-right">
                        {`${
                          DecimalConvert(parseFloat(e?.qty_realisasi ?? 0))
                            .getWithComa
                        } ${e?.satuan_pakai}`}
                      </Td>
                      <TdFixed>
                        <ButtonGroup>
                          <ButtonDetail
                            icon
                            noText
                            variant="primary"
                            onClick={() =>
                              history.push(
                                "/laporan/catatan-transaksi-harian/detail/" +
                                  e?.id_item_buaso,
                                {
                                  tanggal_awal: filter?.tanggal_awal,
                                  tanggal_akhir: filter?.tanggal_akhir,
                                }
                              )
                            }
                          />
                        </ButtonGroup>
                      </TdFixed>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={7}>
                      <DataStatus text="Tidak ada data" />
                    </Td>
                  </Tr>
                )
              ) : (
                <Tr>
                  <Td colSpan={7}>
                    <DataStatus text="FILTER TERLEBIH DAHULU" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Pagination
            dataLength={filter?.per_page}
            dataNumber={filter?.page * filter?.per_page - filter?.per_page + 1}
            dataPage={
              filter?.dataCount < filter?.per_page
                ? filter?.dataCount
                : filter?.page * filter?.per_page
            }
            dataCount={getPermintaanAset?.data?.data_count}
            currentPage={filter?.page}
            totalPage={getPermintaanAset?.data?.total_page}
            onPaginationChange={onPaginationChange}
            onDataLengthChange={onPaginationDataLengthChange}
          />
        </>
      )}

      {modalFilter && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesFilter}
          onSubmit={formSubmitHandlerFilter}
        >
          <ModalFilter toggle={onFilterButtonClickkHandler} />
        </Formik>
      )}
    </CRUDLayout>
  );
};
