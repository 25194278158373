// React
import React from "react";

// Form
import { useFormikContext } from "formik";

// Components
import { DatePicker, FilterModal } from "components";

import { DateConvert } from "utilities";

export const ModalFilter = ({ toggle, isPreForm }) => {
  const { values, handleSubmit, setValues } = useFormikContext();

  // HANDLE CHANGE
  const onResetButtonClick = (setValues) => {
    const myPromise = new Promise((resolve) => {
      setValues({
        tgl_permintaan_aset_mulai: undefined,
        tgl_permintaan_aset_selesai: undefined,
        tgl_produksi_aset_mulai: undefined,
        tgl_produksi_aset_selesai: undefined,
      });

      setTimeout(() => resolve(), 300);
    });

    myPromise.then(() => {});
  };

  const onTanggalChange = (
    dates,
    values,
    setValues,
    value_awal,
    value_akhir
  ) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      [value_awal]: startDate,
      [value_akhir]: endDate,
    });
  };

  return (
    <FilterModal
      isToggle
      toggleModal={toggle}
      onResetButtonClick={() => onResetButtonClick(setValues)}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        selectsRange
        label="Tgl. Permintaan Aset"
        placeholderText="Pilih tgl. permintaan aset"
        startDate={
          values?.tgl_permintaan_aset_mulai
            ? new Date(values?.tgl_permintaan_aset_mulai)
            : ""
        }
        endDate={
          values?.tgl_permintaan_aset_selesai
            ? new Date(values?.tgl_permintaan_aset_selesai)
            : ""
        }
        onChange={(dates) =>
          onTanggalChange(
            dates,
            values,
            setValues,
            "tgl_permintaan_aset_mulai",
            "tgl_permintaan_aset_selesai"
          )
        }
        monthsShown={2}
      />
      {!isPreForm && (
        <DatePicker
          selectsRange
          label="Tgl. Produksi Aset"
          placeholderText="Pilih tgl. produksi aset"
          startDate={
            values?.tgl_produksi_aset_mulai
              ? new Date(values?.tgl_produksi_aset_mulai)
              : ""
          }
          endDate={
            values?.tgl_produksi_aset_selesai
              ? new Date(values?.tgl_produksi_aset_selesai)
              : ""
          }
          onChange={(dates) =>
            onTanggalChange(
              dates,
              values,
              setValues,
              "tgl_produksi_aset_mulai",
              "tgl_produksi_aset_selesai"
            )
          }
          monthsShown={2}
        />
      )}
    </FilterModal>
  );
};
