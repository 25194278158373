import { Td, TdFixed, Tr } from "components";
import { Col, Row } from "react-bootstrap";
import { DateConvert } from "utilities";

export const InfoRAB = ({ data }) => {
  return (
    <Row>
      <Col md>
        <List label="Tgl. RAB">
          {data?.tgl_rab ? DateConvert(new Date(data.tgl_rab)).detail : "-"}
        </List>
        <List label="No. RAB">{data?.no_rab ?? "-"}</List>
      </Col>
    </Row>
  );
};
const List = ({ label, children }) => (
  <Tr>
    <Td>
      <b>{label}</b>
    </Td>
    <TdFixed>:</TdFixed>
    <Td>{children}</Td>
  </Tr>
);
