export const MODAL_TITLE = {
  CREATE: "Tambah",
  UPDATE: "Ubah",
  DETAIL: "Detail",
};

export const STATUS_APPROVAL = {
  PEN: "PENDING",
  VER: "VERIFIED",
  APP: "APPROVED",
  REV: "REVISE",
  REJ: "REJECT",
};

export const KODE_MODUL = [
  "PRY",
  "ASM",
  "TNK",
  "SIA",
  "ANG",
  "CRM",
  "PRO",
  "PRD",
  "SLS",
  "COC",
  "INV",
  "MKT",
  "HRDA",
].map((kode) => ({ value: kode, label: kode }));

export const getStatusPermintaan = (value) => {
  switch (value) {
    case true:
      return {
        variant: "outline-success",
        label: "SUDAH DIMINTA",
      };
    default:
      return {
        variant: "outline-danger",
        label: "BELUM DIMINTA",
      };
  }
};

export const monthOptions = [
  { label: "Januari", value: "01" },
  { label: "Februari", value: "02" },
  { label: "Maret", value: "03" },
  { label: "April", value: "04" },
  { label: "Mei", value: "05" },
  { label: "Juni", value: "06" },
  { label: "Juli", value: "07" },
  { label: "Agustus", value: "08" },
  { label: "September", value: "09" },
  { label: "Oktober", value: "10" },
  { label: "November", value: "11" },
  { label: "Desember", value: "12" },
];
