import { JobMixApi } from "api";
import { DataStatus } from "components";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { DateConvert } from "utilities";
import { FormSalesOrder } from "../sections/FormSalesOrder";
import { InfoPeluang } from "../sections/InfoPeluang";
import { InfoRAB } from "../sections/InfoRAB";
import { ListBarangJadiSection } from "../sections/ListBarangJadiSection";

export const ModalSalesOrder = ({ setModalConfig, modalConfig }) => {
  const [data, setData] = useState({});
  const [fetch, setFetch] = useState({
    loading: true,
    success: false,
  });

  useEffect(() => {
    if (modalConfig.id) {
      setFetch({
        loading: true,
        success: false,
      });

      JobMixApi.getDetailSalesOrder({ id_sales_order: modalConfig.id })
        .then((res) => {
          const data = res.data.data;

          setData({
            ...data,
            tgl_sales_order: data?.tgl_sales_order
              ? DateConvert(new Date(data.tgl_sales_order)).default
              : "",
            batas_waktu: data?.batas_waktu
              ? DateConvert(new Date(data.batas_waktu)).default
              : "",
            diskon: data?.diskon ? parseFloat(data.diskon) : 0,
            ppn: data?.ppn ? parseFloat(data.ppn) : 0,
          });

          setFetch({
            loading: false,
            success: true,
          });
        })
        .catch(() => {
          setFetch({
            loading: false,
            success: false,
          });
        });
    }
  }, [modalConfig.id, modalConfig.show]);

  return (
    <Modal
      scrollable
      show={modalConfig.show}
      onHide={() => setModalConfig({ show: false })}
      size="xl"
    >
      <Modal.Header closeButton>Detail Sales Order</Modal.Header>
      <Modal.Body>
        {fetch.loading || !fetch.success ? (
          <DataStatus
            loading={fetch.loading}
            text={fetch.loading ? "Memuat Data..." : "Data gagal dimuat!"}
          />
        ) : (
          <>
            <InfoPeluang data={data} />
            <hr />
            <InfoRAB data={data} />
            <hr />
            <FormSalesOrder readOnly data={data} />
            <ListBarangJadiSection readOnly data={data} />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
