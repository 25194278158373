const mappingFlag = (value, label, qty_jobmix = 1) => {
  return value[label].map((val) => {
    return {
      ...val,
      base_qty: val.qty ? parseFloat(val.qty) : 1,
      flag: val.flag ?? "UT",
    };
  });
};

export default mappingFlag;
