import { useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import {
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  ReadButton,
  DataStatus,
  Pagination,
  ActionButton,
  ApprovalStatusButton,
} from "components";
import { DateConvert, DecimalConvert, TableNumber } from "utilities";
import { ModalPermintaanProduksi } from "../Comps";

export const TablePermintaanProduksiList = ({
  data = [],
  pagination,
  setPagination,
  tab,
  fetchingStatus,
  onCreateDataSuccess,
  onCreateDataError,
}) => {
  const [modal, setModal] = useState({
    show: false,
    action: "",
    data: {},
  });

  if (fetchingStatus?.loading || fetchingStatus?.error) {
    return (
      <div className="p-3 bg-white border border-top-0">
        <DataStatus
          loading={fetchingStatus.loading}
          text={
            fetchingStatus.loading ? "Memuat data . . ." : "Data gagal dimuat"
          }
        />
      </div>
    );
  }

  return (
    <div className="p-3 bg-white border border-top-0">
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <Th style={{ width: 200 }} className="p-1">
              Informasi Permintaan Produksi
            </Th>
            <Th style={{ width: 200 }} className="p-1">
              Informasi Produksi
            </Th>
            <Th style={{ minWidth: 300 }} className="p-1">
              Item Permintaan Produksi
            </Th>
            <Th style={{ width: 150 }} className="p-1">
              Qty. Permintaan Produksi
            </Th>
            <Th style={{ width: 150 }} className="p-1">
              Qty. Telah Ditransfer
            </Th>
            <Th style={{ width: 150 }} className="p-1">
              Status Permintaan Produksi
            </Th>
          </Tr>
        </THead>
        <TBody>
          {data && data?.length > 0 ? (
            data.map((e, i) => (
              <Tr key={i}>
                <TdFixed>
                  {TableNumber(pagination?.page, pagination?.dataLength, i)}
                </TdFixed>
                <TdFixed>
                  <div className="d-flex">
                    <ReadButton
                      size="sm"
                      className="mr-1"
                      onClick={() =>
                        setModal({ show: true, action: "detail", data: e })
                      }
                    />
                    <ActionButton
                      size="sm"
                      variant="danger"
                      text={<IoCloseOutline />}
                      tooltip
                      tooltipText="Batalkan Permintaan"
                      onClick={() =>
                        setModal({ show: true, action: "confirm", data: e })
                      }
                    />
                  </div>
                </TdFixed>
                <Td>
                  <span className="d-block">
                    {e?.tgl_permintaan_produksi
                      ? DateConvert(new Date(e.tgl_permintaan_produksi))
                          .defaultDMY
                      : "-"}
                  </span>
                  <span className="d-block">
                    {e?.no_permintaan_produksi ?? "-"}
                  </span>
                </Td>
                <Td>
                  <span className="d-block">
                    {e?.tgl_produksi
                      ? DateConvert(new Date(e.tgl_produksi)).defaultDMY
                      : "-"}
                  </span>
                  <span className="d-block">{e?.no_produksi ?? "-"}</span>
                </Td>
                <Td>{e?.nama_item ?? "-"}</Td>
                <Td>
                  {e?.qty_permintaan_produksi
                    ? DecimalConvert(e.qty_permintaan_produksi).getWithComa
                    : "-" + " "}
                  {e?.satuan ?? ""}
                </Td>
                <Td>
                  {DecimalConvert(e?.qty_telah_ditransfer).getWithComa + " "}
                  {e?.satuan ?? ""}
                </Td>
                <Td>
                  <ApprovalStatusButton
                    size="sm"
                    variant={
                      e?.status_permintaan === "batal"
                        ? "outline-danger"
                        : e?.status_permintaan === "diproses"
                        ? "outline-warning"
                        : "outline-success"
                    }
                  >
                    {e?.status_permintaan === "batal"
                      ? "BATAL"
                      : e?.status_permintaan === "diproses"
                      ? "DIPROSES"
                      : "SELESAI"}
                  </ApprovalStatusButton>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={11}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
      <Pagination
        dataLength={pagination?.dataLength}
        dataNumber={
          pagination?.page * pagination?.dataLength - pagination?.dataLength + 1
        }
        dataPage={
          pagination?.dataCount < pagination?.dataLength
            ? pagination?.dataCount
            : pagination?.page * pagination?.dataLength
        }
        dataCount={pagination?.dataCount}
        currentPage={pagination?.page}
        totalPage={pagination?.totalPage}
        onPaginationChange={({ selected }) =>
          setPagination({
            ...pagination,
            page: selected + 1,
          })
        }
        onDataLengthChange={(e) =>
          setPagination({
            ...pagination,
            page: 1,
            dataLength: e.target.value,
          })
        }
      />
      <ModalPermintaanProduksi
        show={modal.show}
        action={modal.action}
        type={tab}
        data={modal.data}
        onCreateDataSuccess={onCreateDataSuccess}
        onCreateDataError={onCreateDataError}
        onHide={() =>
          setModal({
            show: false,
            data: {},
          })
        }
      />
    </div>
  );
};
