const totalSummary = (value, flag, values) => {
  return values[`analisa_${value}`]
    .filter((val) => val.flag === flag)
    .reduce((val, data) => {
      const harga =
        value === "alat_mesin"
          ? parseFloat(data.biaya_penyusutan_per_jam ?? 0)
          : parseFloat(data[`harga_satuan_${value}`] ?? 0);

      const qty_produksi =
        parseFloat(data.qty_produksi ?? 0) * parseFloat(values.qty_jobmix ?? 0);

      return val + parseFloat(harga ?? 0) * parseFloat(qty_produksi ?? 0);
    }, 0);
};

export default totalSummary;
