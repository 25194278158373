import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import CatatanTransaksiHarianApi from "./__CatatanTransaksiHarianApi__";

import {
  Alert,
  DataStatus,
  TBody,
  THead,
  Table,
  Td,
  Th,
  ThFixed,
  Tr,
} from "components";
import { InfoItemHorizontal, ButtonBack } from "components2";
import { Card, Dropdown, Spinner } from "react-bootstrap";
import { DateConvert, DecimalConvert } from "utilities";
import FileSaver from "file-saver";
export const CatatanTransaksiHarianDetail = ({ setNavbarTitle }) => {
  const { id } = useParams();
  const history = useHistory();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: "",
    variant: "primary",
  });
  const [loadingExport, setLoadingExport] = useState(false);

  const {
    data: detailCatatanTransaksiHarian,
    isFetching: loadingDetailCatatanTransaksiHarian,
  } = useQuery(
    ["catatanTransaksiHarian", "single", id],
    () =>
      CatatanTransaksiHarianApi.getSingle({
        id_item_buaso: id,
      }),
    { enabled: !!id }
  );

  const { data: realisasiProduksi, isFetching: loadingRealisasiProduksi } =
    useQuery(
      ["catatanTransaksiHarian", "realisasi", id],
      () =>
        CatatanTransaksiHarianApi.getRealisasi({
          id_item_buaso: id,
          tanggal_awal: history?.location?.state?.tanggal_awal,
          tanggal_akhir: history?.location?.state?.tanggal_akhir,
        }),
      { enabled: !!id }
    );

  const totalQtyRealisasiItem = realisasiProduksi?.data?.reduce(
    (acc, curr) => acc + parseFloat(curr?.qty_realisasi_bahan ?? 0),
    0
  );

  console.log(totalQtyRealisasiItem);

  const exportData = (tipe) => {
    setLoadingExport(true);

    CatatanTransaksiHarianApi.exportSingle({
      tipe,
      id_item_buaso: id,
      tanggal_awal: history?.location?.state?.tanggal_awal,
      tanggal_akhir: history?.location?.state?.tanggal_akhir,
    })
      .then((res) => {
        const data = res.data.data;

        const urlFile = data;
        FileSaver.saveAs(urlFile, data);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Export Data gagal",
        });
      })
      .finally(() => setLoadingExport(false));
  };

  useEffect(() => {
    setNavbarTitle("Catatan Transaksi Harian Produksi");

    return () => {};
  }, []);

  return (
    <>
      <Alert
        show={alertConfig?.show}
        text={alertConfig?.text}
        variant={alertConfig?.variant}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
        showCloseButton
      />
      <div className="d-flex justify-content-between mb-2">
        <small className="font-weight-bold">Detail Item Bahan Produksi</small>
        <div className="d-flex">
          {/* Export Button */}
          <Dropdown>
            <Dropdown.Toggle
              disabled={loadingExport}
              className="ml-2 btn btn-warning text-white"
            >
              {loadingExport ? (
                <Spinner animation="border" variant="white" size="sm" />
              ) : (
                "Export Data"
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                // disabled={!dataFilter?.filter.active}
                onClick={() =>
                  // dataFilter?.filter?.active &&
                  exportData("excel")
                }
              >
                Excel (.xlsx)
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <ButtonBack
            size="sm"
            className="ml-2"
            onClick={() => history.push("/laporan/catatan-transaksi-harian")}
          />
        </div>
      </div>
      <Card>
        <Card.Body>
          {loadingDetailCatatanTransaksiHarian ? (
            <DataStatus loading text="Memuat Data..." />
          ) : (
            <>
              <InfoItemHorizontal
                label="Kode Item Bahan"
                text={detailCatatanTransaksiHarian?.data?.[0]?.kode_item ?? "-"}
              />
              <InfoItemHorizontal
                label="Nama Item Bahan"
                text={detailCatatanTransaksiHarian?.data?.[0]?.nama_item ?? "-"}
              />
              <InfoItemHorizontal
                label="Jenis"
                text={
                  detailCatatanTransaksiHarian?.data?.[0]?.nama_jenis ?? "-"
                }
              />
              <InfoItemHorizontal
                label="Kelompok"
                text={
                  detailCatatanTransaksiHarian?.data?.[0]?.nama_kelompok ?? "-"
                }
              />
              <InfoItemHorizontal
                label="Satuan Pakai"
                text={
                  detailCatatanTransaksiHarian?.data?.[0]?.satuan_pakai ?? "-"
                }
              />
              <InfoItemHorizontal
                label="Periode"
                text={`
                  ${
                    DateConvert(
                      new Date(history?.location?.state?.tanggal_awal)
                    ).custom
                  } - ${
                  DateConvert(new Date(history?.location?.state?.tanggal_akhir))
                    .custom
                }`}
              />
            </>
          )}
        </Card.Body>
      </Card>

      <div className="d-flex justify-content-between mb-1 mt-2">
        <small className="font-weight-bold">Informasi Realisasi Produksi</small>
      </div>

      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Informasi Produksi</ThFixed>
            <ThFixed>Informasi Sales Order</ThFixed>
            <Th>Unit Produksi</Th>
            <Th>Item Produksi</Th>
            <ThFixed>Informasi Realisasi Produksi</ThFixed>
            <Th>Qty. Realisasi Item Bahan Produksi</Th>
          </Tr>
        </THead>
        <TBody>
          {loadingRealisasiProduksi ? (
            <Tr>
              <Td colSpan="6">
                <DataStatus loading text="Memuat Data..." />
              </Td>
            </Tr>
          ) : realisasiProduksi?.data?.length > 0 ? (
            <>
              {realisasiProduksi?.data?.map((val, ind) => (
                <Tr>
                  <Td>{ind + 1}</Td>
                  <Td>
                    <div>
                      {val?.tgl_jobmix
                        ? DateConvert(new Date(val?.tgl_jobmix)).custom
                        : "-"}
                    </div>
                    <div>{val?.no_jobmix ?? "-"}</div>
                  </Td>
                  <Td>
                    <div>
                      {val?.tgl_sales_order
                        ? DateConvert(new Date(val?.tgl_sales_order)).custom
                        : "-"}
                    </div>
                    <div>{val?.no_sales_order ?? "-"}</div>
                  </Td>
                  <Td>{val?.nama_unit_produksi ?? "-"}</Td>
                  <Td>{val?.nama_item ?? "-"}</Td>
                  <Td>
                    <div>
                      {val?.tgl_realisasi_produksi
                        ? DateConvert(new Date(val?.tgl_realisasi_produksi))
                            .custom
                        : "-"}
                    </div>
                    <div>{val?.no_realisasi_produksi ?? "-"}</div>
                  </Td>
                  <Td className="text-right">
                    {
                      DecimalConvert(parseFloat(val?.qty_realisasi_bahan ?? 0))
                        .getWithComa
                    }
                  </Td>
                </Tr>
              ))}
              <Tr>
                <Td colSpan="6" className="text-right font-weight-bold">
                  Total
                </Td>
                <Td className="text-right font-weight-bold">
                  {
                    DecimalConvert(parseFloat(totalQtyRealisasiItem ?? 0))
                      .getWithComa
                  }
                </Td>
              </Tr>
            </>
          ) : (
            <Tr>
              <Td colSpan="7">
                <DataStatus text="Data Tidak Ada" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </>
  );
};
