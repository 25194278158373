import DashboardApi from 'api/Dashboard';
import { Button, ChartDoughnut, DataStatus, Select } from 'components2';
import { Formik } from 'formik';
import { range } from 'lodash';
import getStatusProsesProduksi from 'pages/Master/JobMixDesign/functions/getStatusProsesProduksi';
import { memo, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useQuery } from 'react-query';
import { dateConvert } from 'utilities2';
import {
  ApprovalStatusButton,
  DatePicker,
  Select as SelectDefault,
  Table,
} from '../../components';
import { DecimalConvert, TableNumber } from '../../utilities';
import { InfoBarangTotalProduksi } from './Component/TotalProduksiPerbulan';
import { calculatePercentage } from './helpers';

const date = dateConvert();
const yearNow = date.getYear(new Date());
const monthNow = date.getMonth(new Date());
const defaultEndDate = new Date(
  new Date().getFullYear(),
  new Date().getMonth() + 1,
  new Date().getDate(),
);

const monthOptions = [
  { label: 'Januari', value: '01' },
  { label: 'Februari', value: '02' },
  { label: 'Maret', value: '03' },
  { label: 'April', value: '04' },
  { label: 'Mei', value: '05' },
  { label: 'Juni', value: '06' },
  { label: 'Juli', value: '07' },
  { label: 'Agustus', value: '08' },
  { label: 'September', value: '09' },
  { label: 'Oktober', value: '10' },
  { label: 'November', value: '11' },
  { label: 'Desember', value: '12' },
];

const yearOptions = range(2000, parseInt(yearNow + 1))
  .map((val) => ({
    label: val,
    value: val,
  }))
  .reverse();

const InfoItem = memo(
  ({
    title,
    percent,
    value = 100,
    loading,
    color,
    data,
    chartBgColor: backgroundColor,
  }) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center bg-white rounded border shadow-sm px-4 pt-2 pb-3"
        style={{ height: '100%' }}
      >
        <div className="position-relative">
          <div
            style={{
              position: 'absolute',
              top: 38,
              left:
                percent?.toString()?.length > 2
                  ? 28
                  : value?.toString()?.length > 2
                  ? 28
                  : 32,
              color: color,
            }}
          >
            <b style={{ fontSize: 14 }}>
              {loading ? '-' : percent ? percent : value}%
            </b>
          </div>
          <ChartDoughnut
            height={90}
            width={90}
            data={{
              datasets: [
                {
                  data: data,
                  backgroundColor,
                },
              ],
            }}
          />
        </div>
        <div className="text-right px-3 py-2">
          <div>{title}</div>
          {loading ? (
            <small>Memuat data . . .</small>
          ) : (
            <b style={{ fontSize: 40, fontWeight: 1200, lineHeight: 1 }}>
              {value}
            </b>
          )}
        </div>
      </div>
    );
  },
);

const Pagination = ({
  dataLength,
  onDataLengthChange,
  currentPage,
  totalPage,
  onPaginationChange,
  dataPage,
  dataCount,
  dataNumber,
}) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <small className="mb-2">
        Menampilkan {dataNumber} - {dataCount < dataPage ? dataCount : dataPage}{' '}
        dari {dataCount} data
      </small>
      <ReactPaginate
        pageCount={totalPage}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        containerClassName="pagination pagination-sm justify-content-center"
        pageLinkClassName="page-link"
        breakClassName="page-link"
        previousClassName="page-link"
        nextClassName="page-link"
        activeClassName="page-item active"
        disabledClassName="page-item disabled"
        previousLabel="&laquo;"
        nextLabel="&raquo;"
        onPageChange={onPaginationChange}
        initialPage={currentPage - 1}
        disableInitialCallback={true}
      />
      <small className="mt-1">Tampilkan :</small>
      <SelectDefault
        defaultValue={dataLength}
        style={{ width: '150px' }}
        onChange={onDataLengthChange}
      >
        <option value="10">10 Data</option>
        <option value="20">20 Data</option>
        <option value="50">50 Data</option>
        <option value="100">100 Data</option>
      </SelectDefault>
    </div>
  );
};

const TablePro = ({
  getProsesProduksiPerbulan,
  year,
  convertMonth,
  setPaginationConfig,
  paginationConfig,
}) => {
  return (
    <div className="p-3 bg-white border rounded shadow-sm mb-4 mt-2">
      <div className="m-2">
        <b>
          List Data Proses Produksi Bulan{' '}
          {`${convertMonth.startDate} - ${convertMonth.endDate}`}
          {` ${year}`}
        </b>
      </div>
      <Table>
        <thead>
          <tr>
            <th
              style={{ fontSize: 12 }}
              className="p-1 text-center align-middle"
            >
              No.
            </th>
            <th
              style={{ fontSize: 12 }}
              className="p-1 text-center align-middle"
            >
              Informasi Produksi
            </th>
            <th
              style={{ fontSize: 12 }}
              className="p-1 text-center align-middle"
            >
              Item Produksi
            </th>
            <th
              width={150}
              style={{ fontSize: 12 }}
              className="text-center align-middle"
            >
              Qty. Produksi
            </th>
            <th
              style={{ fontSize: 12 }}
              className="p-1 text-center align-middle"
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {getProsesProduksiPerbulan.isFetching ||
          !getProsesProduksiPerbulan?.data?.data?.data?.length > 0 ? (
            <tr>
              <td colSpan={5}>
                <DataStatus
                  loading={getProsesProduksiPerbulan.isFetching}
                  text={
                    getProsesProduksiPerbulan.isFetching
                      ? 'Memuat data . . .'
                      : getProsesProduksiPerbulan.isError
                      ? 'Data gagal dimuat'
                      : 'Tidak ada data'
                  }
                />
              </td>
            </tr>
          ) : (
            getProsesProduksiPerbulan.data?.data?.data?.map((val, index) => (
              <tr>
                <td
                  style={{ fontSize: 12 }}
                  className="p-1 align-middle text-center"
                >
                  {TableNumber(
                    paginationConfig?.page,
                    paginationConfig.dataLength,
                    index,
                  )}
                </td>
                <td style={{ fontSize: 12 }} className="p-1 align-middle">
                  {val?.no_jobmix}
                </td>
                <td style={{ fontSize: 12 }} className="p-1 align-middle">
                  {val?.nama_item}
                </td>
                <td style={{ fontSize: 12 }} className="p-1 align-middle">
                  {`${DecimalConvert(val?.qty_jobmix).getWithComa} pcs`}
                </td>
                <td style={{ fontSize: 12 }} className="p-1 align-middle">
                  <ApprovalStatusButton
                    variant={getStatusProsesProduksi(val.status_jobmix).variant}
                  >
                    {getStatusProsesProduksi(val.status_jobmix).label}
                  </ApprovalStatusButton>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {getProsesProduksiPerbulan.data?.data?.data_count === 0 ? (
        ''
      ) : (
        <Pagination
          size="sm"
          dataLength={paginationConfig?.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            getProsesProduksiPerbulan.data?.data?.data_count <
            paginationConfig.dataLength
              ? getProsesProduksiPerbulan.data?.data?.data_count
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={getProsesProduksiPerbulan.data?.data?.data_count}
          currentPage={paginationConfig.page}
          totalPage={getProsesProduksiPerbulan.data?.data?.total_page}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      )}
    </div>
  );
};

const TabelTerPro = ({ startMonth, endMonth, convertMonth, year }) => {
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    per_page: 10,
    dataLength: '10',
  });

  const getItemTerproduksi = useQuery(
    [
      'dashboard',
      'item_terproduksi',
      year,
      startMonth,
      endMonth,
      paginationConfig.page,
      paginationConfig.per_page,
    ],
    () =>
      DashboardApi.getItemTerproduksi({
        tahun: year,
        bulan_awal: startMonth,
        bulan_akhir: endMonth,
        page: paginationConfig.page,
        per_page: paginationConfig.per_page,
      }),
  );

  return (
    <div className="p-3 bg-white border rounded shadow-sm mb-4 mt-2">
      <div className="m-2">
        <b>
          List Data Item Yang Ter-Produksi Bulan{' '}
          {`${convertMonth.startDate} - ${convertMonth.endDate}`}
          {` ${year}`}
        </b>
      </div>
      <Table>
        <thead>
          <tr>
            <th
              style={{ fontSize: 12 }}
              className="p-1 text-center align-middle"
            >
              No.
            </th>
            <th
              style={{ fontSize: 12 }}
              className="p-1 text-center align-middle"
            >
              Item Produksi
            </th>
            <th
              style={{ fontSize: 12 }}
              className="p-1 text-center align-middle"
            >
              Jenis Item Produksi
            </th>
            <th
              width={150}
              style={{ fontSize: 12 }}
              className="text-center align-middle"
            >
              Qty. Produksi
            </th>
          </tr>
        </thead>
        <tbody>
          {getItemTerproduksi.isFetching ||
          !getItemTerproduksi?.data?.data?.data?.length > 0 ? (
            <tr>
              <td colSpan={5}>
                <DataStatus
                  loading={getItemTerproduksi.isFetching}
                  text={
                    getItemTerproduksi.isFetching
                      ? 'Memuat data . . .'
                      : getItemTerproduksi.isError
                      ? 'Data gagal dimuat'
                      : 'Tidak ada data'
                  }
                />
              </td>
            </tr>
          ) : (
            getItemTerproduksi.data?.data?.data?.map((val, index) => (
              <tr>
                <td
                  style={{ fontSize: 12 }}
                  className="p-1 align-middle text-center"
                >
                  {TableNumber(
                    paginationConfig?.page,
                    paginationConfig.dataLength,
                    index,
                  )}
                </td>
                <td style={{ fontSize: 12 }} className="p-1 align-middle">
                  {val?.nama_item}
                </td>
                <td style={{ fontSize: 12 }} className="p-1 align-middle">
                  {val?.nama_jenis}
                </td>
                <td style={{ fontSize: 12 }} className="p-1 align-middle">
                  {`${DecimalConvert(val?.qty_produksi).getWithComa} pcs`}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {getItemTerproduksi.data?.data?.data_count === 0 ? (
        ''
      ) : (
        <Pagination
          size="sm"
          dataLength={paginationConfig?.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            getItemTerproduksi.data?.data?.data_count <
            paginationConfig.dataLength
              ? getItemTerproduksi.data?.data?.data_count
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={getItemTerproduksi.data?.data?.data_count}
          currentPage={
            getItemTerproduksi.data?.data?.data_count <=
            paginationConfig.dataLength
              ? 1
              : paginationConfig.page
          }
          totalPage={getItemTerproduksi.data?.data?.total_page}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      )}
    </div>
  );
};

const Dashboard = ({ setNavbarTitle }) => {
  const [filter, setFilter] = useState({
    startMonth: monthNow,
    endMonth: date.getMonth(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        new Date().getDate(),
      ),
    ),
    year: date.getYear(new Date()),
    convertMonth: {
      startDate: '',
      endDate: '',
    },
  });

  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    per_page: 10,
    dataLength: '10',
  });

  const generateMaxDate = (values) => {
    const date = new Date(values.startMonth);
    date.setMonth(date.getMonth() + 12);
    return date;
  };

  const convertDate = (startDate, endDate) => {
    const dateFilter = [startDate, endDate];

    const monthName = dateFilter.map((val) => {
      const monthValue = val; // Angka bulan (contoh: 10 untuk Oktober)
      const currentYear = new Date().getFullYear(); // Mendapatkan tahun saat ini
      const dayOfMonth = date.getDay(new Date()); // Contoh hari 1, Anda bisa menggantinya sesuai kebutuhan

      const todayDate = date.getDetailMonth(
        new Date(currentYear, monthValue - 1, dayOfMonth),
      );

      return todayDate;
    });

    return monthName;
  };

  const getTotalProduksi = useQuery(
    ['dashboard', 'total', filter.year, filter.startMonth, filter.endMonth],
    () =>
      DashboardApi.getTotalProduksi({
        bulan_awal: filter?.startMonth,
        bulan_akhir: filter?.endMonth,
        tahun: filter?.year,
      }),
  );

  const getProsesProduksiPerbulan = useQuery(
    [
      'dashboard',
      'proses_produksi',
      filter.startMonth,
      filter.endMonth,
      filter.year,
      paginationConfig.page,
      paginationConfig.per_page,
    ],
    () =>
      DashboardApi.getProsesProduksiPerbulan({
        bulan_awal: filter.startMonth,
        bulan_akhir: filter.endMonth,
        tahun: filter.year,
        page: paginationConfig.page,
        per_page: paginationConfig.per_page,
      }),
  );

  useEffect(() => {
    setNavbarTitle('Dashboard');

    if (filter.startMonth && filter.endMonth) {
      const monthName = convertDate(filter.startMonth, filter.endMonth);

      setFilter((prev) => ({
        ...prev,
        convertMonth: {
          startDate: monthName[0],
          endDate: monthName[1],
        },
      }));
    }
  }, [setNavbarTitle, filter.startMonth, filter.endMonth]);

  return (
    <>
      {/* Filter Section */}
      <Formik
        initialValues={{
          startMonth: new Date(),
          endMonth: defaultEndDate,
          year: filter.year,
        }}
        onSubmit={(val) => {
          const startMonth = date.getMonth(new Date(val.startMonth));
          const endMonth = date.getMonth(new Date(val.endMonth));
          const monthName = convertDate(startMonth, endMonth);

          setFilter({
            startMonth,
            endMonth,
            year: val.year,
            convertMonth: {
              startDate: monthName[0],
              endDate: monthName[1],
            },
          });
        }}
      >
        {({ values, setValues, handleSubmit }) => (
          <div className="d-flex justify-content-center justify-content-lg-end">
            <div className="d-flex flex-column flex-md-row">
              {/* Bulan */}
              <div className="d-flex align-items-center mr-2">
                <div style={{ width: 150 }}>
                  <DatePicker
                    selectsRange
                    dateFormat="MMMM"
                    showMonthYearPicker
                    selected={values.startMonth}
                    startDate={values.startMonth}
                    maxDate={values.startMonth ? generateMaxDate(values) : null}
                    endDate={values.endMonth}
                    onChange={([startMonth, endMonth]) =>
                      setValues({
                        ...values,
                        startMonth: startMonth,
                        endMonth: endMonth,
                      })
                    }
                  />
                </div>
              </div>

              {/* Tahun */}
              <div className="d-flex align-items-center mr-2 mb-2">
                <span className="mr-2">Tahun</span>
                <div style={{ width: 150 }}>
                  <Select
                    noMargin
                    options={yearOptions}
                    defaultValue={yearOptions.find(
                      (val) => val.value === values.year,
                    )}
                    onChange={(val) =>
                      setValues((prev) => ({ ...prev, year: val.value }))
                    }
                  />
                </div>
              </div>

              {/* Button */}
              <div className="d-flex align-items-center mr-2 mb-2">
                <Button
                  disabled={!values.startMonth || !values.endMonth}
                  text="Filter"
                  className="px-4"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>

      {/* Info Section */}
      <Row className="mt-3">
        {/* Info Total Produksi */}
        <Col className="mb-3">
          <InfoItem
            type="default"
            loading={getTotalProduksi.isFetching}
            title="Total Produksi"
            percent={calculatePercentage(
              getTotalProduksi.data?.data?.total_produksi,
              getTotalProduksi.data?.data?.total_produksi,
            )}
            value={
              getTotalProduksi.data?.data?.total_produksi
                ? parseInt(getTotalProduksi.data?.data?.total_produksi)
                : '0'
            }
            data={[parseInt(getTotalProduksi.data?.data?.total_produksi) ?? 0]}
            chartBgColor={['#0073b9', '#00abda']}
          />
        </Col>

        {/* Info Produksi Open */}
        <Col className="mb-3">
          <InfoItem
            loading={getTotalProduksi.isFetching}
            title="Produksi Open"
            percent={calculatePercentage(
              getTotalProduksi.data?.data?.total_produksi,
              getTotalProduksi.data?.data?.total_produksi_open,
            )}
            value={
              getTotalProduksi.data?.data?.total_produksi_open
                ? parseInt(getTotalProduksi.data?.data?.total_produksi_open)
                : '0'
            }
            data={[
              parseInt(getTotalProduksi.data?.data?.total_produksi) -
                parseInt(getTotalProduksi.data?.data?.total_produksi_open),
              parseInt(getTotalProduksi.data?.data?.total_produksi_open) ?? 0,
            ]}
            chartBgColor={['#00abda', '#0073b9']}
          />
        </Col>

        {/* Info Produksi Close */}
        <Col className="mb-3">
          <InfoItem
            loading={getTotalProduksi.isFetching}
            title="Produksi Closed"
            percent={calculatePercentage(
              getTotalProduksi.data?.data?.total_produksi,
              getTotalProduksi.data?.data?.total_produksi_close,
            )}
            value={
              getTotalProduksi.data?.data?.total_produksi_close
                ? parseInt(getTotalProduksi.data?.data?.total_produksi_close)
                : '0'
            }
            data={[
              parseInt(getTotalProduksi.data?.data?.total_produksi) -
                parseInt(getTotalProduksi.data?.data?.total_produksi_open),
              parseInt(getTotalProduksi.data?.data?.total_produksi_open) ?? 0,
            ]}
            chartBgColor={['#0073b9', '#00abda']}
          />
        </Col>
      </Row>

      {/* Chart Section */}
      <Row>
        <Col md={6}>
          {/* Table Section */}
          <TablePro
            getProsesProduksiPerbulan={getProsesProduksiPerbulan}
            startMonth={filter.startMonth}
            endMonth={filter.endMonth}
            year={filter.year}
            convertMonth={filter.convertMonth}
            setPaginationConfig={setPaginationConfig}
            paginationConfig={paginationConfig}
          />
        </Col>
        <Col md={6}>
          <Col className="my-2">
            <InfoBarangTotalProduksi
              getProsesProduksiPerbulan={getProsesProduksiPerbulan}
              monthOptions={monthOptions}
              startMonth={filter.startMonth}
              endMonth={filter.endMonth}
              year={filter.year}
            />
          </Col>
          <Col className="my-2">
            <TabelTerPro
              startMonth={filter.startMonth}
              endMonth={filter.endMonth}
              convertMonth={filter.convertMonth}
              year={filter.year}
            />
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
