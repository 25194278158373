import { useState } from 'react';
import {
  IoAddOutline,
  IoTrashBinOutline,
  IoCloseOutline,
  IoCreateOutline,
} from 'react-icons/io5';
import {
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  DataStatus,
  ActionButton,
  SelectSearch,
  NumberFormat,
} from 'components';
import { DecimalConvert, RupiahConvert } from 'utilities';

import { ButtonGroup } from 'react-bootstrap';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

export const TabItem = ({ type, dropdownBuaso, dropdownGudang }) => {
  const { values, setValues } = useFormikContext();
  const generateName =
    type === 'bahan'
      ? 'Bahan'
      : type === 'upah'
      ? 'Upah'
      : type === 'alat_mesin'
      ? 'Alat Mesin'
      : type === 'subkon'
      ? 'Subkon'
      : type === 'overhead'
      ? 'Overhead'
      : '';

  const [updateIndex, setUpdateIndex] = useState(null);

  const deleteHandler = (i) => {
    const filterData = values?.[type]?.filter((_, ind) => ind !== i);
    setValues({
      ...values,
      [type]: filterData,
    });
  };

  const FormInputTable = ({ data, tableAction }) => {
    const tableInitialValues = {
      id_item_buaso: data?.id_item_buaso ?? undefined,
      nama_item: data?.nama_item ?? '',
      nama_gudang: data?.nama_gudang ?? '',
      id_gudang: data?.id_gudang ?? undefined,
      qty_produksi: parseFloat(data?.qty_produksi ?? '0').toString(),
      harga: data?.harga ?? '0',
      kode_satuan: data?.kode_satuan ?? '',
    };

    const tableSubmitHandler = (value) => {
      if (tableAction === 'update') {
        setValues({
          ...values,
          [type]: values?.[type].map((val, ind) =>
            updateIndex === ind ? value : val,
          ),
        });
        setUpdateIndex(null);
      }

      if (tableAction === 'create') {
        setValues({ ...values, [type]: [...values?.[type], value] });
      }
    };

    const tableValidationSchema = Yup.object().shape({
      id_item_buaso: Yup.string().required('Item wajib diisi'),
      id_gudang:
        type === 'bahan' && Yup.string().required('Gudang wajib diisi'),
      qty_produksi: Yup.string().required('Qty wajib diisi'),
    });

    return (
      <Formik
        enableReinitialize
        initialValues={tableInitialValues}
        onSubmit={tableSubmitHandler}
        validationSchema={tableValidationSchema}
      >
        {({
          setValues: tableSetValues,
          values: tableValues,
          setFieldValue,
          handleSubmit,
          errors,
          touched,
        }) => (
          <Tr>
            <Td colSpan="2">
              <SelectSearch
                name="id_item_buaso"
                placeholder="Pilih item"
                option={dropdownBuaso}
                defaultValue={{
                  label: tableValues?.id_item_buaso
                    ? tableValues?.nama_item
                    : 'Pilih item',
                  value: tableValues?.id_item_buaso,
                }}
                onChange={(val) => {
                  tableSetValues((prev) => ({
                    ...prev,
                    id_item_buaso: val.value,
                    nama_item: val.label,
                    harga: val.harga,
                    id_satuan: val?.id_satuan,
                    kode_satuan: val?.kode_satuan,
                  }));
                }}
                error={errors?.id_item_buaso && touched?.id_item_buaso}
                errorText={touched?.id_item_buaso && errors?.id_item_buaso}
              />
            </Td>
            {type === 'bahan' && (
              <Td>
                <SelectSearch
                  name="id_gudang"
                  placeholder="Pilih gudang"
                  option={dropdownGudang}
                  defaultValue={{
                    label: tableValues?.id_gudang
                      ? tableValues?.nama_gudang
                      : 'Pilih gudang',
                    value: tableValues?.id_gudang,
                  }}
                  error={errors?.id_gudang && touched?.id_gudang}
                  errorText={touched?.id_gudang && errors?.id_gudang}
                  onChange={(val) =>
                    tableSetValues({
                      ...tableValues,
                      id_gudang: val.value,
                      nama_gudang: val.label,
                    })
                  }
                />
              </Td>
            )}
            <Td>
              <NumberFormat
                className="text-right"
                name="qty_produksi"
                onChange={(val) => setFieldValue('qty_produksi', val)}
                value={tableValues?.qty_produksi}
                placeholder="Masukan qty"
                error={errors?.qty_produksi && touched?.qty_produksi}
                errorText={touched?.qty_produksi && errors?.qty_produksi}
              />
            </Td>
            <Td className="text-right">
              {
                RupiahConvert(parseFloat(tableValues?.harga ?? 0).toString())
                  .getWithComa
              }
            </Td>
            <Td className="text-right">
              {
                RupiahConvert(
                  String(
                    parseFloat(tableValues.harga ?? 0) *
                      parseFloat(tableValues.qty_produksi ?? 0),
                  ),
                ).getWithComa
              }
            </Td>
            <Td>
              {tableAction === 'update' ? (
                <ButtonGroup>
                  <ActionButton
                    size="sm"
                    text={<IoAddOutline />}
                    onClick={handleSubmit}
                    variant="success"
                  />
                  <ActionButton
                    size="sm"
                    text={<IoCloseOutline />}
                    onClick={() => setUpdateIndex(null)}
                    variant="danger"
                  />
                </ButtonGroup>
              ) : (
                <ActionButton
                  size="sm"
                  text={<IoAddOutline />}
                  onClick={handleSubmit}
                  className="px-4"
                />
              )}
            </Td>
          </Tr>
        )}
      </Formik>
    );
  };

  return (
    <div className="p-3 bg-white rounded border border-top-0">
      {/* Table Item */}
      <div className="d-flex justify-content-between mb-3">
        <b>List Data Komponen Produksi</b>
      </div>
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th style={{ minWidth: 300 }} className="p-1">
              Item {generateName}
            </Th>
            {type === 'bahan' && (
              <Th style={{ minWidth: 300 }} className="p-1">
                Gudang Asal
              </Th>
            )}
            <Th style={{ width: 150 }} className="p-1">
              Qty. Produksi
            </Th>
            <Th style={{ width: 150 }} className="p-1">
              Harga Satuan
            </Th>
            <Th style={{ width: 150 }} className="p-1">
              Sub Total
            </Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          <FormInputTable tableAction="create" />
          {values?.[type] && values?.[type]?.length > 0 ? (
            values?.[type]?.map((e, i) => {
              return updateIndex === i ? (
                <FormInputTable data={e} tableAction="update" />
              ) : (
                <>
                  <Tr key={i}>
                    <TdFixed>{i + 1}</TdFixed>

                    <Td>{e?.nama_item ?? '-'}</Td>

                    {type === 'bahan' && <Td>{e.nama_gudang ?? '-'}</Td>}

                    <Td className="text-right">
                      {DecimalConvert(e?.qty_produksi).getWithComa}{' '}
                      {e?.kode_satuan ?? ''}
                    </Td>

                    <Td className="text-right">
                      {
                        RupiahConvert(parseFloat(e?.harga ?? 0).toString())
                          .getWithComa
                      }
                    </Td>

                    <Td className="text-right">
                      {
                        RupiahConvert(
                          String(
                            parseFloat(e?.harga ?? 0) *
                              parseFloat(e.qty_produksi ?? 0),
                          ),
                        ).getWithComa
                      }
                    </Td>

                    <TdFixed className="text-center">
                      <ButtonGroup>
                        <ActionButton
                          size="sm"
                          text={<IoCreateOutline />}
                          onClick={() => setUpdateIndex(i)}
                          variant="success"
                        />
                        <ActionButton
                          size="sm"
                          text={<IoTrashBinOutline />}
                          onClick={() => deleteHandler(i)}
                          variant="danger"
                        />
                      </ButtonGroup>
                    </TdFixed>
                  </Tr>
                </>
              );
            })
          ) : (
            <Tr>
              <Td colSpan={11}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </div>
  );
};
