import Services from "services";

class CloseJobMixApi {
  get(params) {
    return Services.get("/jobmix_close/page", { params });
  }

  getProduksi(params) {
    return Services.get("/jobmix_close/list_produksi", { params });
  }

  getSingle(params) {
    return Services.get("/jobmix_close/detail_produksi", { params });
  }

  getSingleProduksi(params) {
    return Services.get("/jobmix_close/list_bdp", { params });
  }

  getListBDP(params) {
    return Services.get("jobmix_close/list_bdp", { params });
  }

  getNo(params) {
    return Services.get("/jobmix_close/no_baru", { params });
  }

  save(data) {
    return Services.put("/jobmix_close", data);
  }

  export(params) {
    return Services.get("/jobmix_close_report", { params });
  }
}

export default new CloseJobMixApi();
