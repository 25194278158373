import Services from "services";

class CatatanTransaksiHarianApi {
  async getList(params) {
    const fetch = await Services.get("/catatan_transaksi_harian/page", {
      params,
    });
    return fetch.data;
  }

  async getSingle(params) {
    const fetch = await Services.get("/catatan_transaksi_harian/single", {
      params,
    });
    return fetch.data;
  }

  async getRealisasi(params) {
    const fetch = await Services.get(
      "/catatan_transaksi_harian/list_realisasi",
      {
        params,
      }
    );
    return fetch.data;
  }

  async dropdownJenis(params) {
    const fetch = await Services.get("/dropdown/jenis", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_jenis,
      value: val.id_jenis,
    }));
  }

  async dropdownKelompok(params) {
    const fetch = await Services.get("/dropdown/kelompok", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_kelompok,
      value: val.id_kelompok,
    }));
  }

  async dropdownItem(params) {
    const fetch = await Services.get("/dropdown/item_buaso", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_item,
      value: val.id_item_buaso,
    }));
  }

  export(params) {
    return Services.get("/catatan_transaksi_harian/export_page", { params });
  }

  exportSingle(params) {
    return Services.get("/catatan_transaksi_harian/export_single", { params });
  }
}

export default new CatatanTransaksiHarianApi();
