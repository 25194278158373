import {
  ButtonCancel,
  ButtonCreate,
  ButtonDelete,
  ButtonUpdate,
  ButtonVerify,
} from "@bhawanadevteam/react-core";
import {
  DataStatus,
  NumberFormat,
  SelectSearch,
  TBody,
  Table,
  Td,
  Th,
  ThFixed,
  Tr,
} from "components";
import Thead from "components/Table/THead";
import { Formik, useFormikContext } from "formik";
import React, { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useQuery } from "react-query";
import * as Yup from "yup";
import ProduksiAsetApi from "../__ProduksiAsetApi__";
import { DecimalConvert, RupiahConvert } from "utilities";

const FormInputTable = ({ tab, data, action, ind, setisUpdate }) => {
  const { values: formValues, setValues: setFormValues } = useFormikContext();

  const checkIdBuaso =
    tab === "bahan"
      ? "1"
      : tab === "upah"
      ? "2"
      : tab === "alatMesin"
      ? "3"
      : tab === "subkon"
      ? "4"
      : "5";

  const { data: dropdownItem, isFetching: loadingDropdownItem } = useQuery(
    ["dropdownItem", "dropdown", tab],
    () => ProduksiAsetApi.getDropdownItem({ id_buaso: checkIdBuaso })
  );

  const tableInitialValues = {
    id_item: data?.id_item ?? undefined,
    nama_item: data?.nama_item ?? "",
    qty: data?.qty ?? 0,
    harga_satuan: data?.harga_satuan ?? 0,
    nama_satuan: data?.nama_satuan ?? "",
  };

  const tableValidationSchema = Yup.object().shape({
    id_item: Yup.string().required("Pilih item"),
    qty: Yup.string().required("Masukan qty. komponen"),
  });

  const checkActionSubmit = (value) => {
    if (action === "update") {
      setFormValues({
        ...formValues,
        [tab]: formValues[tab]?.map((val, i) => (i === ind ? value : val)),
      });
      setisUpdate("");
    } else {
      setFormValues({ ...formValues, [tab]: [...formValues[tab], value] });
    }
  };

  const tableSubmitHandler = (value, { setSubmitting, resetForm }) => {
    checkActionSubmit(value);
    setSubmitting("");
    resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={tableInitialValues}
      validationSchema={tableValidationSchema}
      onSubmit={tableSubmitHandler}
    >
      {({
        values,
        setFieldValue,
        setValues,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
      }) => (
        <Tr>
          <Td colSpan="2" width="400">
            <SelectSearch
              option={dropdownItem}
              loading={loadingDropdownItem}
              placeholder="Pilih Item"
              defaultValue={
                values?.id_item
                  ? {
                      value: values?.id_item,
                      label: values?.nama_item,
                    }
                  : undefined
              }
              onChange={(val) =>
                setValues({
                  ...values,
                  id_item: val.value,
                  nama_item: val.label,
                  harga_satuan: val?.harga_satuan,
                  nama_satuan: val?.nama_satuan,
                })
              }
              error={errors.id_item && touched.id_item && true}
              errorText={errors.id_item && touched.id_item && errors.id_item}
            />
          </Td>
          <Td width="100">
            <NumberFormat
              placeholder="Masukan Qty. Komponen"
              value={values?.qty}
              onChange={(val) => setFieldValue("qty", val)}
              error={errors.idEntitasAset && touched.idEntitasAset && true}
              errorText={
                errors.idEntitasAset &&
                touched.idEntitasAset &&
                errors.idEntitasAset
              }
            />
          </Td>
          <Td>{values?.nama_satuan ?? "-"}</Td>
          <Td>
            {
              RupiahConvert(parseFloat(values?.harga_satuan ?? 0).toString())
                .getWithComa
            }
          </Td>
          <Td>
            {
              RupiahConvert(
                String(
                  parseFloat(values?.harga_satuan ?? 0) *
                    parseFloat(values?.qty ?? 0)
                )
              ).getWithComa
            }
          </Td>
          <Td>
            {action === "update" ? (
              <ButtonGroup>
                <ButtonVerify
                  icon
                  noText
                  variant="outline-success"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                  loadingText=""
                />
                <ButtonCancel
                  icon
                  noText
                  variant="outline-danger"
                  onClick={() => setisUpdate("")}
                  loadingText=""
                />
              </ButtonGroup>
            ) : (
              <ButtonCreate
                icon
                noText
                onClick={handleSubmit}
                loading={isSubmitting}
                loadingText=""
                style={{ width: 60 }}
              />
            )}
          </Td>
        </Tr>
      )}
    </Formik>
  );
};

export const TableKomponenProduksi = ({ action, tab }) => {
  const [isUpdate, setisUpdate] = useState("");
  const { values, setValues } = useFormikContext();
  const checkTab = tab === "alatMesin" ? "alat mesin" : tab;

  const onDeleteDataTable = (ind) => {
    const filterData = values[tab]?.filter((val, index) => index !== ind);
    setValues({ ...values, [tab]: filterData });
  };

  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th className="text-capitalize">Item {checkTab}</Th>
            <Th>Qty. Komponen</Th>
            <Th>Satuan</Th>
            <Th>Harga Satuan</Th>
            <Th>Sub Total</Th>
            {action !== "detail" && <ThFixed>Aksi</ThFixed>}
          </Tr>
        </Thead>
        <TBody>
          {action !== "detail" && <FormInputTable tab={tab} action="create" />}
          {values?.[tab]?.length > 0 ? (
            values?.[tab]?.map((val, ind) =>
              isUpdate === ind ? (
                <FormInputTable
                  tab={tab}
                  action="update"
                  data={val}
                  ind={ind}
                  setisUpdate={setisUpdate}
                />
              ) : (
                <Tr key={ind}>
                  <Td>{ind + 1}</Td>
                  <Td>{val.nama_item ?? "-"}</Td>
                  <Td className="text-right">
                    {DecimalConvert(parseFloat(val.qty ?? 0)).getWithComa}
                  </Td>
                  <Td>{val.nama_satuan ?? "-"}</Td>
                  <Td>
                    {
                      RupiahConvert(
                        parseFloat(val?.harga_satuan ?? 0).toString()
                      ).getWithComa
                    }
                  </Td>
                  <Td>
                    {
                      RupiahConvert(
                        String(
                          parseFloat(val?.harga_satuan ?? 0) *
                            parseFloat(val?.qty ?? 0)
                        )
                      ).getWithComa
                    }
                  </Td>
                  {action !== "detail" && (
                    <Td>
                      <ButtonGroup>
                        <>
                          <ButtonUpdate
                            noText
                            icon
                            variant="success"
                            size="sm"
                            onClick={() => setisUpdate(ind)}
                          />
                          <ButtonDelete
                            noText
                            icon
                            size="sm"
                            onClick={() => onDeleteDataTable(ind)}
                          />
                        </>
                      </ButtonGroup>
                    </Td>
                  )}
                </Tr>
              )
            )
          ) : (
            <Tr>
              <Td colSpan="8">
                <DataStatus text="Tidak Ada Data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </div>
  );
};
