/* eslint-disable react-hooks/exhaustive-deps */
import { Td, TdFixed, TextArea, TextEditor, Tr } from "components";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { DateConvert } from "utilities";

export const InfoPeluang = ({ data }) => {
  const [textEditorState, setTextEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML("<p></p>"))
    )
  );

  useEffect(
    () =>
      setTextEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(data?.informasi_lainnya ?? "<p></p>")
          )
        )
      ),
    [data?.informasi_lainnya]
  );

  // States
  return (
    <>
      <Row>
        <Col md>
          <List label="Tgl. Peluang">
            {data?.tgl_peluang
              ? DateConvert(new Date(data.tgl_peluang)).detail
              : "-"}
          </List>
          <List label="No. Peluang">{data?.no_peluang ?? "-"}</List>
          <List label="Peringkat Peluang">
            {data?.nama_peringkat_peluang ?? "-"}
          </List>
        </Col>

        <Col md>
          <List label="Customer">{data?.nama_customer ?? "-"}</List>
          <List label="ATT">{data?.att ?? "-"}</List>
          <List label="Proyek">{data?.nama_proyek ?? "-"}</List>
        </Col>
      </Row>

      <Row>
        <Col md>
          <TextArea
            label="Keterangan Pengiriman"
            disabled={true}
            value={data?.keterangan_pengiriman}
            rows="4"
          />

          <TextArea
            label="Lokasi Pengiriman"
            disabled={true}
            value={data?.alamat_pengiriman}
            rows="4"
          />
        </Col>

        <Col md>
          <TextEditor
            readOnly
            label="Informasi Lainnya"
            editorState={textEditorState}
            options={[]}
            editorStyle={{ height: 200 }}
          />
        </Col>
      </Row>
    </>
  );
};
const List = ({ label, children }) => (
  <Tr>
    <Td>
      <b>{label}</b>
    </Td>
    <TdFixed>:</TdFixed>
    <Td>{children}</Td>
  </Tr>
);
