import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import {
  ActionButton,
  Alert,
  BackButton,
  DataStatus,
  DatePicker,
  InfoItemHorizontal,
  Input,
} from "components";
import { PermintaanProduksiApi } from "api";
import { TabItem } from "./Comps";
import { DateConvert, DecimalConvert } from "utilities";
import { Formik, useFormikContext } from "formik";
import { formInitialValues } from "./Utils";
import { formSubmitMapper } from "./Utils/formSubmitMapper";
import * as Yup from "yup";
import { AlertAlreadyStockOpname } from "components/Alert";

const InfoSection = ({ data }) => {
  const { values, setFieldValue, errors, touched } = useFormikContext();

  return (
    <div className="p-3 bg-white border rounded">
      <Row>
        <Col md>
          <InfoItemHorizontal
            label="Tgl. Produksi"
            text={
              data?.tgl_jobmix
                ? DateConvert(new Date(data?.tgl_jobmix)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="No. Produksi"
            text={data?.no_jobmix ?? "-"}
          />
          <InfoItemHorizontal
            label="Customer"
            text={data?.nama_customer ?? "-"}
          />
          <InfoItemHorizontal label="Proyek" text={data?.nama_proyek ?? "-"} />
        </Col>
        <Col md>
          <InfoItemHorizontal
            label="Kode Barang"
            text={data?.kode_item ?? "-"}
          />
          <InfoItemHorizontal label="Item Produksi" text={data?.nama_item} />
          <InfoItemHorizontal
            label="Qty. Produksi"
            text={`${
              data?.qty_produksi
                ? DecimalConvert(data.qty_jobmix).getWithComa
                : "-"
            } ${data?.nama_satuan ?? ""}`}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md="6">
          <DatePicker
            label="Tgl. Permintaan Produksi"
            dateFormat="dd/MM/yyyy"
            selected={values.tgl_permintaan_produksi}
            onChange={(val) => setFieldValue("tgl_permintaan_produksi", val)}
            error={
              errors?.tgl_permintaan_produksi &&
              touched?.tgl_permintaan_produksi
            }
            errorText={
              touched?.tgl_permintaan_produksi &&
              errors?.tgl_permintaan_produksi
            }
          />
        </Col>
        <Col md="6">
          <Input
            label="Gudang Produksi"
            readOnly
            value={values?.nama_gudang_produksi}
          />
        </Col>
      </Row>
    </div>
  );
};

export const PermintaanProduksiCreate = ({ setNavbarTitle }) => {
  const { id } = useParams();
  const history = useHistory();
  const [tabs, setTabs] = useState("bahan");
  const [dataDetail, setDataDetail] = useState({});
  const [dataHistory, setDataHistory] = useState({});
  const [dropdownBuaso, setDropdownBuaso] = useState({
    bahan: [],
    upah: [],
    alat_mesin: [],
    subkon: [],
    overhead: [],
    gudang: [],
  });
  const [alert, setAlert] = useState({
    show: false,
    variant: "",
    text: "",
  });
  const [fetching, setFetching] = useState({
    loading: true,
    error: false,
  });

  const mappingItemBuaso = (data) =>
    data.map((val) => ({
      ...val,
      value: val?.id_item_buaso ?? val.id_item_aset ?? val?.id_gudang,
      label: val?.nama_item ?? val?.nama_gudang,
      harga: val?.harga,
      id_satuan: val?.id_satuan,
    }));

  const getInitialData = () => {
    setFetching({
      loading: true,
      error: false,
    });

    Axios.all([
      PermintaanProduksiApi.getSingleProduksi({ id_jobmix: id }),
      PermintaanProduksiApi.getHistory({ id_jobmix: id }),
      PermintaanProduksiApi.getDropdownBuaso({ tipe: "item_bahan" }),
      PermintaanProduksiApi.getDropdownBuaso({ tipe: "item_upah" }),
      PermintaanProduksiApi.getDropdownBuaso({ tipe: "item_aset" }),
      PermintaanProduksiApi.getDropdownBuaso({ tipe: "item_subkon" }),
      PermintaanProduksiApi.getDropdownBuaso({ tipe: "item_overhead" }),
      PermintaanProduksiApi.getDropdownBuaso({ tipe: "gudang" }),
    ])
      .then(
        Axios.spread(
          (
            single,
            history,
            bahan,
            upah,
            alat_mesin,
            subkon,
            overhead,
            gudang
          ) => {
            setDataDetail(single?.data?.data ?? {});
            setDataHistory(history?.data?.data ?? {});

            setDropdownBuaso({
              bahan: mappingItemBuaso(bahan?.data?.data ?? []),
              upah: mappingItemBuaso(upah?.data?.data ?? []),
              alat_mesin: mappingItemBuaso(alat_mesin?.data?.data ?? []),
              subkon: mappingItemBuaso(subkon?.data?.data ?? []),
              overhead: mappingItemBuaso(overhead?.data?.data ?? []),
              gudang: mappingItemBuaso(gudang?.data?.data ?? []),
            });
            setFetching({
              loading: false,
              error: false,
            });
          }
        )
      )
      .catch(() => {
        setFetching({
          loading: false,
          error: true,
        });
      });
  };

  const onCreateDataSuccess = () => {
    setAlert({
      show: true,
      text: "Tambah permintaan berhasil!",
      variant: "primary",
    });
    history.push("/transaksi/permintaan-produksi", {
      alert: {
        show: true,
        text: "Tambah Data Berhasil",
        variant: "primary",
      },
    });
    getInitialData();
  };

  const onCreateDataError = () => {
    setAlert({
      show: true,
      text: "Tambah permintaan gagal!",
      variant: "danger",
    });
    getInitialData();
  };

  const mapDataHistoryHandler = (data = [], type) =>
    data.map((val) => ({
      id_permintaan_produksi: val?.[`id_permintaan_produksi_${type}`],
      tgl_permintaan_produksi: val?.[`tgl_permintaan_produksi_${type}`],
      no_permintaan_produksi: val?.[`no_permintaan_produksi_${type}`],
      tgl_produksi: val?.tgl_jobmix,
      no_produksi: val?.no_jobmix,
      id_item: val?.id_item_buaso ?? val?.id_item_aset,
      kode_item: val?.kode_item,
      nama_item: val?.nama_item,
      satuan: val?.nama_satuan,
      qty_permintaan_produksi: val?.qty_permintaan_produksi
        ? parseFloat(val.qty_permintaan_produksi)
        : null,
      qty_transfer: val?.qty_transfer ? parseFloat(val.qty_transfer) : 0,
      status_permintaan: val?.status_permintaan_produksi,
    }));

  const formSubmitHandler = (value, { setSubmitting }) => {
    PermintaanProduksiApi.saveAll(formSubmitMapper(value))
      .then(() => onCreateDataSuccess())
      .catch(() => onCreateDataError())
      .finally(() => {
        setSubmitting(false);
      });
  };

  const formValidationSchema = Yup.object().shape({
    tgl_permintaan_produksi: Yup.string().required(
      "Tgl permintaan wajib diisi"
    ),
  });

  useEffect(() => {
    getInitialData();
    setNavbarTitle("Permintaan Produksi");
  }, []);

  return (
    <>
      {/* Title & Back Button */}
      <div className="d-flex justify-content-between align-items-end mb-2">
        <b>Tambah Data Permintaan Produksi</b>
        <div>
          <BackButton size="sm" onClick={history.goBack} />
        </div>
      </div>
      <Alert
        showCloseButton
        show={alert.show}
        text={alert.text}
        variant={alert.variant}
        onClose={() => setAlert({ show: false, text: "", variant: "" })}
      />
      {fetching.loading || fetching.error ? (
        <div>
          <DataStatus
            loading={fetching.loading}
            text={fetching.loading ? "Memuat data . . ." : "Data gagal dimuat!"}
          />
        </div>
      ) : (
        <Formik
          initialValues={formInitialValues(dataDetail)}
          onSubmit={formSubmitHandler}
          validationSchema={formValidationSchema}
        >
          {({ handleSubmit, isSubmitting, values }) => (
            <>
              {/* Info Section */}
              <InfoSection data={dataDetail} />

              {/* Tabs */}
              <div className="mt-3">
                <Tabs defaultActiveKey={tabs} onSelect={(e) => setTabs(e)}>
                  <Tab title="Bahan" eventKey="bahan">
                    <TabItem
                      dropdownBuaso={dropdownBuaso?.bahan}
                      dropdownGudang={dropdownBuaso?.gudang}
                      dataHistory={
                        mapDataHistoryHandler(dataHistory?.bahan, "bahan") ?? []
                      }
                      onCreateDataSuccess={onCreateDataSuccess}
                      onCreateDataError={onCreateDataError}
                      type="bahan"
                    />
                  </Tab>
                  <Tab title="Upah" eventKey="upah">
                    <TabItem
                      dropdownBuaso={dropdownBuaso?.upah}
                      dataHistory={
                        mapDataHistoryHandler(dataHistory?.upah, "upah") ?? []
                      }
                      onCreateDataSuccess={onCreateDataSuccess}
                      onCreateDataError={onCreateDataError}
                      type="upah"
                    />
                  </Tab>
                  <Tab title="Alat & Mesin" eventKey="alat_mesin">
                    <TabItem
                      dropdownBuaso={dropdownBuaso?.alat_mesin}
                      dataHistory={
                        mapDataHistoryHandler(
                          dataHistory?.alat_mesin,
                          "alat_mesin"
                        ) ?? []
                      }
                      onCreateDataSuccess={onCreateDataSuccess}
                      onCreateDataError={onCreateDataError}
                      type="alat_mesin"
                    />
                  </Tab>
                  <Tab title="Subkon" eventKey="subkon">
                    <TabItem
                      dropdownBuaso={dropdownBuaso?.subkon}
                      dataHistory={
                        mapDataHistoryHandler(dataHistory?.subkon, "subkon") ??
                        []
                      }
                      onCreateDataSuccess={onCreateDataSuccess}
                      onCreateDataError={onCreateDataError}
                      type="subkon"
                    />
                  </Tab>
                  <Tab title="Overhead" eventKey="overhead">
                    <TabItem
                      dropdownBuaso={dropdownBuaso?.overhead}
                      dataInfo={dataDetail ?? []}
                      dataHistory={
                        mapDataHistoryHandler(
                          dataHistory?.overhead,
                          "overhead"
                        ) ?? []
                      }
                      onCreateDataSuccess={onCreateDataSuccess}
                      onCreateDataError={onCreateDataError}
                      type="overhead"
                      id_item_buaso={dataDetail?.id_item_buaso}
                    />
                  </Tab>
                </Tabs>
                <AlertAlreadyStockOpname
                  tanggal={values?.tgl_permintaan_produksi}
                />
                <div className="d-flex justify-content-end mt-3">
                  <ActionButton
                    text="Simpan"
                    onClick={handleSubmit}
                    loading={isSubmitting}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      )}
    </>
  );
};
