import { DatePicker, Input, SelectSearch } from "components";
import { Col, Row } from "react-bootstrap";

export const FormSalesOrder = ({ data, readOnly }) => {
  return (
    <Row>
      <Col lg="2">
        <DatePicker
          disabled={readOnly}
          label="Tgl. Sales Order"
          placeholderText="Pilih Tgl. Sales Order"
          selected={data.tgl_sales_order ? new Date(data.tgl_sales_order) : ""}
        />
      </Col>

      <Col lg="3">
        <Input
          label="No. Sales Order"
          value={data.no_sales_order}
          readOnly={true}
        />
      </Col>

      <Col lg="2">
        <DatePicker
          disabled={readOnly}
          label="Tgl. Batas Akhir"
          placeholderText="Pilih Tgl. Batas Akhir"
          selected={data.batas_waktu ? new Date(data.batas_waktu) : ""}
        />
      </Col>

      <Col lg>
        <SelectSearch
          key={data?.id_sales}
          isDisabled={readOnly}
          label="Sales"
          placeholder="Pilih Sales"
          defaultValue={{
            value: data?.id_sales,
            label: data?.nama_sales,
          }}
        />
      </Col>
    </Row>
  );
};
