import React from 'react'
import Routes from './routes'
import {
  AuthContextProvider
} from './utilities'
import './styles/css/App.css'
import "react-datepicker/dist/react-datepicker.css"
import { QueryClient, QueryClientProvider } from 'react-query'
import { ModalConfirmProvider } from '@bhawanadevteam/react-core'

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false
      }
    }
  })
  return (
    <AuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <ModalConfirmProvider>
          <Routes />
        </ModalConfirmProvider>
      </QueryClientProvider>
    </AuthContextProvider>
  )
}

export default App