import React, { useState, useEffect } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { debounce } from "lodash";
import {
  CRUDLayout,
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  Pagination,
  Alert,
  ApprovalStatusButton,
} from "components";
import {
  InputSearch,
  DataStatus,
  ButtonCreate,
  ButtonUpdate,
  ButtonFilter,
  ButtonDetail,
  Button,
  ButtonVerify,
} from "components2";
import { tableNumber } from "utilities2";
import {
  ModalFilter,
  ModalRegisterItemAset,
} from "./__RealisasiProduksiAsetComps__";
import RealisasiProduksiAsetApi from "./__RealisasiProduksiAsetApi__";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { Formik } from "formik";
import { DateConvert, DecimalConvert } from "utilities";
import {
  formInitialValuesRegisterAset,
  formRegisterAsetValidationSchema,
  formSubmitValueMapperRegisterAset,
} from "./__RealisasiProduksiAsetUtils__";
// import { getStatusProgress } from "./__RealisasiProduksiAsetUtils__";

export const RealisasiProduksiAsetList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: "",
    variant: "primary",
  });

  const [filter, setFilter] = useState({
    q: "",
    page: 1,
    per_page: 10,
    tgl_permintaan_aset_mulai: undefined,
    tgl_permintaan_aset_selesai: undefined,
    tgl_produksi_aset_mulai: undefined,
    tgl_produksi_aset_selesai: undefined,
    active: false,
  });

  const [modalFilter, setModalFilter] = useState(false);
  const [modalRegisterItemAset, setModalRegisterItemAset] = useState({
    show: false,
    id: undefined,
  });

  const getRealisasiProduksiAset = useQuery(
    ["realisasiProduksiAset", "list", filter],
    () => RealisasiProduksiAsetApi.getList(filter)
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setFilter({
      ...filter,
      q: searchKey,
    });
    setAlertConfig({
      show: searchKey ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + searchKey,
    });
  };

  const onFilterButtonClickkHandler = () => setModalFilter(!modalFilter);

  const onPaginationChange = ({ selected }) =>
    setFilter({ ...filter, page: selected + 1 });

  const onPaginationDataLengthChange = (e) =>
    setFilter({ ...filter, page: 1, per_page: e.target.value });

  const checkAlert = () => {
    if (state) {
      if (state.alert) {
        setAlertConfig({
          show: state?.alert?.show,
          text: state?.alert?.text,
          variant: state?.alert?.variant,
        });
      }
    }
  };

  const registerAset = useMutation((data) =>
    RealisasiProduksiAsetApi.createRegisterAset(data)
  );

  // FORM VALUES FILTER
  const formInitialValuesFilter = {
    tgl_permintaan_aset_mulai: filter?.tgl_permintaan_aset_mulai,
    tgl_permintaan_aset_selesai: filter?.tgl_permintaan_aset_selesai,
    tgl_produksi_aset_mulai: filter?.tgl_produksi_aset_mulai,
    tgl_produksi_aset_selesai: filter?.tgl_produksi_aset_selesai,
  };

  const formSubmitHandlerFilter = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setFilter({
      ...filter,
      ...values,
      active: checkActive,
      page: 1,
    });
    onFilterButtonClickkHandler();
  };
  const formRegisterAsetSumbitHandler = (value, { setSubmitting }) => {
    registerAset
      .mutateAsync(formSubmitValueMapperRegisterAset(value))
      .then(() => {
        getRealisasiProduksiAset.refetch();
        setAlertConfig({
          variant: "primary",
          show: true,
          text: "Data berhasil disimpan!!!",
        });
      })
      .catch((err) =>
        setAlertConfig({
          variant: "danger",
          show: true,
          text: "Data gagal disimpan!!!",
        })
      )
      .finally(() => {
        setModalRegisterItemAset({
          ...modalRegisterItemAset,
          show: false,
          id: undefined,
        });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    setNavbarTitle("Realisasi Produksi Aset");
    checkAlert();
  }, []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={debounce(searchHandler, 700)}
              className="mr-2"
            />
            {/* <ButtonFilter
              size="sm"
              className="text-nowrap"
              onClick={onFilterButtonClickkHandler}
              active={filter?.active}
            /> */}
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <ButtonCreate
            size="md"
            icon
            onClick={() =>
              history.push("realisasi-produksi-aset/produksi-aset")
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      {getRealisasiProduksiAset.isFetching ||
      getRealisasiProduksiAset.isError ? (
        <DataStatus
          loading={getRealisasiProduksiAset.isFetching}
          text={
            getRealisasiProduksiAset.isFetching
              ? "Memuat . . ."
              : "Data gagal dimuat"
          }
        />
      ) : (
        <>
          <Alert
            show={alertConfig?.show}
            text={alertConfig?.text}
            variant={alertConfig?.variant}
            showCloseButton
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Realisasi Produksi Aset
            </small>
          </div>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Aksi</ThFixed>
                <ThFixed>Informasi Produksi Aset</ThFixed>
                <Th>Item Aset Yang Terproduksi</Th>
                <Th>Progress</Th>
                <ThFixed>Registrasi Aset</ThFixed>
              </Tr>
            </THead>
            <TBody>
              {getRealisasiProduksiAset?.data?.data?.length > 0 ? (
                getRealisasiProduksiAset?.data?.data?.map((e, index) => (
                  <Tr key={index}>
                    <TdFixed>
                      {tableNumber({
                        pageCurrent: filter.page,
                        dataLength: filter.per_page,
                        index,
                      })}
                    </TdFixed>
                    <TdFixed>
                      <ButtonGroup>
                        <ButtonDetail
                          icon
                          noText
                          onClick={() =>
                            history.push(
                              "/transaksi/realisasi-produksi-aset/detail/" +
                                e?.id_produksi_aset
                            )
                          }
                        />
                      </ButtonGroup>
                    </TdFixed>
                    <Td>
                      <div>
                        {DateConvert(new Date(e?.tgl_produksi_aset)).custom}
                      </div>
                      <div>{e.no_produksi_aset ?? "-"}</div>
                    </Td>
                    <Td>{e?.nama_aset ?? "-"}</Td>
                    <Td>
                      {DecimalConvert(parseFloat(e?.progress ?? 0)).getWithComa}
                      %
                    </Td>
                    <Td className="text-center">
                      {e?.id_item_yang_terproduksi ? (
                        <ButtonVerify icon noText />
                      ) : (
                        <ButtonCreate
                          icon
                          noText
                          disabled={parseFloat(e.progress ?? 0) < 100}
                          onClick={() =>
                            setModalRegisterItemAset({
                              ...modalRegisterItemAset,
                              show: true,
                              id: e?.id_produksi_aset,
                            })
                          }
                        />
                      )}
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Pagination
            dataLength={filter?.per_page}
            dataNumber={filter?.page * filter?.per_page - filter?.per_page + 1}
            dataPage={
              filter?.dataCount < filter?.per_page
                ? filter?.dataCount
                : filter?.page * filter?.per_page
            }
            dataCount={getRealisasiProduksiAset?.data?.data_count}
            currentPage={filter?.page}
            totalPage={getRealisasiProduksiAset?.data?.total_page}
            onPaginationChange={onPaginationChange}
            onDataLengthChange={onPaginationDataLengthChange}
          />
        </>
      )}

      {modalRegisterItemAset.show && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesRegisterAset()}
          validationSchema={formRegisterAsetValidationSchema}
          onSubmit={formRegisterAsetSumbitHandler}
        >
          <ModalRegisterItemAset
            setModalConfig={setModalRegisterItemAset}
            modalConfig={modalRegisterItemAset}
            action="create"
          />
        </Formik>
      )}

      {modalFilter && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesFilter}
          onSubmit={formSubmitHandlerFilter}
        >
          <ModalFilter toggle={onFilterButtonClickkHandler} />
        </Formik>
      )}
    </CRUDLayout>
  );
};
