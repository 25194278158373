import { ActionButton } from "components";
import React, { useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import { InfoFormSection } from "../InfoFormSection";
import { TableSection } from "../TableSection";

const ModalSection = ({
  modalConfirmConfig,
  setModalConfirmConfig,
  formik,
  dataInfo,
  dataTable,
  FormSubmitHandler,
  isSubmiting,
}) => {
  return (
    <Modal
      show={modalConfirmConfig.show}
      onHide={() => setModalConfirmConfig({ show: false })}
      size="xl"
    >
      <Modal.Header closeButton>
        <b>Konfirmasi</b>
      </Modal.Header>
      <Modal.Body>
        <b>Apa Anda yakin akan meng-close data produksi ini?</b>
        <InfoFormSection formik={formik} dataInfo={dataInfo} type="detail" />
        <b>List Data BDP Produksi</b>
        <TableSection data={dataTable} />
        <Alert variant="danger" style={{ padding: 4 }}>
          <span style={{ fontSize: 13 }}>
            Data produksi ini akan berubah statusnya dari ON PROGRESS menjadi CLOSED dan tidak dapat
            dikembalikan. Data produksi dengan status CLOSED tidak dapat digunakan sebagai acuan
            untuk Permintaan Produksi, Transfer Produksi, Realisasi Produksi, dan BDP Produksi.
          </span>
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <ActionButton
            text="BATAL"
            variant="outline-secondary"
            size="sm"
            className="mr-2"
            onClick={() => setModalConfirmConfig({ show: false })}
          />
          <ActionButton
            text="CLOSE PRODUKSI"
            variant="danger"
            size="sm"
            onClick={() => FormSubmitHandler(formik.values)}
            loading={isSubmiting}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSection;
