import { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  ActionButton,
  DatePicker,
  InfoItemHorizontal,
  InfoItemVertical,
  Input,
  SelectSearch,
  ApprovalStatusButton,
  Alert,
  NumberFormat,
} from "components";
import { DateConvert, DecimalConvert } from "utilities";
import { PermintaanProduksiApi } from "api";
import { getTotalHistoryQtyPermintaan } from "../Utils";

const decimalConvert = (value) => {
  const newValue = value.toString().replace(/[^0-9\.]/g, "");
  const convert = newValue.substring(0, 10);

  return convert;
};

const ModalDetail = ({ data = {}, removeStatus = false }) => {
  return (
    <>
      <Row>
        <Col md>
          <InfoItemVertical
            label="Tgl. Produksi"
            text={
              data?.tgl_produksi
                ? DateConvert(new Date(data.tgl_produksi)).detail
                : "-"
            }
          />
        </Col>
        <Col md>
          <InfoItemVertical
            label="No. Produksi"
            text={data?.no_produksi ?? "-"}
          />
        </Col>
      </Row>
      <Row>
        <Col md>
          <InfoItemVertical
            label="Tgl. Permintaan Produksi"
            text={
              data?.tgl_permintaan_produksi
                ? DateConvert(new Date(data.tgl_permintaan_produksi)).detail
                : "-"
            }
          />
        </Col>
        <Col md>
          <InfoItemVertical
            label="No. Permintaan Produksi"
            text={data?.no_permintaan_produksi ?? "-"}
          />
        </Col>
      </Row>
      <Row>
        <Col md>
          <InfoItemVertical
            label="Kode Item Permintaan"
            text={data?.kode_item ?? "-"}
          />
        </Col>
        <Col md>
          <InfoItemVertical
            label="Item Permintaan"
            text={data?.nama_item ?? "-"}
          />
        </Col>
      </Row>
      <Row>
        <Col md>
          <InfoItemVertical
            label="Qty. Permintaan Produksi"
            text={`${
              DecimalConvert(data?.qty_permintaan_produksi ?? 0).getWithComa
            } ${data?.satuan}`}
          />
        </Col>
        <Col md>
          <InfoItemVertical
            label="Qty. Telah Ditransfer"
            text={`${
              DecimalConvert(data?.qty_telah_ditransfer ?? 0).getWithComa
            } ${data?.satuan}`}
          />
        </Col>
      </Row>
      <Row>
        <Col md>
          <InfoItemVertical label="Proyek" text={data?.nama_proyek ?? "-"} />
        </Col>
      </Row>
      {!removeStatus && (
        <Row>
          <Col md>
            <InfoItemVertical
              label="Status Permintaan Produksi"
              text={
                <div>
                  <ApprovalStatusButton
                    size="sm"
                    style={{ width: 100 }}
                    variant={
                      data?.status_permintaan === "batal"
                        ? "outline-danger"
                        : data?.status_permintaan === "diproses"
                        ? "outline-warning"
                        : "outline-success"
                    }>
                    {data?.status_permintaan === "batal"
                      ? "BATAL"
                      : data?.status_permintaan === "diproses"
                      ? "DIPROSES"
                      : "SELESAI"}
                  </ApprovalStatusButton>
                </div>
              }
            />
          </Col>
          <Col md></Col>
        </Row>
      )}
    </>
  );
};

const ModalConfirm = ({ data = {} }) => {
  return (
    <>
      <ModalDetail data={data} removeStatus />
      <div className="mt-3" />
      <Alert
        show={true}
        variant="danger"
        text='Data permintaan produksi akan bertatus "Batal" dan tidak bisa dibuatkan transfer produksi.'
      />
    </>
  );
};

const ModalForm = ({ type, data = {}, dataHistory = [], generateName }) => {
  const { values, errors, touched, setValues, setFieldValue } =
    useFormikContext();
  const [dataItemBuaso, setDataItemBuaso] = useState([]);
  const [loadingNomor, setLoadingNomor] = useState(true);
  const [loadingItem, setLoadingItem] = useState(true);

  const generateNomorHandler = (date) => {
    setLoadingNomor(true);

    PermintaanProduksiApi.getNomor({
      buaso: type,
      tanggal: date,
    })
      .then((res) => {
        setFieldValue(`no_permintaan_produksi_${type}`, res?.data?.data);
      })
      .catch(() => {
        setValues({
          ...values,
          [`tgl_permintaan_produksi_${type}`]: "",
          [`no_permintaan_produksi_${type}`]: "",
        });
      })
      .finally(() => {
        setLoadingNomor(false);
      });
  };

  const getDataItemBuaso = () => {
    setLoadingItem(true);

    const fetch = () =>
      type === "alat_mesin"
        ? PermintaanProduksiApi.getDropdown({ tipe: "item_aset" })
        : PermintaanProduksiApi.getDropdown({ tipe: "item_buaso_permintaan" });

    fetch()
      .then((res) => {
        const data = res?.data?.data
          ? res.data.data.map((val) => ({
              value:
                type === "alat_mesin" ? val?.id_item_aset : val.id_item_buaso,
              label:
                type === "alat_mesin"
                  ? val?.nama_item ?? "-"
                  : val.nama_item ?? "-",
            }))
          : [];

        setDataItemBuaso(data);
      })
      .catch(() => {
        setDataItemBuaso([]);
      })
      .finally(() => {
        setLoadingItem(false);
      });
  };

  useEffect(() => {
    values?.[`tgl_permintaan_produksi_${type}`] &&
      generateNomorHandler(values?.[`tgl_permintaan_produksi_${type}`]);
    getDataItemBuaso();
  }, []);

  return (
    <>
      <InfoItemHorizontal
        label={`Kode ${generateName}`}
        text={data?.kode_item ?? "-"}
      />
      <InfoItemHorizontal
        label={`Item ${generateName}`}
        text={data?.nama_item ?? "-"}
      />
      <InfoItemHorizontal label="Satuan" text={data?.nama_satuan ?? "-"} />
      <InfoItemHorizontal
        label="Qty. Produksi"
        text={
          data?.qty_produksi
            ? DecimalConvert(data?.qty_produksi).getWithComa
            : "-"
        }
      />
      <InfoItemHorizontal
        label="Total Qty. Telah Diminta"
        text={getTotalHistoryQtyPermintaan(
          data?.[`id_item_${type === "alat_mesin" ? "aset" : "buaso"}`],
          dataHistory,
          type
        )}
      />
      <InfoItemHorizontal
        label="Total Qty. Telah Ditransfer"
        text={DecimalConvert(data?.qty_transfer ?? 0).getWithComa}
      />
      <hr />
      <Row>
        <Col md>
          <DatePicker
            label="Tgl. Permintaan Produksi"
            placeholder="Pilih tgl. permintaan produksi"
            selected={
              values?.[`tgl_permintaan_produksi_${type}`]
                ? new Date(values?.[`tgl_permintaan_produksi_${type}`])
                : undefined
            }
            onChange={(date) => {
              const parseDate = DateConvert(new Date(date)).default;
              setFieldValue(`tgl_permintaan_produksi_${type}`, parseDate);
              generateNomorHandler(parseDate);
            }}
            error={
              !!(
                errors?.[`tgl_permintaan_produksi_${type}`] &&
                touched?.[`tgl_permintaan_produksi_${type}`]
              )
            }
            errorText={
              !!(
                errors?.[`tgl_permintaan_produksi_${type}`] &&
                touched?.[`tgl_permintaan_produksi_${type}`]
              ) && errors?.[`tgl_permintaan_produksi_${type}`]
            }
          />
        </Col>
        <Col md>
          <Input
            label="No. Permintaan Produksi"
            value={
              loadingNomor
                ? "Memuat nomor . . ."
                : values?.[`no_permintaan_produksi_${type}`] ?? ""
            }
            error={
              !!(
                errors?.[`no_permintaan_produksi_${type}`] &&
                touched?.[`no_permintaan_produksi_${type}`]
              )
            }
            errorText={
              !!(
                errors?.[`no_permintaan_produksi_${type}`] &&
                touched?.[`no_permintaan_produksi_${type}`]
              ) && errors?.[`no_permintaan_produksi_${type}`]
            }
            disabled
          />
        </Col>
      </Row>
      <SelectSearch
        label="Item Permintaan"
        placeholder="Pilih item permintaan"
        loading={loadingItem}
        defaultValue={dataItemBuaso?.find(
          (val) =>
            values?.[`id_item_${type === "alat_mesin" ? "aset" : "buaso"}`] ===
            val.value
        )}
        onChange={(val) =>
          setFieldValue(
            `id_item_${type === "alat_mesin" ? "aset" : "buaso"}`,
            val.value
          )
        }
        option={dataItemBuaso}
        error={
          !!(
            errors?.[`id_item_${type === "alat_mesin" ? "aset" : "buaso"}`] &&
            touched?.[`id_item_${type === "alat_mesin" ? "aset" : "buaso"}`]
          )
        }
        errorText={
          !!(
            errors?.[`id_item_${type === "alat_mesin" ? "aset" : "buaso"}`] &&
            touched?.[`id_item_${type === "alat_mesin" ? "aset" : "buaso"}`]
          ) && errors?.[`id_item_${type === "alat_mesin" ? "aset" : "buaso"}`]
        }
      />
      <Row>
        <Col md>
          {/* <Input
            label="Qty. Permintaan Produksi"
            value={values.qty_permintaan_produksi}
            onChange={(e) => {
              const value = decimalConvert(e?.target?.value || "");
              setFieldValue("qty_permintaan_produksi", value);
            }}
            error={
              !!(
                errors.qty_permintaan_produksi &&
                touched.qty_permintaan_produksi
              )
            }
            errorText={
              !!(
                errors.qty_permintaan_produksi &&
                touched.qty_permintaan_produksi
              ) && errors.qty_permintaan_produksi
            }
          /> */}
          <NumberFormat
            label="Qty. Permintaan Produksi"
            value={values.qty_permintaan_produksi}
            onChange={(e) => {
              // const value = decimalConvert(e?.target?.value || "");
              setFieldValue("qty_permintaan_produksi", e);
            }}
            error={
              !!(
                errors.qty_permintaan_produksi &&
                touched.qty_permintaan_produksi
              )
            }
            errorText={
              !!(
                errors.qty_permintaan_produksi &&
                touched.qty_permintaan_produksi
              ) && errors.qty_permintaan_produksi
            }
          />
        </Col>
        <Col md>
          <Input label="Satuan" value={data?.nama_satuan ?? "-"} disabled />
        </Col>
      </Row>
    </>
  );
};

export const ModalPermintaanProduksi = ({
  show,
  onHide,
  data = {},
  dataHistory = [],
  type = "",
  action,
  onCreateDataSuccess,
  onCreateDataError,
}) => {
  const generateName =
    type === "bahan"
      ? "Bahan"
      : type === "upah"
      ? "Upah"
      : type === "alat_mesin"
      ? "Alat & Mesin"
      : type === "subkon"
      ? "Subkon"
      : type === "overhead"
      ? "Overhead"
      : "";

  const formInitialValues = {
    [`id_item_${type === "alat_mesin" ? "aset" : "buaso"}`]:
      data?.id_item_buaso ?? data?.id_item_aset,
    [`tgl_permintaan_produksi_${type}`]: "",
    [`no_permintaan_produksi_${type}`]: "",
    id_jobmix: data?.id_jobmix,
    qty_permintaan_produksi: parseFloat(data?.qty_produksi ?? 0),
  };

  const formValidationSchema = Yup.object().shape({
    [`id_item_${type === "alat_mesin" ? "aset" : "buaso"}`]:
      Yup.string().required("Item permintaan tidak boleh kosong"),
    [`tgl_permintaan_produksi_${type}`]: Yup.string().required(
      "Tgl permintaan tidak boleh kosong"
    ),
    [`no_permintaan_produksi_${type}`]: Yup.string().required(
      "No. permintaan tidak boleh kosong, Pilih tgl untuk menentukan nomor"
    ),
    qty_permintaan_produksi: Yup.string().required(
      "Qty. permintaan tidak boleh kosong"
    ),
    // .test({
    //   name: "check-qty",
    //   message: "Qty. transfer tidak boleh lebih dari permintaan",
    //   test: (val) => {
    //     const totalPermintaan = getTotalHistoryQtyPermintaan(
    //       data?.[`id_item_${type === "alat_mesin" ? "aset" : "buaso"}`],
    //       dataHistory,
    //       type
    //     );

    //     return !!(val <= parseFloat(parseFloat(data?.qty_produksi ?? 0) - totalPermintaan));
    //   },
    // }),
  });

  const formSubmitHandler = (values) => {
    const saveData = () =>
      type === "bahan"
        ? action === "confirm"
          ? PermintaanProduksiApi.cancelBahan({
              id: data?.id_permintaan_produksi,
            })
          : PermintaanProduksiApi.saveBahan(values)
        : type === "upah"
        ? action === "confirm"
          ? PermintaanProduksiApi.cancelUpah({
              id: data?.id_permintaan_produksi,
            })
          : PermintaanProduksiApi.saveUpah(values)
        : type === "alat_mesin"
        ? action === "confirm"
          ? PermintaanProduksiApi.cancelAlatMesin({
              id: data?.id_permintaan_produksi,
            })
          : PermintaanProduksiApi.saveAlatMesin(values)
        : type === "subkon"
        ? action === "confirm"
          ? PermintaanProduksiApi.cancelSubkon({
              id: data?.id_permintaan_produksi,
            })
          : PermintaanProduksiApi.saveSubkon(values)
        : type === "overhead"
        ? action === "confirm"
          ? PermintaanProduksiApi.cancelOverhead({
              id: data?.id_permintaan_produksi,
            })
          : PermintaanProduksiApi.saveOverhead(values)
        : new Promise((_res, rej) => rej("bukan type buaso"));

    saveData()
      .then(() => {
        onCreateDataSuccess();
      })
      .catch(() => {
        onCreateDataError();
      })
      .finally(() => {
        onHide();
      });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <b>
          {action === "form"
            ? "Tambah Data Permintaan Produksi"
            : action === "detail"
            ? "Detail Data Permintaan Produksi"
            : action === "confirm" && "Konfirmasi"}
        </b>
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={
          action === "confirm" ? undefined : formValidationSchema
        }
        onSubmit={formSubmitHandler}>
        {({ isSubmitting, handleSubmit }) => (
          <>
            <Modal.Body>
              {action === "form" ? (
                <ModalForm
                  type={type}
                  data={data}
                  generateName={generateName}
                  dataHistory={dataHistory}
                />
              ) : action === "confirm" ? (
                <ModalConfirm
                  type={type}
                  data={data}
                  generateName={generateName}
                />
              ) : (
                action === "detail" && (
                  <ModalDetail
                    type={type}
                    data={data}
                    generateName={generateName}
                  />
                )
              )}
            </Modal.Body>
            <Modal.Footer>
              <ActionButton
                variant="outline-secondary"
                text={action === "form" ? "Batal" : "Kembali"}
                onClick={onHide}
              />
              {action === "detail" ? (
                ""
              ) : (
                <ActionButton
                  loading={isSubmitting}
                  onClick={handleSubmit}
                  variant={action === "confirm" ? "danger" : "primary"}
                  text={action === "confirm" ? "Batalkan" : "Simpan"}
                />
              )}
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};
