import Services from 'services'

class JobMixDesignApi {
    page(value) {
        return Services.get(`/jobmix_design/page`, { params: { ...value } });
    }
    list_item_barang(value) {
        return Services.get(`/jobmix_design/list_item_barang`, { params: { ...value } });
    }
    single(value) {
        return Services.get(`/jobmix_design/single`, { params: { ...value } });
    }
    item_barang_single(id) {
        return Services.get(`/jobmix_design/item_barang_single?id_item_buaso=${id}`);
    }
    dropdown(value) {
        return Services.get(`/jobmix_design/dropdown`, { params: { ...value } });
    }
    getAnalisa(params) {
        return Services.get(`jobmix_design/salin_analisa`, { params });
    }
    no_baru(params) {
        return Services.get(`/jobmix_design/no_baru`, { params });
    }
    create(value) {
        return Services.post(`/jobmix_design`, value);
    }
    update(value) {
        return Services.put(`/jobmix_design`, value);
    }
    delete(value) {
        return Services.post(`/jobmix_design/delete`, value);
    }
    show(value) {
        return Services.put(`/jobmix_design/show`, value);
    }
    hide(value) {
        return Services.put(`/jobmix_design/hide`, value);
    }
    create_analisa(value) {
        return Services.post(`/jobmix_design/analisa`, value);
    }
    update_analisa(value) {
        return Services.put(`/jobmix_design/analisa`, value);
    }
    analisa_delete(value) {
        return Services.post(`/jobmix_design/analisa_delete`, value);
    }
    single_analisa(value) {
        return Services.get(`/jobmix_design/single_analisa`, { params: { ...value } });
    }
    upload(value) {
        return Services.post(`/upload/barang_jadi_multiple`, value);
    }
    cek_nama(value) {
        return Services.get(`/jobmix_design/cek_nama`, { params: { ...value } });
    }
}

export default new JobMixDesignApi();