const mappingData = (data, label, kode) => {
  return data.data.data.map((val) => {
    return {
      value: val[`id_${label}`],
      label: `${val?.[kode]} | ${val[`nama_${label}`]}`,
      ...val,
    };
  });
};

export default mappingData;
