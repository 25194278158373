import { DecimalConvert } from "utilities";

export const getTotalHistoryQtyPermintaan = (
  data,
  dataHistory = [],
  type = ""
) =>
  dataHistory
    ?.filter((val) => val?.id_item === data)
    .reduce((prev, current) => {
      return DecimalConvert(
        prev + parseFloat(current?.qty_permintaan_produksi ?? 0)
      ).getWithComa;
    }, 0);
