import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { FormProduksiAsetContent } from "./__CloseProduksiAsetComps__";
import { useMutation, useQuery } from "react-query";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import ProduksiAsetApi from "./__CloseProduksiAsetApi__";
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
} from "./__CloseProduksiAsetUtils__";
import { Alert, DataStatus } from "components";

export const CloseProduksiAsetDetail = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: "",
    variant: "primary",
  });

  const { data: detailProduksiAset, isFetching: loadingDetailProduksiAset } =
    useQuery(
      ["produksiAset", "single", id],
      () =>
        ProduksiAsetApi.getSingleProduksiAset({
          id_produksi_aset: id,
        }),
      { enabled: !!id }
    );

  const createMaintenanceRequest = useMutation((data) =>
    ProduksiAsetApi.update(data)
  );
  const formSubmitHandler = (value) => {
    createMaintenanceRequest
      .mutateAsync(formSubmitValueMapper(value))
      .then(() =>
        history.push("/transaksi/maintenance-request", {
          alert: {
            variant: "primary",
            show: true,
            text: "Data berhasil disimpan!!!",
          },
        })
      )
      .catch((err) =>
        setAlertConfig({
          variant: "danger",
          show: true,
          text: "Data gagal disimpan!!!",
        })
      );
  };

  useEffect(() => {
    setNavbarTitle("Close Produksi Aset");

    return () => {};
  }, []);

  return (
    <>
      <Alert
        show={alertConfig?.show}
        text={alertConfig?.text}
        variant={alertConfig?.variant}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
        showCloseButton
      />
      {loadingDetailProduksiAset ? (
        <DataStatus loading text="Memuat Data..." />
      ) : (
        <Formik
          initialValues={formInitialValues(detailProduksiAset?.data)}
          onSubmit={formSubmitHandler}
          validationSchema={formValidationSchema}
        >
          <FormProduksiAsetContent action="detail" />
        </Formik>
      )}
    </>
  );
};
