export const formInitialValues = (data) => {
  return Object({
    id_produksi_aset: data?.id_produksi_aset ?? undefined,
    id_item_yang_terproduksi: data?.id_item_yang_terproduksi ?? undefined,
    tgl_close_produksi_aset: data?.tgl_close_produksi_aset ?? undefined,
    no_close_produksi_aset: data?.no_close_produksi_aset ?? "",
    tgl_produksi_aset: data?.tgl_produksi_aset ?? undefined,
    no_produksi_aset: data?.no_produksi_aset ?? "",
    id_permintaan_aset: data?.id_permintaan_aset ?? undefined,
    id_permintaan_aset_detail: data?.id_permintaan_aset_detail ?? undefined,
    tgl_permintaan_aset: data?.tgl_permintaan_aset ?? undefined,
    no_permintaan_aset: data?.no_permintaan_aset ?? undefined,
    nama_grup_aset: data?.nama_grup_aset ?? "",
    nama_kategori_aset: data?.nama_kategori_aset ?? "",
    nama_jenis_aset: data?.nama_jenis_aset ?? "",
    nama_aset: data?.nama_aset ?? "",
    keperluan: data?.keperluan ?? "",
    file:
      data?.file?.length > 0 ? data?.file?.map((val) => val?.path_file) : [],
    bahan: data?.bahan ?? [],
    upah: data?.upah ?? [],
    alatMesin: data?.alat_mesin ?? [],
    subkon: data?.subkon ?? [],
    overhead: data?.overhead ?? [],
  });
};

export const formInitialValuesRegisterAset = (data) => {
  return Object({
    id_produksi_aset: data?.id_produksi_aset ?? undefined,
    tgl_permintaan_aset: data?.tgl_permintaan_aset ?? undefined,
    no_permintaan_aset: data?.no_permintaan_aset ?? "",
    tgl_produksi_aset: data?.tgl_produksi_aset ?? undefined,
    tgl_produksi_aset: data?.tgl_produksi_aset ?? "",
    tgl_realisasi_produksi_aset: data?.tgl_realisasi_produksi_aset ?? undefined,
    tgl_realisasi_produksi_aset: data?.tgl_realisasi_produksi_aset ?? "",
    keperluan: data?.keperluan ?? "",
    nama_entitas_aset: data?.nama_entitas_aset ?? "",
    nama_grup_aset: data?.nama_grup_aset ?? "",
    nama_kategori_aset: data?.nama_kategori_aset ?? "",
    nama_jenis_aset: data?.nama_jenis_aset ?? "",
    nama_unit_organisasi: data?.nama_unit_organisasi ?? "",
    id_entitas_aset: data?.id_entitas_aset ?? undefined,
    id_grup_aset: data?.id_grup_aset ?? undefined,
    id_kategori_aset: data?.id_kategori_aset ?? undefined,
    id_jenis_aset: data?.id_jenis_aset ?? undefined,
    id_unit_organisasi: data?.id_unit_organisasi ?? undefined,
    nama_item_aset: data?.nama_item_aset ?? "",
    harga_perolehan: data?.harga_perolehan ?? 0,
    id_penerimaan_aset: data?.id_penerimaan_aset ?? undefined,
    id_kondisi_aset: data?.id_kondisi_aset ?? undefined,
    nama_kondisi_aset: data?.nama_kondisi_aset ?? "",
    id_karyawan: data?.id_karyawan ?? undefined,
    nama_karyawan: data?.nama_karyawan ?? "",
    id_provinsi: data?.id_provinsi ?? undefined,
    nama_provinsi: data?.nama_provinsi ?? "",
    id_kabupaten: data?.id_kabupaten ?? undefined,
    nama_kabupaten: data?.nama_kabupaten ?? "",
    id_kecamatan: data?.id_kecamatan ?? undefined,
    nama_kecamatan: data?.nama_kecamatan ?? "",
    id_desa: data?.id_desa ?? undefined,
    nama_desa: data?.nama_desa ?? "",
    detail_lokasi: data?.detail_lokasi ?? "",
    kode_item_aset: data?.kode_item_aset ?? "",
    bulan_perolehan: data?.bulan_perolehan ?? undefined,
    tahun_perolehan: data?.tahun_perolehan ?? undefined,
    id_satuan_pakai: data?.id_satuan_pakai ?? undefined,
    nama_satuan_pakai: data?.nama_satuan_pakai ?? "",
    id_pabrikan: data?.id_pabrikan ?? undefined,
    nama_pabrikan: data?.nama_pabrikan ?? "",
    no_sertifikat: data?.no_sertifikat ?? "",
    plat_nomor: data?.plat_nomor ?? "",
    no_seri: data?.no_seri ?? "",
    disewa: data?.disewa ?? false,
    produksi: data?.produksi ?? false,
    keterangan: data?.keterangan ?? "",
    qr_code: data?.qr_code ?? "",
    barcode: data?.barcode ?? "",
  });
};
