import React from "react";
import { Alert } from "react-bootstrap";

export const AlertInformasi = () => {
  return (
    <div>
      <Alert variant="danger" className="my-3" style={{ padding: 4 }} show>
        <span style={{ fontSize: 13 }}>
          Data produksi aset ini akan berubah statusnya dari ON PROGRESS menjadi
          CLOSED dan tidak dapat dikembalikan. Data produksi aset dengan status
          CLOSED tidak dapat digunakan sebagai acuan untuk Permintaan Produksi,
          Realisasi Produksi, dan WIP.
        </span>
      </Alert>
    </div>
  );
};
