import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import { debounce } from "lodash";
import {
  CRUDLayout,
  InputSearch,
  Alert,
  DataStatus,
  Pagination,
  CreateButton,
} from "components";
import { PermintaanProduksiApi } from "api";
import { TablePermintaanProduksiList } from "./Comps";

export const PermintaanProduksiList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState(undefined);
  const [tab, setTab] = useState("bahan");
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    error: true,
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alert, setAlert] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [pagination, setPagination] = useState({
    page: 1,
    dataLength: 10,
    totalPage: 1,
    dataCount: 0,
  });
  const [searchTime, setSearchTime] = useState(null);

  const mapDataHandler = (data = [], type) =>
    data.map((val) => ({
      id_permintaan_produksi: val?.[`id_permintaan_produksi_${type}`],
      tgl_permintaan_produksi: val?.[`tgl_permintaan_produksi_${type}`],
      no_permintaan_produksi: val?.[`no_permintaan_produksi_${type}`],
      tgl_produksi: val?.tgl_jobmix,
      no_produksi: val?.no_jobmix,
      nama_item: val?.nama_item,
      kode_item: val?.kode_item,
      satuan: val?.kode_satuan,
      qty_permintaan_produksi: val?.qty_permintaan_produksi
        ? parseFloat(val.qty_permintaan_produksi)
        : null,
      qty_telah_ditransfer: val?.qty_transfer
        ? parseFloat(val.qty_transfer)
        : 0,
      status_permintaan: val?.status_permintaan_produksi,
      nama_proyek: val?.nama_proyek,
    }));

  const getInitialData = () => {
    setData([]);
    setFetchingStatus({
      loading: true,
      error: true,
    });

    const fetchParams = {
      page: pagination.page,
      per_page: pagination.dataLength,
      q: searchConfig.key,
    };

    const fetchData = () =>
      tab === "bahan"
        ? PermintaanProduksiApi.getBahan(fetchParams)
        : tab === "upah"
        ? PermintaanProduksiApi.getUpah(fetchParams)
        : tab === "alat_mesin"
        ? PermintaanProduksiApi.getAlatMesin(fetchParams)
        : tab === "subkon"
        ? PermintaanProduksiApi.getSubkon(fetchParams)
        : tab === "overhead"
        ? PermintaanProduksiApi.getOverhead(fetchParams)
        : new Promise((_res, rej) => rej("bukan type buaso"));

    fetchData()
      .then((res) => {
        setData(mapDataHandler(res?.data?.data ?? [], tab));
        setPagination({
          ...pagination,
          dataCount: res?.data?.data_count,
          totalPage: res?.data?.total_page,
        });
        console.log(pagination);
        setFetchingStatus({
          loading: false,
          error: false,
        });
      })
      .catch(() => {
        setData([]);
        setFetchingStatus({
          loading: false,
          error: true,
        });
      });
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: e.target.value,
        });
        setAlert({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
      }, 750)
    );
  };

  // const searchHandler = (e) => {
  //   setSearch(e.target.value);
  // };

  const onCancelDataSuccess = () => {
    setAlert({
      show: true,
      variant: "primary",
      text: "Data permintaan berhasil dibatalkan!",
    });
    getInitialData();
  };

  const onCancelDataError = () => {
    setAlert({
      show: true,
      variant: "danger",
      text: "Data permintaan gagal dibatalkan!",
    });
    getInitialData();
  };

  console.log("test", history);

  useEffect(() => {
    setNavbarTitle("Permintaan Produksi");
    setAlert({
      show: !!history?.location?.state?.alert?.show,
      variant: history?.location?.state?.alert?.variant,
      text: history?.location?.state?.alert?.text,
    });
  }, []);

  useEffect(() => {
    getInitialData();
  }, [tab, searchConfig.key, pagination.page, pagination.dataLength]);

  return (
    <CRUDLayout>
      {/* Head */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-0">
            <InputSearch onChange={onInputSearchChange} />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push("/transaksi/permintaan-produksi/produksi")
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert show={alert.show} variant={alert.variant} text={alert.text} />

      {/* Content */}
      <>
        <div className="p-1">
          <b>List Data Permintaan Produksi</b>
        </div>
        <div className="my-1">
          <Tabs
            defaultActiveKey={tab}
            transition={false}
            onSelect={(e) => {
              setTab(e);
              setPagination({
                ...pagination,
                page: 1,
              });
            }}
          >
            <Tab title="Bahan" eventKey="bahan">
              <TablePermintaanProduksiList
                data={data ?? []}
                fetchingStatus={fetchingStatus}
                pagination={pagination}
                setPagination={setPagination}
                tab={tab}
                onCreateDataSuccess={onCancelDataSuccess}
                onCreateDataError={onCancelDataError}
              />
            </Tab>
            <Tab title="Upah" eventKey="upah">
              <TablePermintaanProduksiList
                data={data ?? []}
                fetchingStatus={fetchingStatus}
                pagination={pagination}
                setPagination={setPagination}
                tab={tab}
                onCreateDataSuccess={onCancelDataSuccess}
                onCreateDataError={onCancelDataError}
              />
            </Tab>
            <Tab title="Alat & Mesin" eventKey="alat_mesin">
              <TablePermintaanProduksiList
                data={data ?? []}
                fetchingStatus={fetchingStatus}
                pagination={pagination}
                setPagination={setPagination}
                tab={tab}
                onCreateDataSuccess={onCancelDataSuccess}
                onCreateDataError={onCancelDataError}
              />
            </Tab>
            <Tab title="Subkon" eventKey="subkon">
              <TablePermintaanProduksiList
                data={data ?? []}
                fetchingStatus={fetchingStatus}
                pagination={pagination}
                setPagination={setPagination}
                tab={tab}
                onCreateDataSuccess={onCancelDataSuccess}
                onCreateDataError={onCancelDataError}
              />
            </Tab>
            <Tab title="Overhead" eventKey="overhead">
              <TablePermintaanProduksiList
                data={data ?? []}
                fetchingStatus={fetchingStatus}
                pagination={pagination}
                setPagination={setPagination}
                tab={tab}
                onCreateDataSuccess={onCancelDataSuccess}
                onCreateDataError={onCancelDataError}
              />
            </Tab>
          </Tabs>
        </div>
      </>
    </CRUDLayout>
  );
};
