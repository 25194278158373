// React
import React, { useState, useEffect } from "react";

// Router
import { useHistory, useLocation } from "react-router-dom";

// Components
import { ButtonGroup } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ReadButton,
  Table,
  Th,
  Td,
  InputSearch,
  Pagination,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  BackButton,
  ActionButton,
  FilterButton,
} from "components";
import { PageNumber as TableNumber, DateConvert } from "utilities";

// API
import { AnalisaBarangJadiApi } from "api";

// Icons
import { IoAddOutline } from "react-icons/io5";
import { ModalFilter } from "./components";

const ListJobMixDesign = ({ setNavbarTitle }) => {
  // Hooks
  const history = useHistory();
  const location = useLocation();

  // Variables
  const filter = location?.state?.pagination;
  const title = "Analisa Barang Jadi";

  // States
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [data, setData] = useState([]);
  const [modalFilter, setModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    active: filter?.jobmix?.active,
    page: filter?.jobmix?.page ?? 1,
    per_page: filter?.jobmix?.per_page ?? 10,
    q: filter?.jobmix?.q ?? "",
    jenis_barang: filter?.jobmix?.jenis_barang,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [totalPage, setTotalPage] = useState(0);
  const [totalData, setTotalData] = useState(0);

  const getInitialData = () => {
    const filterData = { ...dataFilter };
    delete filterData.active;

    setIsPageLoading(true);

    AnalisaBarangJadiApi.page_jobmix(filterData)
      .then((res) => {
        setData(res?.data?.data ?? []);
        setTotalPage(res.data.total_page);
        setTotalData(res.data.data_count);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const dismissAlert = () => {
    let state = { ...history.location.state };
    delete state.alert;
    history.replace({ ...history.location, state });

    setAlertConfig({
      ...alertConfig,
      show: false,
    });
  };

  useEffect(() => {
    setNavbarTitle(title);
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line
  }, [setNavbarTitle]);

  useEffect(() => {
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line
  }, [dataFilter]);

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Kode Barang</ThFixed>
            <Th style={{ minWidth: "250px" }}>Nama Barang</Th>
            <Th style={{ width: "150px" }}>Jenis Barang</Th>
            <ThFixed>Update Terakhir</ThFixed>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>{TableNumber(dataFilter.page, dataFilter.per_page, index)}</TdFixed>
              <TdFixed>{val.kode_item ?? "-"}</TdFixed>
              <Td>{val.nama_item ?? "-"} [{val.nama_varian ?? "-"}]</Td>
              <Td>{val.nama_jenis ?? "-"}</Td>
              <TdFixed>
                {val.tgl_approval ? DateConvert(new Date(val.tgl_approval)).defaultDMY : "-"}
              </TdFixed>
              <TdFixed className="align-middle">
                <ButtonGroup>
                  <ReadButton
                    size="sm"
                    onClick={() =>
                      history.push(`/master/job-mix-design/detail/${val.id_jobmix_design}`, {
                        pagination: {
                          ...filter,
                          jobmix: dataFilter,
                        },
                      })
                    }
                  />
                  <ActionButton
                    size="sm"
                    variant="primary"
                    iconOnly
                    block
                    tooltip
                    tooltipText="Tambah Data"
                    onClick={() =>
                      history.push(`/master/analisa-barang-jadi/tambah/${val.id_jobmix_design}`, {
                        pagination: {
                          ...filter,
                          jobmix: dataFilter,
                        },
                      })
                    }
                  >
                    <IoAddOutline size="14" />
                  </ActionButton>
                </ButtonGroup>
              </TdFixed>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <b>{`List Data Job Mix Design Yang Siap Dibuatkan ${title}`}</b>
        <DataTable />
        <Pagination
          dataLength={dataFilter.per_page}
          dataPage={
            totalData <= 10
              ? data.length
              : data.map((_res, index) => {
                  if (index === data.length - 1) {
                    return TableNumber(dataFilter.page, dataFilter.per_page, index);
                  } else {
                    return null;
                  }
                })
          }
          dataNumber={data.map((_res, index) => {
            if (index === 0) {
              return TableNumber(dataFilter.page, dataFilter.per_page, index);
            } else {
              return null;
            }
          })}
          dataCount={totalData}
          onDataLengthChange={(e) => {
            setDataFilter({
              ...dataFilter,
              per_page: e.target.value,
              page: 1,
            });
          }}
          currentPage={dataFilter.page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              page: selected + 1,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              value={dataFilter.q}
              onChange={(e) =>
                setDataFilter({
                  ...dataFilter,
                  q: e.target.value,
                })
              }
            />

            <FilterButton active={dataFilter?.active} onClick={() => setModalFilter(true)} />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <BackButton
            size="md"
            className="mb-1"
            onClick={() =>
              history.push("/master/analisa-barang-jadi", {
                pagination: {
                  ...filter,
                  jobmix: dataFilter,
                },
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <div style={{ minHeight: "500px" }}>
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() => dismissAlert()}
        />

        {isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." /> : <PageContent />}
        {modalFilter && (
          <ModalFilter
            show={modalFilter}
            setShow={setModalFilter}
            data={dataFilter}
            setData={setDataFilter}
            isJobmix
          />
        )}
      </div>
    </CRUDLayout>
  );
};

export default ListJobMixDesign;
