import Services from "services";

class PermintaanProduksiApi {
  getNomor(params) {
    return Services.get("/permintaan_produksi/no_baru", { params });
  }

  getDropdown(params) {
    return Services.get("/permintaan_produksi/dropdown", { params });
  }

  getBahan(params) {
    return Services.get("/permintaan_produksi/bahan_page", { params });
  }

  getUpah(params) {
    return Services.get("/permintaan_produksi/upah_page", { params });
  }

  getAlatMesin(params) {
    return Services.get("/permintaan_produksi/alat_mesin_page", { params });
  }

  getSubkon(params) {
    return Services.get("/permintaan_produksi/subkon_page", { params });
  }

  getOverhead(params) {
    return Services.get("/permintaan_produksi/overhead_page", { params });
  }

  getHistory(params) {
    return Services.get("/permintaan_produksi/history", { params });
  }

  getProduksi(params) {
    return Services.get("/permintaan_produksi/jobmix_page", { params });
  }

  getSingleProduksi(params) {
    return Services.get("/permintaan_produksi/jobmix_single", { params });
  }

  getDropdownBuaso(params) {
    return Services.get("/permintaan_produksi/dropdown", { params });
  }

  getDropdownGudang() {
    return Services.get("/dropdown/gudang");
  }

  saveBahan(data) {
    return Services.post("/permintaan_produksi/bahan", data);
  }

  saveUpah(data) {
    return Services.post("/permintaan_produksi/upah", data);
  }

  saveAlatMesin(data) {
    return Services.post("/permintaan_produksi/alat_mesin", data);
  }

  saveSubkon(data) {
    return Services.post("/permintaan_produksi/subkon", data);
  }

  saveOverhead(data) {
    return Services.post("/permintaan_produksi/overhead", data);
  }

  saveAll(data) {
    return Services.post("/permintaan_produksi/auto", data);
  }

  cancelBahan(data) {
    return Services.post("/permintaan_produksi/bahan_batal", data);
  }

  cancelUpah(data) {
    return Services.post("/permintaan_produksi/upah_batal", data);
  }

  cancelAlatMesin(data) {
    return Services.post("/permintaan_produksi/alat_mesin_batal", data);
  }

  cancelSubkon(data) {
    return Services.post("/permintaan_produksi/subkon_batal", data);
  }

  cancelOverhead(data) {
    return Services.post("/permintaan_produksi/overhead_batal", data);
  }
}

export default new PermintaanProduksiApi();
