import React, { useEffect, useState } from "react";
import { Card, Nav } from "react-bootstrap";

import { TabBahan, TabUpah, TabAlatMesin, TabSubcont, TabOverhead } from "./Tabs";

const TableSection = ({ data }) => {
  const [tabs, setTabs] = useState("bahan"); //State untuk menampung tabs yang aktif

  // Konfigurasi komponen tabs
  const tabsConfig = [
    {
      tab: "bahan",
      label: "Bahan",
      component: () => <TabBahan dataBahan={data.data_bahan ?? []} />,
    },
    {
      tab: "upah",
      label: "Upah",
      component: () => <TabUpah dataUpah={data.data_upah ?? []} />,
    },
    {
      tab: "alatMesin",
      label: "Alat & Mesin",
      component: () => <TabAlatMesin dataAlatMesin={data.data_alat_mesin ?? []} />,
    },
    {
      tab: "subcont",
      label: "Subcont",
      component: () => <TabSubcont dataSubcont={data.data_subcont ?? []} />,
    },
    {
      tab: "overhead",
      label: "Overhead",
      component: () => <TabOverhead dataOverhead={data.data_overhead ?? []} />,
    },
  ];

  // Menangani perubahan pada tabs
  const onTabsChangeHandler = (e, newPage) => {
    e.preventDefault();
    setTabs(newPage);
  };

  useEffect(() => {
    setTabs(tabsConfig[0].tab);
  }, []);

  const TabsNav = ({ tab, label }) => (
    <Nav.Item>
      <Nav.Link href={`#${tab}`} onClick={(e) => onTabsChangeHandler(e, tab)}>
        {label}
      </Nav.Link>
    </Nav.Item>
  );

  return (
    <Card className="mb-4">
      <Card.Header>
        <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
          {tabsConfig.map((val, index) => (
            <TabsNav key={index} tab={val.tab} label={val.label} />
          ))}
        </Nav>
      </Card.Header>
      <Card.Body>
        {tabsConfig.map(
          ({ tab, component: Component }, index) => tab === tabs && <Component key={index} />
        )}
      </Card.Body>
    </Card>
  );
};

export default TableSection;
