export const formSubmitValueMapper = (data = {}) => ({
  tgl_permintaan_produksi_aset: data?.tgl_permintaan_produksi_aset,
  id_produksi_aset: data?.id_produksi_aset,
  no_produksi_aset: data?.no_produksi_aset,
  bahan: data?.bahan?.map((val) => ({
    qty_permintaan_produksi: val?.qty,
    id_item_bahan: val?.id_item,
    harga_satuan: val?.harga_satuan ?? 0,
    id_gudang: val?.id_gudang,
    id_satuan: val?.id_satuan,
  })),
  upah: data?.upah?.map((val) => ({
    qty_permintaan_produksi: val?.qty,
    id_item_upah: val?.id_item,
    harga_satuan: val?.harga_satuan,
  })),
  alat_mesin: data?.alatMesin?.map((val) => ({
    qty_permintaan_produksi: val?.qty,
    id_item_alat_mesin: val?.id_item,
    harga_satuan: val?.harga_satuan ?? 0,
  })),
  subkon: data?.subkon?.map((val) => ({
    qty_permintaan_produksi: val?.qty,
    id_item_subkon: val?.id_item,
    harga_satuan: val?.harga_satuan ?? 0,
  })),
  overhead: data?.overhead?.map((val) => ({
    qty_permintaan_produksi: val?.qty,
    id_item_overhead: val?.id_item,
    harga_satuan: val?.harga_satuan ?? 0,
  })),
});
