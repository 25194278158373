import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { FormProduksiAsetContent } from "./__CloseProduksiAsetComps__";
import { useMutation, useQuery } from "react-query";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import ProduksiAsetApi from "./__CloseProduksiAsetApi__";
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
} from "./__CloseProduksiAsetUtils__";
import { Alert, DataStatus } from "components";
import { Modal } from "react-bootstrap";

export const CloseProduksiAsetCreate = ({ setNavbarTitle }) => {
  const { id } = useParams();

  const [modalClosedConfig, setModalClosedConfig] = useState({ show: false });

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: "",
    variant: "primary",
  });

  const {
    data: detailPermintaanAset,
    isFetching: loadingDetailPermintaanAset,
  } = useQuery(
    ["permintaanAset", "single", id],
    () =>
      ProduksiAsetApi.getSingleProduksiAset({
        id_produksi_aset: id,
      }),
    { enabled: !!id }
  );

  const formSubmitHandler = (value) => {
    setModalClosedConfig({ show: true });
  };

  useEffect(() => {
    setNavbarTitle("Close Produksi Aset");
    return () => {};
  }, []);

  return (
    <>
      <Alert
        show={alertConfig?.show}
        text={alertConfig?.text}
        variant={alertConfig?.variant}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
        showCloseButton
      />
      {loadingDetailPermintaanAset ? (
        <DataStatus text="Memuat Data..." loading />
      ) : (
        <Formik
          enableReinitialize
          initialValues={formInitialValues(detailPermintaanAset?.data ?? {})}
          onSubmit={formSubmitHandler}
          validationSchema={formValidationSchema}
        >
          <>
            <FormProduksiAsetContent action="create" />
            {modalClosedConfig && (
              <Modal
                show={modalClosedConfig.show}
                size="xl"
                onHide={() => setModalClosedConfig({ show: false })}
              >
                <Modal.Header closeButton>Close Produksi Aset</Modal.Header>
                <Modal.Body>
                  <FormProduksiAsetContent action="confirm" />
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>
            )}
          </>
        </Formik>
      )}
    </>
  );
};
