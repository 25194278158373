// React
import React, { useState } from "react"

// Components
import { Row, Col } from "react-bootstrap"
import { TextEditor } from "components"

// Draft JS
import { 
  EditorState, ContentState, convertFromHTML, convertToRaw, 
} from "draft-js"
import draftToHtml from "draftjs-to-html"

// Form
import { useFormikContext, Field } from "formik"

const TabInfoDetail = () => {
  // Hooks
  const { values, setFieldValue } = useFormikContext()

  // States
  const [textEditorState, setTextEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(values.uraian ?? "<p></p>"))
    )
  )

  const onUraianChangeHandler = (value) => {
    const convertData = convertToRaw(value.getCurrentContent())

    setTextEditorState(value)
    setFieldValue("uraian", draftToHtml(convertData))
  }

  const List = ({label, children}) => (
    <div className="my-1">
      <small>{label}</small>
      <div>
        <b>{children}</b>
      </div>
    </div>
  )

  return (
    <Row>
      <Col lg>
        <Row>
          <Col lg={6}>
            <List label="Kelompok Barang">{values.kode_kelompok ?? "-"} - {values.nama_kelompok ?? "-"}</List>
          </Col>

          <Col lg={6}>
            <List label="Jenis Barang">{values.kode_jenis ?? "-"} - {values.nama_jenis ?? "-"}</List>
          </Col>

          <Col lg={6}>
            <List label="Kode Barang">{values.kode_item ?? "-"}</List>
          </Col>
        </Row>

        <List label="Nama Barang">{values.nama_item ?? "-"}</List>
        <List label="Nama Varian (Khusus Untuk Barang Jadi)">{values.nama_varian ?? "-"}</List>
        <List label="Pabrikan">{values.nama_pabrikan ?? "-"}</List>

        <Row>
          <Col md="4">
            <List label="Satuan Pakai">{values.kode_satuan_pakai ?? "-"}</List>
          </Col>

          <Col md="4">
            <List label="Satuan Beli">{values.kode_satuan_beli ?? "-"}</List>
          </Col>

          <Col md="4">
            <List label="Satuan Jual">{values.kode_satuan_jual ?? "-"}</List>
          </Col>
        </Row>

        <div>
          <Field type="checkbox" name="bisa_diproduksi" onChange={e => e.preventDefault()} /> Barang Bisa Diproduksi
        </div>

        <div>
          <Field type="checkbox" name="bisa_dijual" onChange={e => e.preventDefault()} /> Barang Bisa Dijual
        </div>
      </Col>

      <Col lg>
        <TextEditor
          label="Uraian"
          placeholder="Uraian"
          editorState={textEditorState}
          onEditorStateChange={(val) => onUraianChangeHandler(val)}
          options={[]}
          editorStyle={{
            height: 320,
          }}
          readOnly
        />
      </Col>
    </Row>
  )
}

export default TabInfoDetail
