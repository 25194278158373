// React
import React, { useState, useEffect } from "react"

// Router
import { 
  useLocation, useHistory, useParams 
} from "react-router-dom"

// Components
import { Card, Nav, Tab } from "react-bootstrap"
import { 
  Alert, BackButton, DataStatus 
} from "components"

// Form
import { Formik } from "formik"

// API
import { AnalisaBarangJadiApi } from "api"

// View Components
import { 
  TabGambar, TabFile, TabAnalisa, SummarySection, Body, 
  Divider, TabInfoDetail, TabKonversiDetail, ApprovalSection
} from "./components"

const DetailAnalisaBarangJadi = ({ setNavbarTitle }) => {
  // Hooks
  const location = useLocation()
  const history = useHistory()
  const { id_analisa_barang_jadi } = useParams()

  // Variables
  const routerState = location.state
  const title = "Analisa Barang Jadi"

  // States
  const [data, setData] = useState({})
  const [dropdown, setDropdown] = useState({
    item_bahan: [],
    jenis: [],
    satuan: [],
    pabrikan: [],
    barang_jadi: []
  })
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isFetchingFailed, setIsFetchingFailed] = useState(false)
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })

  const getInitialData = () => {
    setIsPageLoading(true)
    AnalisaBarangJadiApi.single({ id_analisa_barang_jadi }).then(single => {
      const dataSingle = single.data.data
      setData({
        ...dataSingle,
        nilai_konversi: parseFloat(dataSingle.nilai_konversi),
        path_gambar: dataSingle.path_gambar.map(val => val.path_gambar)
      })
    }).catch(() => {
      setIsFetchingFailed(true)
      setAlertConfig({
        show: true,
        variant: "danger",
        text: "Data gagal dimuat!",
      })
    })
    .finally(() => setIsPageLoading(false))
  }

  useEffect(() => {
    setNavbarTitle(title)
    getInitialData()

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="mb-2 text-right">
        <BackButton onClick={() => history.push("/master/analisa-barang-jadi", { ...routerState })} />
      </div>

      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : isFetchingFailed ? (
        <DataStatus text="Data gagal dimuat, tidak dapat menampilkan data!" />
      ) : (
        <Formik
          initialValues={{
            ...data,
            nama_item: data.nama_item_base,
          }}
        >
          {(formik) => {
            const totalSummary = (value) => {
              return formik.values.analisa.filter(val => val.id_buaso === value).reduce((val, {harga, qty}) => {
                const subtotal = Math.round(parseFloat(qty ?? 0) * parseFloat(harga ?? 0))
                return val + subtotal
              }, 0)
            }

            const summary = [
              {
                buaso: "Bahan",
                total: totalSummary('1')
              },
              {
                buaso: "Upah",
                total: totalSummary('2')
              },
              {
                buaso: "Alat & Mesin",
                total: totalSummary('3')
              },
              {
                buaso: "Subkon",
                total: totalSummary('4')
              },
              {
                buaso: "Overhead",
                total: totalSummary('5')
              }
            ]
            const total = summary.reduce((prev, { total }) => {
              return prev + total
            }, 0)

            return (
              <form onSubmit={formik.handleSubmit}>
                <Divider>
                  <b>Detail Data Job Mix Design</b>
                  <Card>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">
                      <Card.Header className="d-flex justify-content-between align-items-center">
                        <Nav variant="tabs">
                          <Nav.Item>
                            <Nav.Link eventKey="tab-1">Informasi Umum</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="tab-4">Konversi Satuan</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="tab-2">Gambar</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="tab-3">File</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Card.Header>
                      <Tab.Content>
                        <Tab.Pane eventKey="tab-1">
                          <Body>
                            <TabInfoDetail />
                          </Body>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab-4">
                          <Body>
                            <TabKonversiDetail />
                          </Body>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab-2">
                          <Body>
                            <TabGambar readOnly />
                          </Body>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab-3">
                          <Body>
                            <TabFile readOnly />
                          </Body>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Card>
                </Divider>

                <Divider>
                  <div className="d-flex justify-content-between align-items-end">
                    <b>Data {title}</b>
                  </div>
                  <Card>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="analisa-1">
                      <Card.Header className="d-flex justify-content-between align-items-center">
                        <Nav variant="tabs">
                          <Nav.Item>
                            <Nav.Link eventKey="analisa-1">Bahan</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="analisa-2">Upah</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="analisa-3">Alat & Mesin</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="analisa-4">Subkon</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="analisa-5">Overhead</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Card.Header>
                      <Tab.Content>
                        <Tab.Pane eventKey="analisa-1">
                          <TabAnalisa dropdown={{}} id_buaso='1' nama_buaso='Bahan' readOnly />
                        </Tab.Pane>
                        <Tab.Pane eventKey="analisa-2">
                          <TabAnalisa dropdown={{}} id_buaso='2' nama_buaso='Upah' readOnly />
                        </Tab.Pane>
                        <Tab.Pane eventKey="analisa-3">
                          <TabAnalisa dropdown={{}} id_buaso='3' nama_buaso='Alat & Mesin' readOnly />
                        </Tab.Pane>
                        <Tab.Pane eventKey="analisa-4">
                          <TabAnalisa dropdown={{}} id_buaso='4' nama_buaso='Subkon' readOnly />
                        </Tab.Pane>
                        <Tab.Pane eventKey="analisa-5">
                          <TabAnalisa dropdown={{}} id_buaso='5' nama_buaso='Overhead' readOnly />
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Card>
                </Divider>

                <Divider>
                  <SummarySection summary={summary} total={total} />
                </Divider>

                <Divider>
                  <ApprovalSection data={data} />
                </Divider>
              </form>
            )
          }}
        </Formik>
      )}
    </>
  )
}

export default DetailAnalisaBarangJadi
