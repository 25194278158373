import { DataStatus, TBody, Table, Td, Th, ThFixed, Tr } from "components";
import Thead from "components/Table/THead";
import { useFormikContext } from "formik";
import React from "react";
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities";

export const TableKomponenProduksi = ({ action, tab }) => {
  const { values } = useFormikContext();
  const checkTab = tab === "alatMesin" ? "alat mesin" : tab;
  const checkKeyTab = tab === "alatMesin" ? "alat_mesin" : tab;

  const showVendor = () => {
    if (tab === "upah" || tab === "subkon") return true;
    return false;
  };

  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed className="text-capitalize">
              Informasi WIP {checkTab}
            </ThFixed>
            <Th className="text-capitalize">Item {checkTab}</Th>
            {showVendor() && <Th>Vendor</Th>}
            <Th>Qty.</Th>
            <Th>Harga Satuan</Th>
            <Th>Jumlah</Th>
            <Th>Akun COA</Th>
            <Th>Keterangan Jurnal</Th>
          </Tr>
        </Thead>
        <TBody>
          {values?.[tab]?.length > 0 ? (
            values?.[tab]?.map((val, ind) => (
              <Tr key={ind}>
                <Td>{ind + 1}</Td>
                <Td>
                  <div>
                    {
                      DateConvert(new Date(val?.[`tgl_wip_${checkKeyTab}`]))
                        .custom
                    }
                  </div>
                  <div>{val?.[`no_wip_${checkKeyTab}`] ?? "-"}</div>
                </Td>
                <Td>{val.nama_item ?? "-"}</Td>
                {showVendor() && <Td>{val?.nama_vendor ?? "-"}</Td>}
                <Td className="text-right">
                  {
                    DecimalConvert(parseFloat(val.qty_realisasi ?? 0))
                      .getWithComa
                  }
                </Td>
                <Td>
                  {
                    RupiahConvert(
                      parseFloat(
                        val?.[`harga_satuan_${checkKeyTab}`] ?? 0
                      ).toString()
                    ).getWithComa
                  }
                </Td>
                <Td>
                  {
                    RupiahConvert(
                      String(
                        parseFloat(val?.[`harga_satuan_${checkKeyTab}`] ?? 0) *
                          parseFloat(val?.qty_realisasi ?? 0)
                      )
                    ).getWithComa
                  }
                </Td>
                <Td>
                  <div>
                    D : {val?.nomor_akun_debet} - {val?.nama_akun_debet}
                  </div>
                  <div>
                    D : {val?.nomor_akun_kredit} - {val?.nama_akun_kredit}
                  </div>
                </Td>
                <Td>{val?.keterangan_jurnal ?? "-"}</Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="8">
                <DataStatus text="Tidak Ada Data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </div>
  );
};
