import React, {useState, useEffect} from 'react'
import { Formik } from 'formik'
import {
    FilterModal,
    DatePicker,
    SelectSearch
} from 'components'
import { DateConvert } from 'utilities'
import Axios from "axios"
import { JobMixApi } from 'api'

const ModalFilter = ({ show, setShow, data, setData }) => {
// STATE DATA
    const [dataCustomer, setDataCustomer] = useState([{label: 'Semua', value: undefined}])
    const [dataBarangJadi, setDataBarangJadi] = useState([{label: 'Semua', value: undefined}])
    const [dataStatusProgress, setDataBarangJdataStatusProgress] = useState([
        {label: 'Semua', value: undefined},
        {label: 'ON PROGRESS', value: 'on progress'},
        {label: 'CLOSED', value: 'closed'},
    ])
    const [loading, setLoading] = useState(true)
// REQUSET DATA SERVER
    const getDataDropdown = () => {
        Axios.all([
          JobMixApi.dropdown({tipe: 'customer'}),
          JobMixApi.dropdown({tipe: 'barang_jadi'}),
        ])
        .then(Axios.spread((customer, barang) => {
            const mapDataCustomer = customer?.data?.data ? customer.data.data.map(val => ({label: val?.nama_customer, value: val?.id_customer})) : []
            const mapDataBarangJAdi = barang?.data?.data ? barang.data.data.map(val => ({label: val?.nama_barang_jadi, value: val?.id_barang_jadi})) : []

            setDataCustomer([...dataCustomer, ...mapDataCustomer])
            setDataBarangJadi([...dataBarangJadi, ...mapDataBarangJAdi])
        }))
        .finally(() => setLoading(false))
    }
// USE EFFECT ALL DROPDOWN
    useEffect(() => {
        getDataDropdown()
    }, [])
// FORM VALUES
    const formInitialValues = {
        tgl_produksi_mulai: data?.filter?.tgl_produksi_mulai,
        tgl_produksi_selesai: data?.filter?.tgl_produksi_selesai,
        tgl_sales_order_mulai: data?.filter?.tgl_sales_order_mulai,
        tgl_sales_order_selesai: data?.filter?.tgl_sales_order_selesai,
        customer: data?.filter?.customer,
        barang_jadi: data?.filter?.barang_jadi,
        status_progress: data?.filter?.status_progress,
    }
    const formSubmitHandler = (values) => {
        const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

        if (checkActive) {
          setData({ 
              filter: {
                  ...values,
                  active: true
              },
              pagination: {
                  ...data.pagination,
                  page: 1
              }
           })
        } else { 
            setData({ 
                ...data,
                filter: {
                    ...values,
                    active: false
                }
             })
        }
    
        setShow(false)
    }
// HANDLE CHANGE 
    const onTanggalProduksiChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            tgl_produksi_mulai: startDate,
            tgl_produksi_selesai: endDate,
        })
    }
    const onTanggalSOChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            tgl_sales_order_mulai: startDate,
            tgl_sales_order_selesai: endDate,
        })
    }
    const onResetButtonClick = (values, setValues) => {
        setValues({
            tgl_produksi_mulai: undefined,
            tgl_produksi_selesai: undefined,
            tgl_sales_order_mulai: undefined,
            tgl_sales_order_selesai: undefined,
            customer: undefined,
            barang_jadi: undefined,
            status_progress: undefined,
        })
    }

    return (
        <Formik
            enableReinitialize
            initialValues={formInitialValues}
            onSubmit={formSubmitHandler}>
            
            {({values, handleSubmit, setFieldValue, setValues}) => (
                <FilterModal
                    show={show}
                    setShow={setShow}
                    onResetButtonClick={() => onResetButtonClick(values, setValues)}
                    onFilterButtonClick={handleSubmit}>
                    
                    <DatePicker 
                        selectsRange
                        label="Tgl. Produksi"
                        placeholderText="Pilih Tanggal Produksi"
                        startDate={values.tgl_produksi_mulai ? new Date(values.tgl_produksi_mulai) : ''}
                        endDate={values.tgl_produksi_selesai ? new Date(values.tgl_produksi_selesai) : ''}
                        onChange={dates => onTanggalProduksiChange(dates, values, setValues)}
                        monthsShown={2}
                    />
                    <DatePicker 
                        selectsRange
                        label="Tgl. Sales Order"
                        placeholderText="Pilih Tanggal Sales Order"
                        startDate={values.tgl_sales_order_mulai ? new Date(values.tgl_sales_order_mulai) : ''}
                        endDate={values.tgl_sales_order_selesai ? new Date(values.tgl_sales_order_selesai) : ''}
                        onChange={dates => onTanggalSOChange(dates, values, setValues)}
                        monthsShown={2}
                    />
                    <SelectSearch 
                        key={values.customer}
                        label="Customer"
                        placeholder="Pilih customer"
                        defaultValue={dataCustomer.find(val => val.value === values.customer)}
                        option={dataCustomer}
                        onChange={val => setFieldValue('customer', val.value)}
                        loading={loading}
                    />
                    <SelectSearch 
                        key={values.barang_jadi}
                        label="Barang Jadi"
                        placeholder="Pilih Barang Jadi"
                        defaultValue={dataBarangJadi.find(val => val.value === values.barang_jadi)}
                        option={dataBarangJadi}
                        onChange={val => setFieldValue('barang_jadi', val.value)}
                        loading={loading}
                    />

                    <SelectSearch
                        key={values.status_progress}
                        label="Status Pregress"
                        placeholder="Pilih Status Pregress"
                        defaultValue={dataStatusProgress.find(val => val.value === values.status_progress)}
                        option={dataStatusProgress}
                        onChange={val => setFieldValue('status_progress', val.value)}
                        loading={loading}
                    />
                </FilterModal>
            )}
        </Formik>
    )
}
export default ModalFilter;