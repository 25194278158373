import React, { memo, useState, useEffect, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Layout, PageLoader } from "components";
import { AuthContext } from "utilities";
import Dashboard from "pages/Dashboard";
import NotFound from "pages/404";
import Login from "pages/Login";
import Config from "config";

const MyRoutes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { state } = useContext(AuthContext);
  const { isAuthenticated, role } = state;
  const { ROUTES } = Config;

  // Routes pada saat terotentikasi
  const AuthRoutes = memo(({ setNavbarTitle }) => {
    return (
      <Switch>
        {/* DASHBOARD */}
        <Route exact path="/">
          <Dashboard setNavbarTitle={setNavbarTitle} />
        </Route>

        {/* ROUTES */}
        {ROUTES.map(
          (val, index) =>
            val.hak &&
            val.hak.some((hak) => role.includes(hak)) && (
              <Route key={index} exact={val.exact} path={val.path}>
                <val.page setNavbarTitle={setNavbarTitle} />
              </Route>
            )
        )}

        {/* LOGIN FORM */}
        <Route path="/login">
          <Redirect to="/" />
        </Route>

        {/* 404-NOT FOUND */}
        <Route path="*">
          <NotFound setNavbarTitle={setNavbarTitle} />
        </Route>
      </Switch>
    );
  });

  // Routes pada saat belum terotentikasi
  const UnAuthRoutes = () => (
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route path="*">
        <Redirect to="/login" />
      </Route>
    </Switch>
  );

  // Routing keseluruhan
  const Routes = () => {
    if (!isAuthenticated) {
      return <UnAuthRoutes />;
    }

    return (
      <Layout>
        <AuthRoutes />
      </Layout>
    );
  };

  const Loading = () => {
    useEffect(() => {
      setTimeout(() => setIsLoading(false), 3000);
    }, []);
    return <PageLoader />;
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <BrowserRouter basename="/">
      <Routes />
    </BrowserRouter>
  );
};

export default MyRoutes;
