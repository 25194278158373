const getStatusApprovalLabel = (value) => {
	switch (value) {
		case "APP":
			return {
				variant: "outline-success",
				label: "APPROVED",
				level: 4
			}
		case "REJ":
			return {
				variant: "outline-danger",
				label: "REJECT",
				level: 3
			}
		case "REV":
			return {
				variant: "outline-warning",
				label: "REVISI",
				level: 2
			}
		case "VER":
			return {
				variant: "outline-success",
				label: "VERIFIED",
				level: 4
			}
		default:
			return {
				variant: "outline-secondary",
				label: "PENDING",
				level: 1
			}
	}
}

export default getStatusApprovalLabel