// React
import React from "react";

// Form
import { useFormikContext } from "formik";

// Components
import { DatePicker, FilterModal } from "components";
import { Select } from "components2";

// API
import { useQuery } from "react-query";
import { DateConvert } from "utilities";
import CatatanTransaksiHarianApi from "../__CatatanTransaksiHarianApi__";

export const ModalFilter = ({ toggle }) => {
  const { values, handleSubmit, setValues } = useFormikContext();

  const dataStatusChecklist = [
    { label: "Approve", value: "APP" },
    { label: "Verify", value: "VER" },
    { label: "Revisi", value: "REV" },
    { label: "Reject", value: "REJ" },
    { label: "Pending", value: "PEN" },
  ];

  const { data: dropdownKelompok, isFetching: loadingDropdownKelompok } =
    useQuery(["kelompok", "dropdown"], () =>
      CatatanTransaksiHarianApi.dropdownKelompok()
    );

  const { data: dropdownJenis, isFetching: loadingDropdownJenis } = useQuery(
    ["jenis", "dropdown", values?.id_kelompok],
    () =>
      CatatanTransaksiHarianApi.dropdownJenis({
        id_kelompok: values?.id_kelompok,
      }),
    { enabled: !!values?.id_kelompok }
  );

  const { data: dropdownItem, isFetching: loadingDropdownItem } = useQuery(
    ["item", "dropdown", values?.id_jenis],
    () =>
      CatatanTransaksiHarianApi.dropdownItem({
        id_jenis: values?.id_jenis,
      }),
    { enabled: !!values?.id_jenis }
  );

  // HANDLE CHANGE
  const onResetButtonClick = (setValues) => {
    const myPromise = new Promise((resolve) => {
      setValues({
        tanggal_awal: undefined,
        tanggal_akhir: undefined,
        id_jenis: undefined,
        id_kelompok: undefined,
        id_item_buaso: undefined,
      });

      setTimeout(() => resolve(), 300);
    });

    myPromise.then(() => {});
  };

  const onTanggalChange = (
    dates,
    values,
    setValues,
    value_awal,
    value_akhir
  ) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      [value_awal]: startDate,
      [value_akhir]: endDate,
    });
  };

  return (
    <FilterModal
      isToggle
      toggleModal={toggle}
      onResetButtonClick={() => onResetButtonClick(setValues)}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        selectsRange
        label="Tanggal"
        placeholderText="Pilih tanggal"
        startDate={values?.tanggal_awal ? new Date(values?.tanggal_awal) : ""}
        endDate={values?.tanggal_akhir ? new Date(values?.tanggal_akhir) : ""}
        onChange={(dates) =>
          onTanggalChange(
            dates,
            values,
            setValues,
            "tanggal_awal",
            "tanggal_akhir"
          )
        }
        monthsShown={2}
      />

      <Select
        label="Kelompok Item"
        placeholder="Pilih kelompok item"
        name="id_kelompok"
        options={dropdownKelompok ?? []}
        loading={loadingDropdownKelompok}
        onChange={async (val) =>
          setValues({
            ...values,
            id_kelompok: val.value,
          })
        }
        defaultValue={dropdownKelompok?.find(
          (val) => val.value === values.id_kelompok
        )}
      />

      <Select
        label="Jenis Item"
        placeholder="Pilih jenis item"
        name="id_jenis"
        disable={!!!values?.id_kelompok}
        loading={loadingDropdownJenis}
        options={dropdownJenis ?? []}
        onChange={async (val) =>
          setValues({
            ...values,
            id_jenis: val.value,
          })
        }
        defaultValue={dropdownJenis?.find(
          (val) => val.value === values.id_jenis
        )}
      />

      <Select
        label="Item Bahan"
        placeholder="Pilih item bahan"
        name="id_item_buaso"
        disable={!!!values?.id_jenis}
        loading={loadingDropdownItem}
        options={dropdownItem ?? []}
        onChange={async (val) =>
          setValues({
            ...values,
            id_item_buaso: val.value,
          })
        }
        defaultValue={dropdownItem?.find(
          (val) => val.value === values.id_item_buaso
        )}
      />
    </FilterModal>
  );
};
